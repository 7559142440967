import * as React from 'react';
import { useState, useEffect } from 'react';
// import TextBox from "devextreme-react/text-box";
import axios from 'axios';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import './user-management.css';
// import { baseAPI2 } from "../../../api/APIEndpoints";
import { useSelector } from 'react-redux';
import { IStateProps } from '../../../types/auth';

const UserManagement = () => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);
  const allRolesURL = `${baseAPI}/ApplicationRole/getRolesForSelect`;
  const getUserDetailsURL = `${baseAPI}/ApplicationUser/GetUserByLoginEmail`;
  const setUserRoleURL = `${baseAPI}/ApplicationUser/AssignUserToRoleAsync`;

  const [userData, setUserData] = useState({
    id: 2,
    email: 'mail.com',
    fullName: 'User name',
    phoneNumber: 'number'
  });
  const [value, setValue] = useState('');
  const [formValues, setFormValues]: any = useState({
    role: null,
    email: null
  });

  const [rolesLookup, setRolesLookup]: any = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [lookupFetched, setLookupFetched] = useState(false);

  async function fetchUser(url: string, value = '') {
    const res = await axios.get(`${url}/${value}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const userDetails = await res.data.result[0];
    if (userDetails) {
      setUserData(userDetails);
      setFormValues({ email: userDetails.email, role: null });
      setHasFetched(true);
      setLoading(false);

      const res = await axios.get(`${allRolesURL}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      const lookup = res.data.result;
      if (lookup) {
        setRolesLookup(res.data.result);
        setLookupFetched(true);
      }
      console.log(res.data.result);
    }
  }

  const handleSetUserRole = async () => {
    console.log(formValues);
    await axios
      .put(`${setUserRoleURL}/${formValues.role}/${formValues.email}`, formValues, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then(() => notify('Role updated successfully'));
  };

  const handleUserSearch = () => {
    if (value) {
      setLoading(true);
      fetchUser(getUserDetailsURL, value);
    }
  };

  useEffect(() => {});

  const handleCheckSelect = (arg: any) => {
    console.log(arg);
  };
  const handleSelect = (arg: any) => {
    // console.log();
    const role = arg.selectedItem.title;
    setFormValues((prev: any) => {
      return {
        ...prev,
        role
      };
    });
  };

  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>User Management</header>
      <div className="module-wrapper" id={'user'}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#user' }}
          // onHiding={hideLoadPanel}
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          // closeOnOutsideClick={closeOnOutsideClick}
        />

        <div className="search-component">
          <label htmlFor="email">
            Email
            <input
              type="text"
              name="email"
              id="email"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="search-box"
              placeholder="Enter user email address"
            />
          </label>
          <button type="submit" className="submit-btn" onClick={handleUserSearch}>
            Search
          </button>
        </div>

        {hasFetched && (
          <>
            <div className="user-search-result">
              <div className="search-item">
                <b>ID:</b>
                <span>{userData.id}</span>
              </div>
              <div className="search-item">
                <b>Email:</b>
                <span>{userData.email}</span>
              </div>
              <div className="search-item">
                <b>Full Name:</b>
                <span>{userData.fullName}</span>
              </div>
              <div className="search-item">
                <b>Phone Number</b>
                <span>{userData.phoneNumber}</span>
              </div>
            </div>
            <div className={'roles-section'}>
              <SelectBox
                className={'roles-select-box'}
                //   items={createLookup(optionsList, "string", "")}
                onSelectionChanged={handleSelect}
                defaultValue={lookupFetched && rolesLookup[0]}
                dataSource={lookupFetched && rolesLookup}
                displayExpr="title"
                valueExpr="value"
              />
              <CheckBox defaultValue={false} style={{ marginTop: '20px' }} text="Clear Existing roles" onValueChange={handleCheckSelect} />

              <button type="submit" className="submit-btn set-role-btn" onClick={handleSetUserRole}>
                Set Role
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
