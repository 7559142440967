import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Message,
  Form,
  Header,
  Icon,
  Confirm,
  Segment
  // Container,
  // Label,
} from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Formik, Form as FForm, FieldArray } from 'formik';
import MyTextInput from './input-controls/MyTextInput';

import MyDateInput from './input-controls/MyDateInput';
import { IStateProps } from '../../../types/auth';

// import { inspectionTemplatetasks } from "../assets/tempJson";

function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
interface IPIGridProps {
  rowID?: number;
  setMode: any;
}

const VendorGridForm: React.FC<IPIGridProps> = ({ rowID, setMode }) => {
  const [open, setOpen] = useState(false);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const [vendorGrid, setVendorGrid] = useState({
    vendorOID: 2,
    vendorCode: 'string',
    vendorName: 'string',
    address: 'string',
    telephoneNumber: 'string',
    email: 'string',
    website: 'string',
    contactPerson: 'string',
    status: 'string',
    ownersName: 'string',
    webPage: 'string',
    businessSectorOID: 0,
    addressStreet: 'string',
    addressCity: 'string',
    postalCode: 'string',
    countryOID: 0,
    priceLevelOID: 0,
    accountOID: 0,
    shippingMethod: 'string',
    paymentMethodOID: 0,
    paymentTermOID: 0,
    creditLimit: 0,
    openingBalance: 0,
    openingBalanceDate: '2021-11-24T09:37:34.252',
    shortName: 'string',
    currencyOID: 0,
    addressStateOID: 0,
    bankAccountNo: 'string',
    bankOID: 0,
    accountNumber: 'string'
  });
  useEffect(() => {
    if (rowID) {
      const fetchRowData = async () => {
        fetch(`${baseAPI}/FFVendors/${rowID}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((data: any) => setVendorGrid(data.result[0]));
      };
      fetchRowData();
    }
  }, [rowID, baseAPI, jwt]);
  const handleEditFormSubmit = (vendorGrid: any) => {
    // e.preventDefault();
    console.log('1. submit function', vendorGrid);
    fetch(`${baseAPI}/FFVendors/${rowID}`, {
      method: 'PUT',
      body: JSON.stringify(vendorGrid),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };
  const handleAddFormSubmit = (vendorGrid: any) => {
    // e.preventDefault();
    console.log('2. submit function', vendorGrid);
    fetch(`${baseAPI}/FFVendors`, {
      method: 'POST',
      body: JSON.stringify(vendorGrid),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(handleErrors);
    setMode('GRID');
  };
  const handleCancelClick = () => {
    setMode('GRID');
  };
  const handleDeleteClick = (e: any) => {
    e.preventDefault();
    fetch(`${baseAPI}/FFVendors/${rowID}`, {
      method: 'DELETE',
      body: JSON.stringify(vendorGrid),
      headers: {
        Authorization: `Bearer${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  return (
    <StyledProvider>
      <>
        <Header>
          <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
          Vendors
        </Header>
        <Message color="brown" size="mini">
          <Message.Header>Vendors</Message.Header>
          <p>Create and manage vendors</p>
        </Message>
        <Formik
          initialValues={vendorGrid}
          onSubmit={async (values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
          enableReinitialize
          // onSubmit={(values: any) => console.log(values)}
        >
          {({ values, handleSubmit, isValid, isSubmitting, dirty }) => (
            <FForm className="ui form" onSubmit={handleSubmit} autoComplete="off">
              <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
              <Form.Group widths="equal">
                <MyTextInput disabled label="Vendor OID" placeholder="Vendor OID" name="vendorOID" />
                <MyTextInput name="vendorName" placeholder="Vendor Name" label="Vendor Name" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput name="Address" placeholder="Address" label="Address" />
                <MyTextInput label="Telephone Number" placeholder="Telephone Number" name="telephoneNumber" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput label="email" placeholder="email" name="email" />
                <MyTextInput label="website" placeholder="website" name="website" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput label="Contact Person" placeholder="contactPerson" name="contactPerson" />
                <MyTextInput label="status" placeholder="status" name="status" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput label=" Owners Name" placeholder="Owners Name" name=" ownersName" />
                <MyTextInput label="Web Page" placeholder="Web Page" name="webPage" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput label="Business SectorOID" placeholder="Business SectorOID" name="businessSectorOID" />
                <MyTextInput label="Address Street" placeholder=" Address Street" name=" addressStreet" />
                <MyTextInput label="Address City" placeholder="Address City" name="addressCity" />
              </Form.Group>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  lineHeight: '25px',
                  justifyContent: 'flex-end'
                }}
              >
                <Button content="Cancel" onClick={handleCancelClick} />
                <Button type="submit" primary content="Save" loading={isSubmitting} />
                <Button negative content="Delete" onClick={handleDeleteClick} />
              </div>
            </FForm>
          )}
        </Formik>
      </>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .field {
    width: 40%;
  }

  .it__details_form {
    align-items: center;
    // width: 60%;
    display: flex;
    column-gap: 5px;
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }
`;
export default VendorGridForm;
