import React from 'react';
// import "./Branch.css";
//import { baseAPI } from "../../../api/APIEndpoints";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
// import styled from "@emotion/styled";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  Editing,
  Export,
  Form,
  RequiredRule,
  Pager,
  Grouping,
  GroupPanel,
  Lookup,
  Column
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import { IStateProps } from '../../../types/auth';
// import BaseUrl from "../../../Baseurl";
// import DealerSideNav from "../../../Layout/SideNav/Layout";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const SetupTypeGrid = ({ setupType }: any) => {
  //Get JWT Token from the store
  const BaseUrl = useSelector((state: IStateProps) => state.config.baseURL);

  const jwt = useSelector((state: IStateProps) => state.user.token);
  // const [dataSource, setDataSource] = React.useState(() => initialData);

  const setupTypeDataSource: any = new CustomStore({
    key: 'oid',
    load: () => {
      return fetch(`${BaseUrl}/EcoSystemCodes/GetEcoSystemCodeBySetupType/${setupType}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          // console.log(response.result.data)
          return {
            data: response.result.data,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count
          };
        });
    },
    update: (values) => {
      return values;
    }
  });

  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);
    // console.log(e.newData)
    fetch(`${BaseUrl}/EcoSystemCodes`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(setupTypeDataSource.load());
  };
  return (
    <DataGrid
      id="[$GridID]"
      dataSource={setupTypeDataSource}
      className="dx-datagrid dx-row-alt"
      hoverStateEnabled={true}
      columnAutoWidth={true}
      showBorders={true}
      selection={{ mode: 'single' }}
      onRowUpdating={onRowUpdating}
      activeStateEnabled={true}
      // keyExpr="oid"
      //  onSelectionChanged={onSelectionChanged}
      columnResizingMode="widget"
      allowColumnResizing={true}
      columnHidingEnabled={true}
    >
      {/* <RowDragging allowReordering={true} /> */}
      <FilterPanel visible={true} />
      <SearchPanel visible={true} />
      <FilterRow visible={true} />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
      <Grouping contextMenuEnabled={true} />
      <GroupPanel visible={true} />
      <Editing mode="popup" useIcons={true} allowUpdating={true} allowAdding={true} />
      <Form>
        <Item itemType="group" colCount={2} colSpan={2}>
          <Item dataField="oid" />

          <Item
            dataField="setupType"
            // editorOptions={setupTypeOption}
            // helpText={setupType}
          />
          <Item dataField="code" />
          <Item dataField="description" />
          <Item dataField="codeOrderApplication" />
        </Item>
      </Form>
      <Export enabled={true} fileName="FABranchs" allowExportSelectedData={false} />
      <Column type="buttons" /> <Column dataField="oid" allowEditing={false}></Column>
      <Column dataField="setupType" allowEditing={true}>
        <Lookup dataSource={[setupType]} />
        <RequiredRule />
      </Column>
      <Column dataField="code">
        <RequiredRule />
      </Column>
      <Column dataField="description">
        <RequiredRule />
      </Column>
      <Column dataField="codeOrderApplication" allowEditing={true}>
        <RequiredRule message="Code Order Application is required and is a number" />
      </Column>
    </DataGrid>
  );
};

export default SetupTypeGrid;
