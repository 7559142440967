import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  //   Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  Form
  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { Segment, Message, Icon, Header } from 'semantic-ui-react';
import 'whatwg-fetch';
import { IStateProps } from '../../../../types/auth';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const CamsPart = () => {
  const [dataSource, setDataSource] = React.useState({});
  // const [rowData, setRowData]: any = React.useState({});
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  console.log('1. baseapi', baseAPI);
  console.log('1. jwt', jwt);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'partNumberOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSInventorys`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSInventorys`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSInventorys/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    // console.log(Object.assign(e.oldData, e.newData));
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${baseAPI}/CAMSInventorys/${e.newData.partNumberOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  return (
    <Segment>
      <div>
        <Header as="h2">Parts</Header>
        <Message color="brown">
          <Message.Content>
            <Message.Header> Manage Inventory</Message.Header>
            CAMS has the ability to add separate Inventory to a Location to store your part. 
          </Message.Content>
        </Message>
      </div>
      <div>
        <DataGrid
          id="grdCamsWarehouse"
          dataSource={dataSource && dataSource}
          // className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          onRowUpdating={onRowUpdating}
          //   onRowUpdating={(e: any) => console.log(e)}
          columnHidingEnabled={true}
          // onToolbarPreparing={handleToolPreparing}
        >
          <RowDragging allowReordering={true} />
          <Export enabled={true} fileName="Parts" allowExportSelectedData={false} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
            <Popup title="Parts" showTitle={true} width={700} height={500}></Popup>

            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="partNumberOID" />
                <Item dataField="partName" />
                <Item dataField="warehouseBin" />
                <Item dataField="category" />
                <Item dataField="trackInventory" />
                <Item dataField="maximumQuantity" />
                <Item dataField="unitType" />
                <Item dataField="reorderPoint" />
                <Item dataField="reorderQuantity" />
              </Item>
            </Form>
          </Editing>

          <Column type="buttons">
            <Button name="edit" icon="edit" />
            <Button name="delete" />
          </Column>
          <Column dataField="partNumberOID" allowEditing={false} visible={false}></Column>
          <Column dataField="partName">
            <RequiredRule />
          </Column>
          <Column dataField="description">
            <RequiredRule />
          </Column>
          <Column dataField="category">
            <RequiredRule />
          </Column>
          <Column dataField="unitType">
            <RequiredRule />
          </Column>
          <Column dataField="trackInventory">
            <RequiredRule />
          </Column>
          <Column dataField="warehouseBin">
            <RequiredRule />
          </Column>
          <Column dataField="reorderPoint">
            <RequiredRule />
          </Column>
          <Column dataField="reorderQuantity">
            <RequiredRule />
          </Column>
          <Column dataField="maximumQuantity">
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
    </Segment>
  );
};

export default CamsPart;
