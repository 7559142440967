// *************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : Ecosystem Settings
// SIGNIFICANCE:
// DATE CREATED : May 24 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
// *************************************************************

import * as React from 'react';
// import { baseAPI2 } from "../../../api/APIEndpoints";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PreferenceCard from '../../../components/ecosystem/Card';
import { IStateProps } from '../../../types/auth';

const EcoSystemSettings: React.FC = () => {
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const [loading, setLoading] = useState(true);

  const [preferenceData, setPreferenceData]: any = useState([
    [
      {
        oid: 8,
        description: 'System Account',
        value: 'string',
        settingsCode: 1,
        categoryOrder: 1,
        optionsList: 'string',
        inputUIType: 'string'
      }
    ]
  ]);

  const preferenceAPI = `${baseAPI}/ecosystemsettings`;

  // const [combinedArray, setCombinedArray] = useState([]);
  let combinedArray: any[] = [];
  // let index: number = 0;
  const distinctCategory: any[] = [];

  const fetchSystemPreferences = () => {
    axios.get(preferenceAPI).then((res) => {
      let data = res.data.result;
      // filterData(data);
      sortPreferences(data);
      console.log(data);
      console.log(combinedArray);
    });
  };

  const sortPreferences = (data: any) => {
    let preferences = data;
    console.log(preferences);
    // let id = 0;
    let categoryType: any;

    for (let i = 0; i < preferences.length; i += 1) {
      if (!distinctCategory.includes(preferences[i].category)) {
        distinctCategory.push(preferences[i].category);
      }
    }

    while (preferences.length > 0) {
      combinedArray.push(preferences.filter((type: any) => type && type.category === preferences[0].category));

      categoryType = combinedArray[combinedArray.length - 1][0].category;

      //remove all previously sorted preference types
      for (let i = 0; i < preferences.length; i += 1) {
        if (preferences[i] && preferences[i].category === categoryType) {
          preferences.splice(i, 1, null); // add null to maintain the array length
        }
      }

      //remove all nulls
      preferences = preferences.filter((type: any) => type !== null);

      // console.log(
      //   "AFTER SPLICE",
      //   preferences,
      //   preferences.length,
      //   combinedArray,
      //   distinctCategory
      // );
    }

    setPreferenceData(combinedArray);
    setLoading(false);
  };

  useEffect(() => {
    fetchSystemPreferences();
  }, []);

  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>System Preferences</header>
      <div className="module-wrapper">
        {loading === false ? (
          preferenceData.map((category: any, index: any) => (
            <PreferenceCard records={category} key={category + index} title={category[0].category} />
          ))
        ) : (
          <div style={{ textAlign: 'center' }}>
            {/* <Spin tip="Loading..." /> */}
            {/* LOADER DEVEXPRESS */}
          </div>
        )}
      </div>
    </div>
  );
};

export default EcoSystemSettings;
