import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { makeStyles } from '@mui/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { useSelector } from 'react-redux';

// project imports
// import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './profileSection/ProfileSection';
// import NotificationSection from './NotificationSection';

import { Menu } from '@mui/icons-material';

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light
    }
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      width: 'auto'
    }
  }
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const baseAPI = useSelector((state) => state.config.baseURL);
  const classes = useStyles();
  // const [fileURL, setFileURL] = useState('');
  // const logoDownload = `${baseAPI}/FileManagement?Folder=Images&FileName=${'logo-Charlvon'}&Extension=png=${fileURL}`;
  // const logoDownload = `${baseAPI}/FileManagement/${folder}/${FileName}/${Extension}`;
  // const logoLoad = ` ${baseAPI}/FileManagement/folder/fileName/Extention`;
  const logoLoad = ` ${baseAPI}/FileManagement/Images/logo-Charlvon/png`;

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          {/* <LogoSection /> */}
          {/* <h1>Logo_Charlvon</h1> */}
          <img src={logoLoad} alt="logo" style={{ width: '100px', objectFit: 'contain' }} />
          {/* <img src="https://cams.caderp.com/api/FileManagement/Images/logo-Charlvon/png" alt="logo" /> */}
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
            <Menu />
          </Avatar>
        </ButtonBase>
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <div className={classes.grow} />
      <div className={classes.grow} />

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
