import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';

import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  // Popup,
  Editing,
  Export,
  Pager,
  LoadPanel,
  // Grouping,
  // GroupPanel,
  RowDragging,
  Column,
  RequiredRule

  // Form,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from "devextreme-react/form";
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';

import 'whatwg-fetch';
import EquipmentGridForm from '../components/EquipmentGridForm';
import { IStateProps } from '../../../types/auth';
import { fetchCamsLocations } from '../../../store/actions';
// import { Template, Toolbar } from "devextreme-react";
// import { Template } from "devextreme/core/templates/template";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const EquipmentsGrid = () => {
  const [dataSource, setDataSource] = useState({});
  // const [rowData, setRowData]: any = useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const [loader, setLoader] = useState(false);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsLocations } = useSelector((state: IStateProps) => state?.cams?.camsLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchHREmployeesV2s());
    dispatch(fetchCamsLocations());
  }, [dispatch]);

  React.useEffect(() => {
    setLoader(true);
    const customDataSource: any = new CustomStore({
      key: 'equipmentOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSEquipments`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            setLoader(false);
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        // console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSEquipments`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSEquipments/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/CAMSEquipments/${e.newData.equipmentOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.equipmentOID);
    setMode('FORM-EDIT');
  };

  const handleNewClick = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewClick;
        break;
      }
    }
  }

  const locationsLookup = {
    store: camsLocations
  };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2"> Equipment </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage Equipment</Message.Header>
                Create and manage Equipment.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsLocation"
              dataSource={dataSource && dataSource}
              hoverStateEnabled={true}
              columnAutoWidth={true}
              activeStateEnabled={true}
              columnResizingMode="widget"
              allowColumnResizing={true}
              onRowUpdating={onRowUpdating}
              columnHidingEnabled={true}
              rowAlternationEnabled={true}
              onToolbarPreparing={onToolbarPreparing}
            >
              {/* <Toolbar>
                  <Item location='before' ></Item>
                </Toolbar> */}
              <RowDragging allowReordering={true} />
              <LoadPanel enabled={loader} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              {/* <Grouping contextMenuEnabled={true} /> */}
              {/* <GroupPanel visible={true} /> */}
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} />
              <Export enabled={true} fileName="Equipmemts" allowExportSelectedData={false} />
              {/* <Template name="addNewEquipment" render={toolbarItemRender} /> */}
              <Column type="buttons">
                <Button name="edit" onClick={handleEditClick} icon="edit" />
                <Button name="delete" />
              </Column>
              {/* <Column
                dataField="equipmentOID"
                allowEditing={false}
                width={120}
                // visible={false}
              ></Column> */}
              <Column dataField="description">
                <RequiredRule />
              </Column>
              <Column dataField="equipmntType">
                <RequiredRule />
              </Column>
              {/* <Column dataField="serialNumber">
                <RequiredRule />
              </Column> */}

              <Column dataField="location">
                <Lookup dataSource={locationsLookup} displayExpr="locationName" valueExpr="locationOID"></Lookup>
                <RequiredRule />
              </Column>

              {/* <Column dataField="category">
                <RequiredRule />
              </Column> */}
              {/* <Column dataField="operator">
                <RequiredRule />
              </Column> */}
              <Column dataField="equipmentStatus"></Column>
              {/* <Column dataField="state">
            <RequiredRule />
          </Column>
          <Column dataField="country"></Column>
          <Column dataField="postCode"></Column>
          <Column dataField="camsWarehouseList"></Column> */}
            </DataGrid>
          </div>
        </>
      ) : (
        <EquipmentGridForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default EquipmentsGrid;
