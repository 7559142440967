// *************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : FormEngine
// SIGNIFICANCE: The form engine main grid having all configured forms and grid in the entire ecosystem.
// DATE CREATED : 10 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
// *************************************************************
import React, { useState } from 'react';
// import Axios from "axios";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
// import { Item } from "devextreme-react/form";
// import { baseAPI2 } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  // Lookup,
  // Popup,
  // Position,
  Button as Point,
  Editing,
  Export,
  Pager,
  Grouping,
  // GroupPanel,
  // RowDragging,
  Column,
  RequiredRule
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

import // ButtonItem,
// GroupItem,
// SimpleItem,
// Label,
// CompareRule,
// EmailRule,
// PatternRule,
// RangeRule,
// RequiredRule,
// StringLengthRule,
// AsyncRule,
'devextreme-react/form';
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
// import Toolbar, { Item } from "devextreme-react/toolbar";
// import styled from "@emotion/styled";
import '../grid.scss';
// import notify from "devextreme/ui/notify";

import { useSelector } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
// import "whatwg-fetch";
// import { baseAPI2, ECOSYSTEM_APIS } from "../../../api/APIEndpoints";
import CustomToolbar from '../CustomToolbar';
import FormGeneratorForm from './FormGeneratorForm';
import { IStateProps } from '../../../types/auth';

function handleErrors(response: any) {
  if (!response.ok) {
    console.log(response);
    if (response.status === 401) {
      throw Error('Your Login session has expired. Please logout and sign in again.');
    }
    throw Error(response.statusText);
  }
  return response;
}

export const FormEngine = () => {
  const jwt = useSelector((state: IStateProps) => state?.user?.token);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const url = `${baseAPI}/EcoCADUIEngineHdrs`;

  const privilege = {
    canEdit: 1,
    canCreate: 1,
    canDelete: 1,
    canPost: 1,
    canExport: 1
  };

  const [mode, setMode] = React.useState('GRID');
  // const [rowID, setRowID]: any = React.useState(null);
  const [rowData, setRowData]: any = React.useState({});
  const [shouldEdit, setShouldEdit] = React.useState(false);

  const [dataSource, setDataSource] = React.useState({});

  // const [currentFormEntry, setCurrentFormEntry] = React.useState({});
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [engineDetailsUIOID, setEngineDetailsUIOID]: any = useState(undefined);

  React.useEffect(() => {
    console.log(url, 'URL');
    const customDataSource: any = new CustomStore({
      key: 'uioid',
      load: () => {
        return fetch(`${url}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        // console.log('Value =' + JSON.stringify(values));
        return fetch(`${url}`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${url}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });
    // const onRowUpdating = (e: any) => {
    //   console.log(e);
    //   e.newData = Object.assign(e.oldData, e.newData);
    //   fetch(`${url}`, {
    //     method: "PUT",
    //     body: JSON.stringify(e.newData),
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //       "Content-Type": "application/json",
    //     },
    //   }).then(customDataSource.load());
    // };

    setDataSource(customDataSource);
  }, [jwt, url]);

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.data };
    });
    setMode('FORM-EDIT');
    // console.log(rowData);
  };
  return (
    <div className="page-ui">
      <header className="page-ui-header">Form Engine</header>
      {mode === 'GRID' ? (
        <DataGrid
          id="grdApplicationRole"
          dataSource={dataSource && dataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled
          columnAutoWidth
          activeStateEnabled
          columnResizingMode="widget"
          allowColumnResizing
          // onRowUpdating={onRowUpdating}
          onInitNewRow={(e) => {
            // console.log(e);
            // e.data.id = Math.floor(Math.random() * 10 + 2);
            // const id = Math.floor(Math.random() * 10 + 2);
            if (privilege.canCreate) {
              setRowData({});
              setShouldEdit(true);
              setMode('ADD-NEW');
            }
          }}
          columnHidingEnabled
          rowAlternationEnabled
        >
          {/* <RowDragging allowReordering /> */}
          <FilterPanel visible />
          <SearchPanel visible />
          <FilterRow visible />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector allowedPageSizes={[2, 10, 20, 50, 100]} showInfo />
          <Grouping contextMenuEnabled />
          {/* <GroupPanel visible /> */}
          <Editing
            mode="row"
            useIcons
            allowUpdating={!!privilege.canEdit}
            allowDeleting={!!privilege.canDelete}
            allowAdding={!!privilege.canCreate}
          />
          {/* <Popup
            title="Application FormEngine"
            showTitle
            width={20}
            height={30}></Popup> */}
          <Export enabled={privilege.canExport} fileName="ApplicationRole" allowExportSelectedData={privilege.canExport} />
          <Column type="buttons">
            <Point
              text="View"
              // text="&#10004; Admit"
              onClick={handleEdit}
              cssClass="edit-btn"
            />
          </Column>
          {/* <Column dataField="id" allowEditing={false}>
            <RequiredRule />
          </Column> */}
          <Column dataField="uioid">
            <RequiredRule />
          </Column>
          <Column dataField="entity_ApplicationCode">
            <RequiredRule />
          </Column>
          <Column dataField="form_FormName">
            <RequiredRule />
          </Column>
          <Column dataField="entity_TaskOID">
            <RequiredRule />
          </Column>
          <Column dataField="entity_UICode">
            <RequiredRule />
          </Column>
          <Column dataField="entity_DataSourceType">
            <RequiredRule />
          </Column>
        </DataGrid>
      ) : (
        <div id="form">
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: '#form' }}
            // onHiding={hideLoadPanel}
            visible={loadPanelVisible}
            showIndicator
            shading
            showPane
            // closeOnOutsideClick={closeOnOutsideClick}
          />
          <div className="toolbar-Form">
            <CustomToolbar
              // getOneUrl={"EcoCADUIEngineHdrs"}
              // getAllUrl={"EcoCADUIEngineHdrs"}
              deleteUrl={`${'EcoCADUIEngineHdrs'}${rowData.uioid ? `/${rowData?.uioid}` : ''}`}
              putUrl={`${'EcoCADUIEngineHdrs'}${rowData.uioid ? `/${rowData?.uioid}` : ''}`}
              postUrl="EcoCADUIEngineHdrs"
              setMode={setMode}
              setShouldEdit={setShouldEdit}
              isEditMode={shouldEdit}
              formData={rowData}
              formKey={rowData.uioid}
              setFormData={setRowData}
              setLoadPanelVisible={setLoadPanelVisible}
              url={`${url}${rowData.uioid ? `/${rowData?.uioid}` : ''}`}
              privilege={privilege}
              setEngineDetailsUIOID={setEngineDetailsUIOID}
              requestHandler="toolbar"
            />
            <FormGeneratorForm
              formType={mode}
              formData={rowData}
              setFormData={setRowData}
              shouldEdit={shouldEdit}
              setLoadPanelVisible={setLoadPanelVisible}
              privilege={privilege}
              url={url}
              formUIOID={engineDetailsUIOID}
              loadPanelVisible={loadPanelVisible}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormEngine;
