import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import FullPageLoader from '../components/general/FullPageLoader';
import Roles from '../ecosystem/modules/roles/Roles';
import MainLayout from '../layout/mainLayout';
import { RedirectRoute } from './configRoutes';

const Login = lazy(() => import('../pages/unauthenticated/login'));
const Register = lazy(() => import('../pages/unauthenticated/register'));
const CreateNewPassword = lazy(() => import('../pages/unauthenticated/change-password'));
const ResetPassword = lazy(() => import('../pages/unauthenticated/rest-password'));
// const MainDashboard = lazy(() => import('../pages/authenticated/dashboard/MainDashboard'));

export default function Router() {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <RedirectRoute>
              <Login />
            </RedirectRoute>
          }
        />
        <Route
          path="/register"
          element={
            <RedirectRoute>
              <Register />
            </RedirectRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <RedirectRoute>
              <ResetPassword />
            </RedirectRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <RedirectRoute>
              <CreateNewPassword />
            </RedirectRoute>
          }
        />

        <Route path="app/*" element={<MainLayout />} />
      </Routes>
    </Suspense>
  );
}
