export interface IAntSelectMapProps{
    data: any[] ;
    key: string | number;
    title: string;
    value: string;
}

/**
 * 
 * @param arr an array to be mapped over
 * @param func the function that defines what the mapped data 
 * @returns the value after the array is maped over
 */
export default function mapFunction<Input, Output>(arr: Input[], func: (arg: Input) => Output): Output[] {
  return arr.map(func);
}

// example  of using the funcyion
// const parsed = mapToLookup(["1", "2", "3"], (item: any) => {
//     const { authorOID, surname, otherNames } = item;
//     return {
//       key: authorOID,
//       title: surname.toUpperCase() + " " + otherNames.toUpperCase(),
//       value: authorOID,
//     };
// });