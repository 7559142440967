import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import { FETCH_CAMS_HR_EMPLOYEE_V2S, FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED } from '../../actionTypes';
import { IHREmployeesV2APIProps } from '../../../types';

export const fetchHREmployeesV2s = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/HREmployeeV2s`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<IHREmployeesV2APIProps>) => {
        let result = res.data.result.data;

        dispatch({
          type: FETCH_CAMS_HR_EMPLOYEE_V2S,
          payload: {
            hrEmployeesV2s: result
          }
        });
      })
      .catch((err: AxiosError) => {
        console.log('FETCH_CAMS_HR_EMPLOYEE_V2_FAILED', err);

        dispatch({
          type: FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED,
          payload: {
            hrEmployeesV2s: err
          }
        });
      });
  };
};
