import {
  FETCH_CAMS_PM_OPERATIONS_ALL, //
  FETCH_CAMS_PM_OPERATIONS_ALL_FAILED,
  EDIT_CAMS_PM_OPERATION,
  CREATE_CAMS_PM_OPERATION,
  FETCH_CAMS_PM_OPERATIONS_BY_STATUS,
  EDIT_CAMS_PM_OPERATION_V2,
  DELETE_CAMS_PM_OPERATION
} from '../../actionTypes'; // import {  ILMSUser } from "../../../types/types";
import { CamsPMOperation, PMOperationReducer } from './camsPMOperationType';

const InitialState: PMOperationReducer = {
  pmOperationLoaded: false,
  pmOperations: [],
  pmOperation: {}
};

const camsPMOperationReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMS_PM_OPERATIONS_ALL:
      return {
        ...state,
        pmOperationLoaded: true,
        pmOperations: [...action.payload.pmOperations]
      };
    case FETCH_CAMS_PM_OPERATIONS_BY_STATUS:
      return {
        ...state,
        pmOperationLoaded: true,
        pmOperations: [...action.payload.pmOperations]
      };

    case FETCH_CAMS_PM_OPERATIONS_ALL_FAILED:
      return {
        ...state,
        pmOperationLoaded: true,
        pmOperations: [...action.payload.pmOperations]
      };

    case EDIT_CAMS_PM_OPERATION:
      const pmOperationAfterEdit = state.pmOperations.filter(
        (pmOperation: any) => pmOperation.camspmOperationOID !== action.payload.pmOperation.camspmOperationOID
      );

      return {
        ...state,
        pmOperations: [action.payload.pmOperation, ...pmOperationAfterEdit]
      };

    // This should be faster than the regular version 'EDIT_CAMS_PM_OPERATION'
    case EDIT_CAMS_PM_OPERATION_V2:
      const pmOperationCollection = state.pmOperations;
      const newRowData: CamsPMOperation = action.payload.pmOperation.camspmOperationOID;
      const indexToUpdate = pmOperationCollection.findIndex((obj: any) => obj.camspmOperationOID === newRowData.camspmOperationOID);

      if (indexToUpdate !== -1) {
        pmOperationCollection[indexToUpdate].offsetDay = newRowData.offsetDay;
        pmOperationCollection[indexToUpdate].operationDate = newRowData.operationDate;
      }

      return {
        ...state,
        pmOperations: pmOperationCollection
      };

    case DELETE_CAMS_PM_OPERATION: {
      const pmOperationCollection = state.pmOperations;
      const rowID: CamsPMOperation = action.payload.pmOperationOID;
      const deletedCollection = pmOperationCollection.filter((obj: any) => obj.camspmOperationOID !== rowID);

      return {
        ...state,
        pmOperations: deletedCollection
      };
    }

    case CREATE_CAMS_PM_OPERATION:
      return {
        ...state,
        pmOperations: [action.payload.pmOperation, ...state.pmOperations]
      };

    default:
      return state;
  }
};

export default camsPMOperationReducer;
