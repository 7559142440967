import React, { useEffect } from 'react';
// import styled from "@emotion/styled";
import axios from 'axios';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  EmptyItem,
  // CompareRule,
  EmailRule,
  PatternRule,
  // RangeRule,
  RequiredRule
  // StringLengthRule,
  // AsyncRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { FileUploader } from 'devextreme-react';
import 'devextreme-react/autocomplete';
// import Toolbar, { Item } from "devextreme-react/toolbar";
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import CustomToolbar from '../CustomToolbar';

// import { baseAPI2 } from "../../../api/APIEndpoints";
import { useSelector } from 'react-redux';
import { IStateProps } from '../../../types/auth';

const colCountByScreen = {
  sm: 1,
  md: 2,
  lg: 3
};

const buttonOptions = {
  text: 'Save',
  type: 'success',
  useSubmitBehavior: true
};

const dateBoxOptions = {
  invalidDateMessage: 'The date must have the following format: MM/dd/yyyy'
};
const data = [
  {
    ID: 1,
    Name: 'YES'
  },
  {
    ID: 2,
    Name: 'NO'
  }
];

const CamsCustomCompanyForm = ({ formMode, closePopup, fetchedData }: any) => {
  // const formRef: any = React.useRef(null);
  // const saveBtnRef: any = React.useRef(null);
  // console.log("FETCHED DATA", fetchedData);
  const formRef: any = React.useRef();
  const hiddenBtnRef: any = React.useRef();

  const [dataFetched, setDataFetched]: any = React.useState(false);
  const [states, setStates]: any = React.useState();
  const [country, setCountry]: any = React.useState(null);
  const [businessSector, setBusinessSector]: any = React.useState(null);
  const [formValues, setFormValues]: any = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState(false);
  const [shouldEdit, setShouldEdit] = React.useState(false);
  const [rowID, setRowID] = React.useState(0);

  const privilege = {
    canEdit: 1,
    canCreate: 0,
    canDelete: 0,
    canPost: 0,
    canExport: 0
  };
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);
  const url = `${baseAPI}/EcoCompany`;
  const onFieldDataChanged = (e: any) => {
    // console.log(e.component.option("formData"));
    // setCompanyInfoData(e.component.option("formData"));
  };

  useEffect(() => {
    if (!fetchedData || !fetchedData.result) {
      setLoading(true);
      axios
        .get(`${url}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((res) => {
          const data = res.data;
          setCountry(data.referenceObjects.countries.selectData);
          setStates(data.referenceObjects.states.selectData);
          setBusinessSector(data.referenceObjects.businessSectors.selectData);
          setFormValues(data.result[0]);

          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          notify(error.message, 'error', 3000);
          console.log(error);

          setLoading(false);
        });
    } else {
      setFormValues(fetchedData.result[0]);
      setCountry(fetchedData.referenceObjects.countries.selectData);
      setStates(fetchedData.referenceObjects.states.selectData);
      setBusinessSector(fetchedData.referenceObjects.businessSectors.selectData);
      // console.log(res.data);
      setLoading(false);
      setDataFetched(true);
    }
  }, [jwt, url, fetchedData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(e, formValues);

    const validationSummary = formRef.current.instance._$element[0].children[1].children;
    console.log(formRef.current.instance._$element[0].children[1].children);

    if (validationSummary.length > 0) {
      console.log(validationSummary[0]);
      return false;
    }

    setLoading(true);
    axios
      .put(`${url}`, formValues, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => {
        setLoading(false);

        const companyName = res.data.result ? res.data.result[0].companyName : formValues.companyName;

        notify(
          {
            message: 'Company Information updated successfully',
            position: {
              my: 'center top',
              at: 'center top'
            }
          },
          'success',
          3000
        );

        if (formMode === 'POPUP') return closePopup(companyName);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          {
            message: 'An error occurred while updating company record',
            position: {
              my: 'center top',
              at: 'center top'
            }
          },
          'danger',
          3000
        );
      });
  };

  function screenByWidth(width: any) {
    if (formMode !== 'POPUP') {
      return width > 1140 ? 'lg' : width > 720 ? 'md' : 'sm';
    } else {
      return 'sm';
    }
  }
  const uploadApi = `${baseAPI}/FileManagement/upload-file?Folder=Images&FileName=${'logo-Charlvon'}&Extension=png&FileSource=CamsFile=${rowID}`;
  const fileExtensions = ['.png', '.jpg'];

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#user' }}
        // onHiding={hideLoadPanel}
        visible={loading}
        showIndicator={true}
        shading={true}
        showPane={true}
        // closeOnOutsideClick={closeOnOutsideClick}
      />
      {formMode !== 'POPUP' && (
        <CustomToolbar
          formRef={formRef}
          btnRef={hiddenBtnRef}
          hideBackButton
          setMode={setMode}
          isEditMode={mode}
          setShouldEdit={setShouldEdit}
          setFormData={setFormValues}
          formData={dataFetched && formValues}
          formKey={true}
          setLoadPanelVisible={setLoading}
          url={`${url}`}
          // getOneUrl={"/EcoCompany"}
          // getAllUrl={"/EcoCompany"}
          deleteUrl={'/EcoCompany'}
          putUrl={'EcoCompany'}
          postUrl={'/EcoCompany'}
          privilege={privilege}
          setEngineDetailsUIOID={null}

          // uiType={null}
        />
      )}
      <form onSubmit={handleSubmit}>
        <Form
          ref={formRef}
          formData={formValues}
          disabled={formMode !== 'POPUP' ? !shouldEdit : false}
          showColonAfterLabel={true}
          onFieldDataChanged={onFieldDataChanged}
          showValidationSummary={true}
          validationGroup="companyData"
          colCountByScreen={colCountByScreen}
          screenByWidth={screenByWidth}
          labelLocation="top"
          minColWidth={233}
          colCount="auto"
        >
          <GroupItem caption=" General Info" colSpan={3} cssClass={'group-caption'}>
            {' '}
          </GroupItem>
          <SimpleItem dataField="companyOID" editorOptions={{ disabled: true }}>
            <RequiredRule message="Company OID is required" />
            <PatternRule message="" pattern={/[0-9]+$/} />
          </SimpleItem>
          <SimpleItem dataField="companyName" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="Company Name is required" />
            {/* <PatternRule message="" pattern={/w+$/} /> */}
          </SimpleItem>
          {/* <SimpleItem
            dataField="businessSector"
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: businessSector && businessSector,
              searchEnabled: true,
              valueExpr: 'value',
              displayExpr: 'title'
              // defaultValue: "businessSector",
              // onValueChange: (e: any) => {
              //   console.log(e);
              // },
            }}
          ></SimpleItem> */}
          <SimpleItem dataField="email" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
          </SimpleItem>

          <SimpleItem dataField="companyName" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="Company Name is required" />
            {/* <PatternRule message="" pattern={/w+$/} /> */}
          </SimpleItem>
          <SimpleItem dataField="message" editorType="dxTextBox" isRequired={true}>
            {/* <RequiredRule message="Business City is required" /> */}
          </SimpleItem>

          <GroupItem caption="Address" colSpan={3} cssClass={'group-caption'}>
            {' '}
          </GroupItem>
          <SimpleItem dataField="address1" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="Company address is required" />
          </SimpleItem>

          <SimpleItem dataField="address2" editorType="dxTextBox" isRequired={true}></SimpleItem>

          <SimpleItem
            dataField="country"
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: country && country,
              searchEnabled: true,
              valueExpr: 'value',
              displayExpr: 'title'
              // onValueChange: (e: any) => {
              //   console.log(e);
              // },
            }}
          >
            <RequiredRule message="Country is required" />
          </SimpleItem>

          <SimpleItem
            dataField="state"
            editorType="dxSelectBox"
            editorOptions={{
              defaultValue: states && states,
              dataSource: states && states,
              searchEnabled: true,
              valueExpr: 'value',
              displayExpr: 'title'
            }}
          >
            <RequiredRule message="Business State is required" />
          </SimpleItem>

          <SimpleItem dataField="city" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="Business City is required" />
          </SimpleItem>

          <SimpleItem dataField="zip"></SimpleItem>

          <SimpleItem dataField="website"></SimpleItem>
          <SimpleItem dataField="logoFileName" editorType="dxTextBox" isRequired={true}>
            <RequiredRule message="logoFileName is required" />
          </SimpleItem>
          <SimpleItem>
            <FileUploader
              name="files"
              multiple={false}
              uploadMode="useButtons"
              uploadUrl={uploadApi}
              maxFileSize={4000000}
              allowedFileExtensions={fileExtensions}
            />
          </SimpleItem>

          <EmptyItem colSpan={2} />
          {/* <EmptyItem /> */}
          <div className="TransactionInfo">
            <GroupItem caption="Transaction Info" colSpan={3} cssClass={'group-caption'}>
              {' '}
            </GroupItem>
            <SimpleItem dataField="acceptPOS" isRequired={true}>
              <SelectBox
                dataSource={data}
                valueExpr="ID"
                disabled
                defaultValue={formValues ? (formValues.acceptPOS === 'YES' ? 1 : 0) : ''}
                displayExpr="Name"
              />
              <RequiredRule message="acceptPOS is required" />
            </SimpleItem>

            <SimpleItem dataField="fedralTaxNumber">
              <RequiredRule message="federal Tax Number is required" />
            </SimpleItem>
            {/* <SimpleItem dataField="fedralTaxNumber">
            <RequiredRule message="Business City is required" />
          </SimpleItem> */}
            <SimpleItem dataField="fiscalPeriodStartDate" editorType="dxDateBox" editorOptions={dateBoxOptions} isRequired={true}>
              <Label text="fiscal Period Start Date" />
              <RequiredRule message="fiscal Period Start Date is required" />
            </SimpleItem>
          </div>

          {formMode === 'POPUP' && <ButtonItem cssClass={'tf-form-btn'} horizontalAlignment="left" buttonOptions={buttonOptions} />}
          <ButtonItem cssClass={'hidden-btn tf-form-btn'} ref={hiddenBtnRef} horizontalAlignment="left" buttonOptions={buttonOptions} />
        </Form>
      </form>
    </>
  );
};

export default CamsCustomCompanyForm;
