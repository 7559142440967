//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : GRID-FORM UI
// SIGNIFICANCE: Grid to Form structure generator
// DATE CREATED : 20 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************

import * as React from 'react';
import { useState, useEffect } from 'react';
import FormUI from './FormUI';
import GridUI from './GridUI';
import { TIndexType } from './types';
import { LoadPanel } from 'devextreme-react/load-panel';

const GridForm = ({ config }: any) => {
  //state should be decided by the FormUITYpe from the form engine
  const [uiType] = useState(() => {
    return config.form_UIType.toUpperCase().replaceAll(' ', '');
  });
  const [mode, setMode] = useState('GRID');

  const [rowData, setRowData]: any = useState({});
  const [loadPanelVisible, setLoadPanelVisible]: any = useState(true);

  let gridData: TIndexType = {};
  let formData: TIndexType = {};
  let columnFieldData: any[] = [];
  let gridColumnData: any[] = [];

  if (typeof config === 'object') {
    for (const data in config) {
      if (data.includes('form') || data.includes('Form')) {
        formData[data] = config[data];
      } else {
        gridData[data] = config[data];
      }
    }
    columnFieldData = gridData.oEcoCADUIEngineDtl;
    gridColumnData = columnFieldData.filter((data: any) => data.grid_ShowInGrid === true);
    gridColumnData.sort((a: any, b: any) => {
      if (a.uI_SortOrder < b.uI_SortOrder) {
        return -1;
      }
      if (a.uI_SortOrder > b.uI_SortOrder) {
        return 1;
      }
      // order are equal
      return 0;
    });
    // console.log("GRID DATA", gridData);
  }

  useEffect(() => {}, []);
  return (
    <div id={'form'}>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#form' }}
        // onHiding={hideLoadPanel}
        visible={loadPanelVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
        // closeOnOutsideClick={closeOnOutsideClick}
      />
      {mode === 'GRID' && uiType.includes('LISTVIEW') ? (
        <GridUI
          getOneUrl={gridData.endPoint_GetSingleGridData}
          getAllUrl={gridData.endPoint_GetAllGridData}
          deleteUrl={gridData.endPoint_DeleteGridData}
          putUrl={gridData.endPoint_PutGridData}
          postUrl={gridData.endPoint_PostGridData}
          formData={formData}
          gridData={gridData}
          setRowData={setRowData}
          config={config}
          columnFieldData={gridColumnData}
          setMode={setMode}
          mode={mode}
          uiType={uiType}
          setLoadPanelVisible={setLoadPanelVisible}
        />
      ) : (
        <FormUI
          getOneUrl={gridData.endPoint_GetSingleGridData}
          getAllUrl={gridData.endPoint_GetAllGridData}
          deleteUrl={gridData.endPoint_DeleteGridData}
          putUrl={gridData.endPoint_PutGridData}
          postUrl={gridData.endPoint_PostGridData}
          formData={formData}
          gridData={gridData}
          rowData={rowData}
          config={config}
          columnFieldData={columnFieldData}
          setMode={setMode}
          uiType={uiType}
          mode={mode}
          setRowData={setRowData}
          setLoadPanelVisible={setLoadPanelVisible}
        />
      )}
    </div>
  );
};

export default GridForm;
