//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : Preference Card UI
// SIGNIFICANCE:
// DATE CREATED : May 24 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************

import React, { useEffect } from 'react';

import './Card.css';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Button } from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import TextBox from 'devextreme-react/text-box';
import { IPreferenceCard } from '../../ecosystem/modules/form-engine/types';
import { IStateProps } from '../../types/auth';
// import { createLookup } from "../../../utils/createLookup";

const PreferenceCard = ({ dataLoaded, title, records, id }: IPreferenceCard) => {
  // console.log('IN PREFERENCE CARD', records);

  useEffect(() => {}, []);
  return (
    <section className="wrapper">
      <header>{title && <h3 className="h3">{title}</h3>}</header>
      <table className="table">
        {/* <thead> */}
        <TableHead
          headTitles={[
            'OID',
            'Settings Code',
            // "Input UI Type",
            'Value',
            'Description',
            // "Option List",
            'Operation'
          ]}
        />
        {/* </thead> */}
        <tbody>{records && records.map((record, index) => <CustomTableData key={record + index} isHeader={true} record={record} />)}</tbody>
      </table>
    </section>
  );
};

export default PreferenceCard;

export const CustomTableData = ({ record }: any) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const updateSettingURL = baseAPI + '/EcoSystemSettings';

  //   const { headTitles, isHeader, record } = props;
  const [, setSaving] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [valueEdited, setValueEdited]: any = React.useState(record.value);
  // const [rowData, setRowData] = React.useState({
  //   oid: 8,
  //   description: "System Account",
  //   value: "string",
  //   settingsCode: 1,
  //   categoryOrder: 1,
  //   optionsList: "string",
  //   inputUIType: "string",
  // });

  // console.log("RECORD IN ROW DATA", record);

  let { oid, description, value, settingsCode, inputUIType } = record;

  const handleSelect = (value: any) => {
    setValueEdited(() => value.selectedItem);
    console.log(value.selectedItem);
  };

  const handleEdit = (e: any) => {
    setIsEditing(true);
  };

  const handleSave = (e: any) => {
    setSaving(true);
    //save to db using the data in rowData
    if (valueEdited) {
      console.log('UPDATING...', valueEdited, record.value, record.oid);

      //save to backend
      axios
        .put(updateSettingURL, {
          value: valueEdited || record.value,
          oid: record.oid
        })
        .then((res) => {
          console.log('UPDATE COMPLETE');

          //update the row values
          record = {
            ...record,
            value: valueEdited
          };

          // message.success("Value updated successfully", 3);
          setSaving(false);
          setIsEditing(false);
          // setTimeout(() => window.location.reload());
        })
        .catch((err) => {
          console.log(err);
          // message.error("Value not updated, please check your network", 3);
          setValueEdited(record.value);
          setSaving(false);
          setIsEditing(false);
        });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleChange = (value: any) => {
    console.log(value.value);
    setValueEdited(() => value.value);
  };

  useEffect(() => {}, []);
  return (
    <tr>
      {record && (
        <>
          <td key={oid}>{oid}</td>
          <td key={settingsCode + oid}>{settingsCode}</td>
          <td key={'value' + oid}>
            {isEditing && inputUIType.toLowerCase() === 'textbox' ? (
              !!Number(value) ? (
                <NumberBox defaultValue={+value} onValueChanged={handleChange} />
              ) : (
                <TextBox onValueChanged={handleChange} mode={'text'} defaultValue={valueEdited || value} />
              )
            ) : isEditing && inputUIType.toLowerCase() === 'select' ? (
              <SelectBox
                //select box with items
                // items={createLookup(optionsList, "string", "")}
                // defaultValue={value}
                // placeholder="Choose Product"
                // showClearButton={true}
                // readOnly={true}
                onSelectionChanged={handleSelect}
                defaultValue={valueEdited || value}
                // disabled={true}
                //select box 2
                // dataSource={createLookup(optionsList, inputUIType, "GRID")}
                // displayExpr="title"
                // valueExpr="value"
              />
            ) : isEditing ? (
              <NumberBox defaultValue={+value} onValueChanged={handleChange} />
            ) : (
              valueEdited || value
            )}
          </td>
          <td key={'desc' + description + oid}>{description}</td>
          {/* <td key={optionsList + oid}>{optionsList}</td> */}
          <td>
            {isEditing ? (
              <span>
                {/* <Button
                  style={{ backgroundColor: "#1969456b", color: "#000" }}
                  loading={saving}
                  onClick={handleSave}>
                  Save
                </Button>{" "}
                <Button danger onClick={handleCancel}>
                  Cancel
                </Button> */}
                <Button width={80} text="Save" type="success" stylingMode="contained" onClick={handleSave} />
                <Button
                  width={80}
                  style={{ marginLeft: '10px' }}
                  text="Cancel"
                  type="danger"
                  stylingMode="contained"
                  onClick={handleCancel}
                />
              </span>
            ) : (
              <Button style={{ backgroundColor: '#195069', color: '#fff' }} onClick={handleEdit}>
                Edit
              </Button>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export const TableHead = (props: any) => {
  const { headTitles } = props;
  return headTitles.map((title: any) => <th key={title}>{title}</th>);
};
