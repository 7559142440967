//*************************************************************
// PROJECT NAME : CADERP
// CLASS        : AspNetRoles
// DATE CREATED : 19 April 2021
// TIME CREATED : 06:13:31
// DEVELOPER    : Ibinaiye Wale Kayode & Simeon Kenneth Tobechi
//*************************************************************
import React, { useState } from 'react';
// import Axios from "axios";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
// import { Item } from "devextreme-react/form";
// import { baseAPI2 } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  // Lookup,
  // Popup,
  // Position,
  Button as Point,
  Editing,
  Export,
  Pager,
  Grouping,
  // GroupPanel,
  // RowDragging,
  Column,
  RequiredRule
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

import // ButtonItem,
// GroupItem,
// SimpleItem,
// Label,
// CompareRule,
// EmailRule,
// PatternRule,
// RangeRule,
// RequiredRule,
// StringLengthRule,
// AsyncRule,
'devextreme-react/form';
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
// import Toolbar, { Item } from "devextreme-react/toolbar";
// import styled from "@emotion/styled";
import '../grid.scss';
// import notify from "devextreme/ui/notify";

import { useSelector } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
// import "whatwg-fetch";
// import { baseAPI2 } from "../../../api/APIEndpoints";
import DynamicForm from '../DynamicForm';
import CustomToolbar from '../CustomToolbar';
import { IStateProps } from '../../../types/auth';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    // console.log(response);
    if (response.status === 401) {
      throw Error('Your Login session has expired. Please logout and sign in again.');
    }
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

export const Roles = () => {
  //Get JWT Token from the store
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const url = `ApplicationRole`;

  const privilege = {
    canEdit: 1,
    canCreate: 1,
    canDelete: 1,
    canPost: 1,
    canExport: 1
  };

  const [mode, setMode] = React.useState('GRID');
  // const [rowID, setRowID]: any = React.useState(null);
  const [rowData, setRowData]: any = React.useState({});
  const [shouldEdit, setShouldEdit] = React.useState(false);

  const [dataSource, setDataSource] = React.useState({});

  // const [currentFormEntry, setCurrentFormEntry] = React.useState({});
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'id',
      load: () => {
        return fetch(`${baseAPI}/ApplicationRole`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        // console.log("Value =" + JSON.stringify(values));
        return fetch(`${baseAPI}/ApplicationRole`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/ApplicationRole`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });
    // const onRowUpdating = (e: any) => {
    //   console.log(e);
    //   e.newData = Object.assign(e.oldData, e.newData);
    //   fetch(`${baseAPI}/ApplicationRole`, {
    //     method: "PUT",
    //     body: JSON.stringify(e.newData),
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //       "Content-Type": "application/json",
    //     },
    //   }).then(customDataSource.load());
    // };

    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => ({ ...e.row.data }));
    setMode('FORM-EDIT');
    // console.log(rowData);
  };
  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>Roles Definition</header>
      {mode === 'GRID' ? (
        <DataGrid
          id="grdApplicationRole"
          dataSource={dataSource && dataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          // onRowUpdating={onRowUpdating}
          onInitNewRow={(e) => {
            // console.log(e);
            // e.data.id = Math.floor(Math.random() * 10 + 2);
            // const id = Math.floor(Math.random() * 10 + 2);
            if (privilege.canCreate) {
              setRowData({});
              setShouldEdit(true);
              setMode('ADD-NEW');
            }
          }}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
        >
          {/* <RowDragging allowReordering={true} /> */}
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
          <Grouping contextMenuEnabled={true} />
          {/* <GroupPanel visible={true} /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={privilege.canEdit ? true : false}
            allowDeleting={privilege.canDelete ? true : false}
            allowAdding={privilege.canCreate ? true : false}
          />
          {/* <Popup
            title="Application Roles"
            showTitle={true}
            width={20}
            height={30}></Popup> */}
          <Export enabled={privilege.canExport} fileName="ApplicationRole" allowExportSelectedData={privilege.canExport} />
          <Column type="buttons">
            <Point
              text="View"
              // text="&#10004; Admit"
              onClick={handleEdit}
              cssClass={'edit-btn'}
            />
          </Column>
          <Column dataField="id" allowEditing={false}>
            <RequiredRule />
          </Column>
          <Column dataField="name">
            <RequiredRule />
          </Column>
          <Column dataField="rolePriority">
            <RequiredRule />
          </Column>
          <Column dataField="description">
            <RequiredRule />
          </Column>
          {/* <Column dataField="normalizedName">
            <RequiredRule />
          </Column> */}
          <Column dataField="concurrencyStamp">
            <RequiredRule />
          </Column>
        </DataGrid>
      ) : (
        <div id={'form'}>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: '#form' }}
            // onHiding={hideLoadPanel}
            visible={loadPanelVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            // closeOnOutsideClick={closeOnOutsideClick}
          />
          <div className={'toolbar-Form'}>
            <CustomToolbar
              setMode={setMode}
              setShouldEdit={setShouldEdit}
              isEditMode={shouldEdit}
              keyField="roleOID"
              formData={rowData}
              formKey={rowData.id}
              setFormData={setRowData}
              setLoadPanelVisible={setLoadPanelVisible}
              url={url}
              privilege={privilege}
              // getOneUrl={url + "/[roleOID]"}
              // getAllUrl={url}
              deleteUrl={url + '/[roleOID]'}
              putUrl={url}
              postUrl={url}
              requestHandler={'toolbar'}
            />
            <DynamicForm
              formType={mode}
              formData={rowData}
              setFormData={setRowData}
              shouldEdit={shouldEdit}
              setLoadPanelVisible={setLoadPanelVisible}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Roles;

// Needed to process selected value(s) in the SelectBox, Lookup, Autocomplete, and DropDownBox
// byKey: (key) => {
//     return fetch(`https://mydomain.com/MyDataService?id=${key}`)
//         .then(handleErrors);
// }
