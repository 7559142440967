import React, { useState, useEffect } from 'react';
import { Formik, Form as FForm, FieldArray } from 'formik';
import styled from '@emotion/styled';
import { Grid, Header, Icon, Form, Button, Message, Label } from 'semantic-ui-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MySelectInput } from '.';
import { useGetSystemCodeBySetupType } from '../../../store/actions';
import { IStateProps, ISystemCodeProps } from '../../../types';
import { mapFunction } from '../../../util';
// import notify from 'devextreme/ui/notify';
// import axios, { AxiosError } from 'axios';
// import type { AxiosResponse } from 'axios';

// const dueEvery = [
//   {
//     key: 'day',
//     text: 'day',
//     value: 'day'
//   },
//   {
//     key: 'month',
//     text: 'month',
//     value: 'month'
//   },
//   {
//     key: 'year',
//     text: 'year',
//     value: 'year'
//   }
// ];

interface IProps {
  rowID?: number;
  setMode?: any;
  fileURL: string;
}

const UserProfilesForm: React.FC<IProps> = ({ rowID, setMode, fileURL }) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsLocations } = useSelector((state: IStateProps) => state.cams?.camsLocation);
  const { hrEmployeesV2s } = useSelector((state: IStateProps) => state?.cams?.hrEmployeeV2);
  // const userID = useSelector((state: IStateProps) => state?.user?.profile?.userID);
  // const [reportURL, setReportURL] = useState('');
  // const [fileURL, setFileURL] = useState('');
  const YesNoOptions: ISystemCodeProps[] = useGetSystemCodeBySetupType('YesNo');
  const [initialValues, setInitialValues] = useState({
    camsEmployeeOID: 0,
    labourRate: '',
    canBeAssigneToWO: '',
    notifyViaApp: '',
    notifyViaEmail: '',
    notifyMaintenanceDueLocation: '',
    notifyMaintenanceDueEquipment: '',
    notifyRenewalDueForLocations: '',
    notifyWorkOrderDueForLocation: '',
    notifyWorkOrderDue: '',
    notifyWorkOrderDueEquipment: '',
    notifyRequestRepairCompleted: '',
    notifyDailyInventoryLowStock: '',
    notifyImmediateInventoryLowStock: '',
    employeeOID: 0,
    hrEmployeeV2: null,
    camsUserLocationsVirtual: [
      {
        // camsUserLocationOID: 2,
        // camsUserOID: this,
        // locationOID: 0
        // camsUserVrtual: null,
        // camsLocationVrtual: null,
      }
    ]
  });

  const YesNo = mapFunction(YesNoOptions, (element) => {
    const { description } = element;
    return {
      key: description,
      text: description,
      value: description
    };
  });

  const locationOption = mapFunction(camsLocations, (element) => {
    const { locationName, locationOID } = element;
    return {
      key: locationOID,
      text: locationName,
      value: locationOID
    };
  });
  const employeeOption = mapFunction(hrEmployeesV2s, (element) => {
    const { otherNames, surname, employeeOID } = element;
    return {
      key: employeeOID,
      text: otherNames + ' ' + surname,
      value: employeeOID
    };
  });

  useEffect(() => {
    if (rowID) {
      const fetchRowData = async () => {
        const url = `${baseAPI}/CAMSUsers/${rowID}`;
        axios
          .get(url)
          .then((response) => {
            return response.data.result[0];
          })
          .then((data: any) => {
            setInitialValues(data);
          });
      };
      fetchRowData();
    }
  }, [rowID, baseAPI]);

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleEditFormSubmit = (detail: any) => {
    // console.log(detail);
    const url = `${baseAPI}/CAMSUsers/${rowID}`;
    axios
      .put(url, detail)
      .then(() => setMode('GRID'))
      .catch((err) => {
        // console.log('Edit Form submission Failed')
      });
  };

  const reportDownloadURL = `${baseAPI}/CAMSReports/DownloadReport?vFullFileName=${fileURL}`;

  const handleAddFormSubmit = (detail: any) => {
    // console.log(detail);
    const url = `${baseAPI}/CAMSUsers`;
    axios
      .post(url, detail)
      .then(() => setMode('GRID'))
      .catch((err) => {
        // console.log('Add Form submission Failed')
      });
  };

  return (
    <StyledProvider>
      <Header as="h2">
        <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
        User Profiles
      </Header>
      <Message color="brown" size="mini">
        <Message.Header> Manage Cams users </Message.Header>
        <p> You can create and manage Cams users profile. </p>
      </Message>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values);
        }}
      >
        {({ values }) => (
          <FForm className="ui form">
            <Grid fluid columns={2}>
              <Grid.Row>
                <Form.Group style={{ width: '100%' }} widths={2} inline>
                  <MySelectInput label="User Name" placeholder="" name="employeeOID" options={employeeOption} />

                  <Button
                    style={{ marginLeft: 'auto' }}
                    floated="right"
                    type="button"
                    primary
                    content="View Week Report"
                    target="_blank"
                    // onClick={handleClick}
                    href={`https://view.officeapps.live.com/op/embed.aspx?src=${reportDownloadURL}`}
                  />
                  {/* <MyTextInput label="Labour Rate" placeholder="" name="labourRate" /> */}
                </Form.Group>
                {/* <Form.Group style={{ width: '100%' }} widths={2} inline>
                  <MySelectInput label="Default Location" placeholder="" name="hold1" options={locationOption} />
                  <MySelectInput label="Default Warehouse" placeholder="" name="hold3" options={dueEvery} />
                </Form.Group> */}
              </Grid.Row>
              <Grid.Row width="16" columns={2}>
                <Grid.Column>
                  <Header className="text-pink-500" as="h2" dividing>
                    Activate Notifications
                  </Header>
                  <MySelectInput label="Can Be Assigned To Work Order" placeholder="" name="canBeAssigneToWO" options={YesNo} />
                  <MySelectInput label="Notify Via App" placeholder="" name="notifyViaApp" options={YesNo} />
                  <MySelectInput label="Notify Via Email" placeholder="" name="notifyViaEmail" options={YesNo} />
                  <MySelectInput
                    label="Notify Maintenance Due Location"
                    placeholder=""
                    name="notifyMaintenanceDueLocation"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Maintenance Due Equipment"
                    placeholder=""
                    name="notifyMaintenanceDueEquipment"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Renewal Due For Locations"
                    placeholder=""
                    name="notifyRenewalDueForLocations"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Work Order Due For Location"
                    placeholder=""
                    name="notifyWorkOrderDueForLocation"
                    options={YesNo}
                  />
                  <MySelectInput label="Notify Work Order Due" placeholder="" name="notifyWorkOrderDue" options={YesNo} />
                  <MySelectInput
                    label="Notify Work Order Due Equipment"
                    placeholder=""
                    name="notifyWorkOrderDueEquipment"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Request Repair Completed"
                    placeholder=""
                    name="notifyRequestRepairCompleted"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Daily Inventory Low Stock"
                    placeholder=""
                    name="notifyDailyInventoryLowStock"
                    options={YesNo}
                  />
                  <MySelectInput
                    label="Notify Immediate Inventory Low Stock"
                    placeholder=""
                    name="notifyImmediateInventoryLowStock"
                    options={YesNo}
                  />
                  {/* <M
                  ySelectInput
                    label="Notify Immediate Inventory Low Stock"
                    placeholder=""
                    name="notifyImmediateInventoryLowStock"
                    options={YesNo}
                  /> */}
                </Grid.Column>
                <Grid.Column>
                  <FieldArray name="camsUserLocationsVirtual">
                    {({ insert, remove, push }) => (
                      <>
                        <Header as="h2" dividing>
                          Assign Location
                          <Button
                            floated="right"
                            type="button"
                            primary
                            onClick={() =>
                              push({
                                locationOID: 0,
                                camsUserOID: values.camsEmployeeOID
                              })
                            }
                          >
                            Add
                          </Button>
                        </Header>
                        <div>
                          {values.camsUserLocationsVirtual.length > 0 &&
                            values.camsUserLocationsVirtual.map((friend, index) => (
                              <div className="row" key={index}>
                                <div className="it__details_form_item">
                                  <MySelectInput
                                    label={`Location ${index}`}
                                    name={`camsUserLocationsVirtual.${index}.locationOID`}
                                    placeholder=""
                                    options={locationOption}
                                  />
                                  <Label color="red" size="big" as="a" type="button" className="secondary" onClick={() => remove(index)}>
                                    X
                                  </Label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </FieldArray>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div
              style={{
                display: 'flex',
                width: '100%',
                lineHeight: '25px',
                justifyContent: 'flex-end'
              }}
            >
              <Button type="submit" primary content="Save" />
              <Button content="Cancel" onClick={handleCancelClick} />
            </div>
          </FForm>
        )}
      </Formik>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  .ui.grid > .row {
    padding: 1rem !important;
  }

  .it__details_form_item {
    align-items: baseline;
    width: 100%;
    display: flex;
    column-gap: 5px;

    div {
      width: 90%;
    }
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }
`;

export default UserProfilesForm;
