import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, Table } from 'semantic-ui-react';
import MyTextInput from './input-controls/MyInlineTextInput';
import axios from 'axios';
import type { AxiosError, AxiosResponse } from 'axios';
import RadioPass from './radio-controls/pass';
import RadioFail from './radio-controls/fail';
import RadioPending from './radio-controls/pending';
import notify from 'devextreme/ui/notify';
import { mapFunction } from '../../../util';

interface IOperationProps {
  values: any;
  setInitialValues: any;
  baseAPI: string;
  initialValues: any;
}

const PerformInspectionDetails: React.FC<IOperationProps> = ({ values, setInitialValues, baseAPI, initialValues }) => {
  const {
    values: { inspectionTemplateOID }
  } = useFormikContext();

  useEffect(() => {
    if (initialValues['inspectionTemplateOID'] !== inspectionTemplateOID) {
      const url = `${baseAPI}/CAMSInspectionTemplates/${inspectionTemplateOID}`;
      axios
        .get(url)
        .then((res: AxiosResponse) => {
          return res.data.result[0];
        })

        .then((data) => {
          setInitialValues((prev: any) => {
            return {
              ...values,
              camsInspectionLogDetailsVirtual: [...data['camsInspectionTemplateTasksVirtual']]
            };
          });
        })
        .catch((err: AxiosError) => {
          notify('', 'error', 3000);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionTemplateOID]);

  const clearAllClick = () => {
    const clearedSubArr = mapFunction(values['camsInspectionLogDetailsVirtual'], (item: any) => {
      return {
        ...item,
        inspectionStatus: '',
        remarks: ''
      };
    });

    setInitialValues(() => {
      return {
        ...values,
        camsInspectionLogDetailsVirtual: clearedSubArr
      };
    });
  };

  const allDoneClick = () => {
    const finishedSubArr = mapFunction(values['camsInspectionLogDetailsVirtual'], (item: any) => {
      return {
        ...item,
        inspectionStatus: 'done'
      };
    });

    setInitialValues(() => {
      return {
        ...values,
        camsInspectionLogDetailsVirtual: finishedSubArr
      };
    });
  };

  return (
    <>
      {values.camsInspectionLogDetailsVirtual.length > 0 && (
        <div className="w-full">
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>sNo</Table.HeaderCell>
                <Table.HeaderCell width={6}>PM Task</Table.HeaderCell>
                <Table.HeaderCell>Done</Table.HeaderCell>
                <Table.HeaderCell>Not Done</Table.HeaderCell>
                <Table.HeaderCell>Pending</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <>
                {values.camsInspectionLogDetailsVirtual.map((item: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell> {item.taskDescription}</Table.Cell>
                    <Table.Cell>
                      <RadioPass
                        checked={item.inspectionStatus === 'done'}
                        value="done"
                        name={`camsInspectionLogDetailsVirtual.${index}.inspectionStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RadioFail
                        checked={item.inspectionStatus === 'notDone'}
                        value="notDone"
                        name={`camsInspectionLogDetailsVirtual.${index}.inspectionStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RadioPending
                        checked={item.inspectionStatus === 'pending'}
                        value="pending"
                        name={`camsInspectionLogDetailsVirtual.${index}.inspectionStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyTextInput placeholder="" name={`camsInspectionLogDetailsVirtual.${index}.remarks`} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            </Table.Body>
          </Table>
          <div>
            <Button onClick={allDoneClick} type="button">
              All Done
            </Button>
            <Button onClick={clearAllClick} type="button">
              Clear All
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformInspectionDetails;
