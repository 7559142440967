import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
// import { persistor } from '../store/createStore';
import { IStateProps } from '../types/auth';

const ServiceRoutes = ({ children }: { children: JSX.Element }) => {
  const userData = useSelector((state: IStateProps) => state?.user);
  const token = userData ? userData?.token : null;
  const allowedRoutes = userData ? userData?.allowedRoutes : [];
  const { pathname } = useLocation();

  const isRouteAvailable = allowedRoutes?.some((route: string) => pathname.replace('/app', '').includes(route));

  // const isRouteAvailable = allowedRoutes?.includes(pathname.replace('/app', '') || '404');

  if (!token) {
    return <Navigate to="/login" />;
  }

  return isRouteAvailable ? children : <Navigate to="/app" />;
};

export default ServiceRoutes;

export const RedirectRoute = ({ children }: { children: JSX.Element }) => {
  const userData = useSelector((state: IStateProps) => state?.user);
  const token = userData ? userData?.token : null;

  return token ? <Navigate to={'/app'} /> : children;
};

export const AuthRoutes = ({ children, redirectTo = '/login' }: { children: JSX.Element; redirectTo?: string }) => {
  const userData = useSelector((state: IStateProps) => state?.user);
  const token = userData ? userData?.token : null;

  return !token ? <Navigate to={redirectTo} /> : children;
};
