import { LOOKUP_ADDED } from '../../actionTypes';

const initialState: any = {};

export default function lookupReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case LOOKUP_ADDED:
      // console.log(action.payload, "PAYLOAD OFLOOKUP");
      return {
        ...state,
        ...action.payload
      };
    default:
      return { ...state };
  }
}
