import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Message, Form, Header, Icon, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { Formik, Form as FForm, FieldArray } from 'formik';

import styled from '@emotion/styled';
import { IStateProps } from '../../../types/auth';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { PerformInspectionDetails } from '.';
import MySelectInput from './input-controls/MyInlineSelectInput';
import MyDateInput from './input-controls/MyInlineDateInput';
import MyTextInput from './input-controls/MyInlineTextInput';
import MyTextArea from './input-controls/MyTextArea';

function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

interface IPIGridProps {
  rowID?: number;
  setMode: any;
  fileURL?: string;
}

const PerformInspectionForm: React.FC<IPIGridProps> = ({ rowID, setMode, fileURL }) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const [initialValues, setInitialValues] = useState({
    inspectionLogOID: 0,
    assignee: '',
    tDate: '',
    currentHours: 0,
    notes: '',
    allOk: '',
    equipmentOID: 0,
    inspectionTemplateOID: 0,
    camsInspectionLogDetailsVirtual: [
      // {
      //   createRepairRequest: '',
      //   inspectionLogDetailOID: 0,
      //   inspectionStatus: '',
      //   inspectionTaskOID: 0,
      //   remarks: '0',
      //   taskDescription: ''
      // }
    ]
  });
  const [equipments, setEquipments] = useState([]);
  const [InspectionTemplate, setInspectionTemplate] = useState([]);
  const [loadState, setLoadState] = useState(true);

  //   [API] - fetching inspection logs
  useEffect(() => {
    if (rowID) {
      const url = `${baseAPI}/CAMSInspectionLogs/${rowID}`;
      axios.get(url).then((res: AxiosResponse) => {
        const response = res.data.result[0];
        setInitialValues(response);
        setLoadState(false);
      });
    }
  }, [rowID, baseAPI, jwt]);

  useEffect(() => {
    const fetchEquipmentData = async () => {
      fetch(`${baseAPI}/CAMSEquipments`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          const selectedResponse = response.result.data.map((element: any) => {
            const { equipmentOID, description } = element;
            return {
              key: equipmentOID,
              text: description,
              value: equipmentOID
            };
          });
          return selectedResponse;
        })
        .then((data: any) => setEquipments(data));
    };
    fetchEquipmentData();
  }, [baseAPI, jwt]);

  useEffect(() => {
    const fetchInspectionTemplateData = async () => {
      fetch(`${baseAPI}/CAMSInspectionTemplates`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          const selectedResponse = response.result.data.map((element: any) => {
            const { inspectionTemplateOID, templateName } = element;
            return {
              key: inspectionTemplateOID,
              text: templateName,
              value: inspectionTemplateOID
            };
          });
          return selectedResponse;
        })
        .then((data: any) => setInspectionTemplate(data));
    };
    fetchInspectionTemplateData();
  }, [baseAPI, jwt]);

  const handleEditFormSubmit = (inspections: any) => {
    fetch(`${baseAPI}/CAMSInspectionLogs/${rowID}`, {
      method: 'PUT',
      body: JSON.stringify(inspections),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  const handleAddFormSubmit = (inspections: any) => {
    const url = `${baseAPI}/CAMSInspectionLogs`;
    axios
      .post(url, inspections)
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  const handleCancelClick = () => {
    setMode('GRID');
  };

  // const handleDeleteClick = (e: any) => {
  //   e.preventDefault();
  //   fetch(`${baseAPI}/CAMSInspectionLogs/${rowID}`, {
  //     method: 'DELETE',
  //     body: JSON.stringify(initialValues),
  //     headers: {
  //       Authorization: `Bearer${jwt}`,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(handleErrors)
  //     .then(() => setMode('GRID'));
  // };

  const downloadExcelAPI = `${baseAPI}/CAMSReports/DownloadReport?vFullFileName=${fileURL}`;

  return (
    <StyledProvider>
      <Header as="h2">
        <Icon onClick={handleCancelClick} className="cursor-pointer" size="huge" name="arrow circle left" color="blue" />
        Inspection
      </Header>
      <Message color="brown" size="mini">
        <Message.Header>Manage Inspection</Message.Header>
        <p> Create and manage Inspection. </p>
      </Message>
      {loadState ? (
        <Dimmer active inverted>
          <Loader size="medium" inverted>
            Loading
          </Loader>
        </Dimmer>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
        >
          {({ values }) => (
            <FForm className="ui form">
              <Grid fluid columns={2}>
                <Grid.Row>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MySelectInput label="Equipment" placeholder="Equipment" name="equipmentOID" options={equipments} />
                    <MySelectInput label="Inspection Template" placeholder="" name="inspectionTemplateOID" options={InspectionTemplate} />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MyDateInput label="LoDate" placeholder="" name="tDate" dateFormat="MMMM d, yyyy " />
                    <MyTextInput label="Assignee" placeholder="assignee" name="assignee" />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MyTextInput label="Current Hours" placeholder="Current Hours" name="currentHours" />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={1} inline>
                    <MyTextArea label="Notes" rows={4} placeholder="notes" name="notes" />
                  </Form.Group>
                </Grid.Row>

                <Grid.Row fluid columns={1} width="16">
                  <FieldArray name="camsInspectionLogDetailsVirtual">
                    {({ insert, remove, push }) => (
                      <>
                        <Header className="w-full flex justify-between" as="h3" dividing>
                          Inspections Task
                          {rowID && fileURL ? (
                            <span className="mb-2">
                              <Button as="a" download="Operation-Template.xlsx" href={downloadExcelAPI} type="button">
                                Download Inspection
                              </Button>
                              <Button
                                as="a"
                                target="_blank"
                                href={`https://view.officeapps.live.com/op/embed.aspx?src=${downloadExcelAPI}`}
                                type="button"
                              >
                                Preview Inspection
                              </Button>
                              <Button type="button">Upload Inspection</Button>
                            </span>
                          ) : null}
                        </Header>
                        <>
                          <PerformInspectionDetails
                            baseAPI={baseAPI}
                            initialValues={initialValues}
                            setInitialValues={setInitialValues}
                            values={values}
                          />
                        </>
                      </>
                    )}
                  </FieldArray>
                </Grid.Row>
              </Grid>
              <div className="mt-4 flex justify-end w-full">
                <Button type="submit" primary content="Save" />
                <Button content="Cancel" onClick={handleCancelClick} />
              </div>
            </FForm>
          )}
        </Formik>
      )}
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }
  .inspection_title {
    font-size: 17px;
  }

  .ui.grid > .row {
    padding: 1rem !important;
  }
`;

export default PerformInspectionForm;
// [SOLUTION]
// [LINK] - https://codepen.io/nikkipantony/pen/wpPGZp
// [LINK] - https://github.com/Semantic-Org/Semantic-UI-React/issues/798
