import React, { useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Lookup,
  Popup,
  //   Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule

  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';
import { IStateProps } from '../../../types/auth';

import 'whatwg-fetch';
import MeterReadingForm from '../components/MeterReadingForm';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
// ********************FUNCTIONS******************************* */

const MeterReading = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  // console.log("1. baseapi", baseAPI);
  // console.log("1. jwt", jwt);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'meterReadingOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSMeterReadings`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSMeterReadings`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSMeterReadings/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    // console.log(Object.assign(e.oldData, e.newData));
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${baseAPI}/CAMSMeterReadings/${e.newData.meterReadingOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.meterReadingOID);
    setMode('FORM-EDIT');
  };

  // const handleNewInspection = () => {
  //   setRowID(0);
  //   setMode('ADD-NEW');
  // };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Meter Readings </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage Meter Readingx</Message.Header>
                Create and manage Meter Readings.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsMeterReadings"
              dataSource={dataSource && dataSource}
              // className="dx-datagrid dx-row-alt"
              hoverStateEnabled={true}
              columnAutoWidth={true}
              activeStateEnabled={true}
              columnResizingMode="widget"
              allowColumnResizing={true}
              onRowUpdating={onRowUpdating}
              //   onRowUpdating={(e: any) => console.log(e)}
              columnHidingEnabled={true}
              // onToolbarPreparing={handleToolPreparing}
            >
              <RowDragging allowReordering={true} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                <Popup title="Meter Readings" showTitle={true} width={700} height={550}></Popup>
                <Export enabled={true} fileName="Warehouses" allowExportSelectedData={true} />
                {/* <Form>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="repairNameOID" />
                    <Item dataField="repairCode" />
                    <Item dataField="repairName" />
                  </Item>
                </Form> */}
              </Editing>

              <Column type="buttons">
                <Button name="edit" onClick={handleEditClick} icon="edit" />
                <Button name="delete" />
              </Column>
              {/* <Column
                dataField="repairNameOID"
                allowEditing={false}
                // visible={false}
                width={190}></Column> */}
              <Column allowEditing={false} dataField="equipmentOID"></Column>
              <Column dataType="date" dataField="lastUpdated">
                <RequiredRule />
              </Column>
              <Column dataType="date" dataField="recordedOn">
                <RequiredRule />
              </Column>
              <Column dataField="primaryMeter">
                <RequiredRule />
              </Column>
              <Column dataField="secondaryMeter">
                <RequiredRule />
              </Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <>
          <MeterReadingForm rowID={rowID} setMode={setMode} />
        </>
      )}
    </Segment>
  );
};

export default MeterReading;
