export default function concatBySeparator(str: string, separator: string) {
  // let regExp = new RegExp(separator, "g");
  //@ts-ignore
  // const n: number = str.match(regExp).length;
  const stringList = str.split(separator);

  let combinedStr = "";
  let moduleName = "";
  for (let i = 0; i < stringList.length; i += 1) {
    if (i === 0) {
      combinedStr += stringList[i];
    } else {
      combinedStr += stringList[i][0].toUpperCase() + stringList[i].slice(1);
    }
    moduleName += ` ${stringList[i][0].toUpperCase()}${stringList[i].slice(1)}`;
  }
  return [combinedStr, moduleName.trim()];
}
