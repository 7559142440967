import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, Table } from 'semantic-ui-react';
import MyTextInput from './input-controls/MyInlineTextInput';
import axios from 'axios';
import type { AxiosError, AxiosResponse } from 'axios';
import RadioPass from './radio-controls/pass';
import RadioFail from './radio-controls/fail';
import RadioPending from './radio-controls/pending';
import notify from 'devextreme/ui/notify';
import { mapFunction } from '../../../util';

interface IOperationProps {
  values: any;
  setInitialValues: any;
  baseAPI: string;
  initialValues: any;
}

const PMTaskOperation: React.FC<IOperationProps> = ({ values, setInitialValues, baseAPI, initialValues }) => {
  const {
    values: { pmTaskOID, camspmOperationDetailVirtual }
  } = useFormikContext();

  useEffect(() => {
    if (values.camspmOperationDetailVirtual.length > 0) {
      const sortResponse = values.camspmOperationDetailVirtual.sort((a: any, b: any) => a.sortOrder - b.sortOrder);

      console.log(sortResponse);

      setInitialValues((prev: any) => {
        return {
          ...values,
          camspmOperationDetailVirtual: [...sortResponse]
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (pmTaskOID && initialValues['pmTaskOID'] !== pmTaskOID) {
    if (pmTaskOID && !camspmOperationDetailVirtual.length) {
      const url = `${baseAPI}/CAMSPMTasks/${pmTaskOID}`;
      axios
        .get(url)
        .then((res: AxiosResponse) => {
          const response = res.data.result[0];

          return response;
        })

        .then((data) => {
          const sortResponse = data['camspmTaskDetailVirtual'].sort((a: any, b: any) => a.sortOrder - b.sortOrder);

          setInitialValues((prev: any) => {
            return {
              ...values,
              camspmOperationDetailVirtual: [...sortResponse]
            };
          });
        })
        .catch((err: AxiosError) => {
          notify('', 'error', 3000);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmTaskOID]);

  useEffect(() => {
    const target: any[] = values['camspmOperationDetailVirtual'];
    // console.log('Hello I reached this use Effect');

    let value = 'NOT STARTED';
    if (target.length > 0) {
      // console.log('target', target);
      if (target.every((item: any) => item.taskStatus === 'done')) {
        value = 'DONE';
      } else if (target.some((item: any) => item.taskStatus === 'done')) {
        value = 'ONGOING';
      } else {
        value = 'NOT STARTED';
      }
    }

    setInitialValues(() => {
      return {
        ...values,
        overallStatus: value
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const clearAllClick = () => {
    const clearedSubArr = mapFunction(values['camspmOperationDetailVirtual'], (item: any) => {
      return {
        ...item,
        taskStatus: '',
        remarks: ''
      };
    });

    setInitialValues(() => {
      return {
        ...values,
        camspmOperationDetailVirtual: clearedSubArr
      };
    });
  };

  const allDoneClick = () => {
    const finishedSubArr = mapFunction(values['camspmOperationDetailVirtual'], (item: any) => {
      return {
        ...item,
        taskStatus: 'done'
      };
    });

    setInitialValues(() => {
      return {
        ...values,
        camspmOperationDetailVirtual: finishedSubArr
      };
    });
  };

  return (
    <>
      {values.camspmOperationDetailVirtual.length > 0 && (
        <div>
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>SNo</Table.HeaderCell>
                <Table.HeaderCell width={6}>PM Task</Table.HeaderCell>
                <Table.HeaderCell>Done</Table.HeaderCell>
                <Table.HeaderCell>Not Done</Table.HeaderCell>
                <Table.HeaderCell>Pending</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <>
                {values.camspmOperationDetailVirtual.map((item: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell> {item.taskDescription}</Table.Cell>
                    <Table.Cell>
                      <RadioPass
                        checked={item.taskStatus === 'done'}
                        value="done"
                        name={`camspmOperationDetailVirtual.${index}.taskStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RadioFail
                        checked={item.taskStatus === 'notDone'}
                        value="notDone"
                        name={`camspmOperationDetailVirtual.${index}.taskStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <RadioPending
                        checked={item.taskStatus === 'pending'}
                        value="pending"
                        name={`camspmOperationDetailVirtual.${index}.taskStatus`}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyTextInput placeholder="" name={`camspmOperationDetailVirtual.${index}.remarks`} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            </Table.Body>
          </Table>
          <div>
            <Button color="teal" onClick={allDoneClick} type="button">
              All Done
            </Button>
            <Button color="orange" onClick={clearAllClick} type="button">
              Clear All
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PMTaskOperation;
