export const createLookup = (
  valueList: any,
  type?: string,
  origin?: string
) => {
  console.log(valueList, "VALUE LIST");
  const options = valueList.split(",");

  let valueOption: any[] = [];
  let lookupOption: any[] = [];

  options.forEach((opt: any) => {
    const typedOpt = type === "number" ? Number(opt) : opt;
    valueOption.push(typedOpt);
    lookupOption.push({
      title: typedOpt,
      value: typedOpt,
    });
  });

  if (origin && origin.toUpperCase() === "GRID") {
    return lookupOption;
  }

  return lookupOption;
  // return valueOption;
};

export const createLookup2 = (
  data: any,
  // origin: string,
  title: string,
  value: any
) => {
  let lookupOption: any[] = [];
  let selectOptions: any[] = [];
  // console.log(data, title, value);
  // if (origin && origin.toUpperCase() === "GRID") {
  data.forEach((data: any) => selectOptions.push(data[title]));
  console.log("LOOKUP OPtion", selectOptions);
  // console.log("LOOKUP VAL, TITLE", data[value], data[title]);
  // return selectOptions;
  // } else {
  console.log(value, title);
  // await data.forEach((data: any) => console.log(data[title]));

  data.forEach((data: any) =>
    lookupOption.push({
      title: data[title],
      value: data[value],
    })
  );
  console.log("LOOKUP OPtion", lookupOption, "here 4");
  return [lookupOption, selectOptions];
  // }
};
