import React from 'react';
// import "./Branch.css";
//import { baseAPI } from "../../../api/APIEndpoints";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import styled from '@emotion/styled';
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  // SearchPanel,
  // Paging,
  // Editing,
  // Export,
  // Pager,
  // Grouping,
  // GroupPanel,
  Column
} from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import SetupTypeGrid from './SetupTypeGrid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import { IStateProps } from '../../../types/auth';
// import SetupTypeGrid from "./SetupTypeGrid";
// import DealerSideNav from "../../../Layout/SideNav/Layout";
// import BaseUrl from "../../../Baseurl";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

export const EcoSystemCodes = React.memo(() => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseURL = useSelector((state: IStateProps) => state?.config?.baseURL);
  const [setupType, setSetupType] = React.useState('gender');
  const [systemCodeData, setSystemCodeData] = React.useState('');
  // const [setupTypeData, setSetupTypeData] = React.useState("");
  const [dataLoading, setDataLoading] = React.useState(true);

  function onSelectionChanged(selectedRowsData: any) {
    // console.log(selectedRowsData.selectedRowsData[0])
    let value = selectedRowsData.selectedRowsData[0].setupType;
    setSetupType(value);
  }

  React.useEffect(() => {
    let shouldLoad: boolean = false;
    const customDataSource: any = new CustomStore({
      key: 'id',
      load: () => {
        return fetch(
          `${baseURL}/EcoSystemCodes/GetAllDistinctSetupType
      `,
          {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        )
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            //console.log(response.result);
            return {
              data: response.result,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      }
    });

    // const setupTypeDataSource: any = new CustomStore({
    //   key: "oid",
    //   load: () => {
    //     return fetch(
    //       `${baseURL}/EcoSystemCodes/GetEcoSystemCodeBySetupType/${setupType}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${jwt}`,
    //         },
    //       }
    //     )
    //       .then(handleErrors)
    //       .then((response) => response.json())
    //       .then((response) => {
    //         // console.log(response.result.data)
    //         return {
    //           data: response.result.data,
    //           totalCount: response.count,
    //           summary: response.summary,
    //           groupCount: response.count,
    //         };
    //       });
    //   },
    // });

    if (!shouldLoad) {
      setSystemCodeData(customDataSource);
      // setSetupTypeData(setupTypeDataSource);
      setDataLoading(false);
    }

    return () => {
      shouldLoad = true;
    };
  }, [setupType, jwt, baseURL]);

  return (
    <div className={'page-ui'}>
      {/* <DealerSideNav> */}

      <header className={'page-ui-header'}>System Codes</header>
      <GridLayout>
        <div className="systemType">
          <DataGrid
            id="[$GridID]"
            dataSource={!dataLoading ? systemCodeData : {}}
            className="dx-datagrid dx-row-alt"
            hoverStateEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            selection={{ mode: 'single' }}
            // activeStateEnabled={true}
            // keyExpr="oid"
            onSelectionChanged={onSelectionChanged}
            // columnResizingMode="widget"
            // allowColumnResizing={true}
            // columnHidingEnabled={true}
          >
            {/* <RowDragging allowReordering={true} /> */}
            <FilterPanel visible={true} />
            {/* <SearchPanel visible={true} /> */}
            <FilterRow visible={true} />
            {/* <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[2, 10, 20, 50, 100]}
              showInfo={true}
            /> */}
            {/* <Grouping contextMenuEnabled={true} />
            <GroupPanel visible={true} /> */}

            {/* <Export
              enabled={true}
              fileName="FABranchs"
              allowExportSelectedData={true}
            /> */}
            <Column dataField="setupType" allowEditing={false}></Column>
          </DataGrid>
        </div>
        <div className="type-grid">
          {' '}
          <h4>{setSetupType}</h4>
          <SetupTypeGrid
            // initialData={!dataLoading ? setSetupTypeData : {}}
            setupType={setupType}
          />
        </div>
      </GridLayout>
    </div>
  );
});
export default EcoSystemCodes;

const GridLayout = styled.div`
  display: grid !important;
  grid-template-columns: 300px 20px auto !important;
  min-height: 800px !important;
  height: 85vh !important;
  overflow: hidden !important;
  & .systemType {
    grid-column: 1/2 !important;
    height: inherit !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  & .type-grid {
    grid-column: 3/4 !important;
    height: inherit !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  /* .row {
    display: flex;
  } */
`;
