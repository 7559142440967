import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import { FETCH_CAMS_PM_TASKS, FETCH_CAMS_PM_TASKS_FAILED } from '../../actionTypes';
import { IPMTasksAPIProps } from '../../../types';

export const fetchPMTasks = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/CAMSPMTasks`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<IPMTasksAPIProps>) => {
        let result = res.data.result.data;

        dispatch({
          type: FETCH_CAMS_PM_TASKS,
          payload: {
            pmTasks: result
          }
        });
      })
      .catch((err: AxiosError) => {
        // console.log('FETCH_CAMS_PM_TASKS_FAILED', err);
        dispatch({
          type: FETCH_CAMS_PM_TASKS_FAILED,
          payload: {
            pmTasks: err.message
          }
        });
      });
  };
};
