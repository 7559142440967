import { combineReducers } from 'redux';
import authReducer from './reducers/auth';
// import applicationUserReducer from './reducers/cams/applicationUserReducer';
import camsReducers from './reducers/cams';
// import camsLocationsReducer from './reducers/cams/camsLocationsReducer';
// import camsPMOperationReducer from './reducers/cams/camsPMOperationReducer';
// import camsPMTaskReducer from './reducers/cams/camsPMTaskReducer';
// import camsUserProfilesReducer from './reducers/cams/camsUserProfilesReducer';
// import hrEmployeesV2Reducer from './reducers/cams/hrEmployeesV2Reducer';
import configReducers from './reducers/config/configReducer';
import lookupReducer from './reducers/ecosystem/lookupReducer';
import systemCodesReducer from './reducers/ecosystem/systemCodesReducer';
import generalGlobalReducer from './reducers/global/general';

const storeReducers = combineReducers({
  config: configReducers,
  user: authReducer,
  global: generalGlobalReducer,
  systemCodes: systemCodesReducer,
  lookup: lookupReducer,
  // camsUserProfiles: camsUserProfilesReducer,
  // camsLocation: camsLocationsReducer,
  // hrEmployeeV2: hrEmployeesV2Reducer,
  // pmTask: camsPMTaskReducer,
  // pmOperation: camsPMOperationReducer,
  // applicationUser: applicationUserReducer,
  // allowedRoutes: allowedRouteReducer
  cams: camsReducers
});

export default storeReducers;
