// type TcamsData = {
//   ID: number;
//   categoryId: string;
//   name: string;
//   sortOrder: string;
//   report: string;
// };

const camsReportsData = [
  {
    ID: '1',
    name: 'Cams Report',
    expanded: true
  },
  {
    ID: '1_1',
    categoryId: '1',
    name: 'Equipment Report',
    urlCams: 'CAMSReports/EquipmentReport',
    expanded: true
  },

  {
    ID: '1_2',
    categoryId: '1',
    name: 'PM Operations Blank Template',
    urlCams: 'CAMSReports/PMOperationsBlankTemplate?vPMOperationOID=1',
    expanded: true
  },
  {
    ID: '1_3',
    categoryId: '1',
    name: 'Location Report',
    urlCams: 'CAMSReports/LocationReport',
    expanded: true
  },
  {
    ID: '1_4',
    categoryId: '1',
    name: 'Warehouse Report',
    urlCams: 'CAMSReports/WarehouseReport',
    expanded: true
  },
  {
    ID: '1_5',
    categoryId: '1',
    name: 'Users Report',
    urlCams: 'CAMSReports/UserReport',
    expanded: true
  },
  {
    ID: '1_6',
    categoryId: '1',
    name: 'PM Task Report',
    urlCams: 'CAMSReports/PMTaskReport',
    expanded: true
  }
];

// export default camsReportsData;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCamsReportsData() {
    return camsReportsData;
  }
};
