import React, { useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  Form
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';

import 'whatwg-fetch';
// import InspectionTemplateForm from "../components/InspectionTemplateForm(formik)";
import InspectionTemplateForm from '../components/InspectionTemplateForm';
import { IStateProps } from '../../../types/auth';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const InspectionTemplatesGrid = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  // console.log("1. baseapi", baseAPI);
  // console.log("1. jwt", jwt);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'inspectionTemplateOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSInspectionTemplates`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSInspectionTemplates`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSInspectionTemplates/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  // const onRowUpdating = (e: any) => {
  //   // console.log(Object.assign(e.oldData, e.newData));
  //   e.newData = Object.assign(e.oldData, e.newData);
  //   fetch(
  //     `${baseAPI}/CAMSInspectionTemplates/${e.newData.inspectionTemplateOID}`,
  //     {
  //       method: "PUT",
  //       body: JSON.stringify(e.newData),
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  // };

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.inspectionTemplateOID);
    setMode('FORM-EDIT');
  };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Inspection Templates </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage Inspection Templates</Message.Header>
                Create and manage inspection template.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsWarehouse"
              dataSource={dataSource && dataSource}
              // className="dx-datagrid dx-row-alt"
              hoverStateEnabled={true}
              columnAutoWidth={true}
              activeStateEnabled={true}
              columnResizingMode="widget"
              allowColumnResizing={true}
              // onRowUpdating={onRowUpdating}
              columnHidingEnabled={true}
            >
              <RowDragging allowReordering={true} />
              <Export enabled={true} fileName="Inspection Templates" allowExportSelectedData={false} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                <Popup title="Inspection Templates" showTitle={true} width={700} height={550}></Popup>

                <Form>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="inspectionTemplateOID" />
                    <Item dataField="templateName" />
                    <Item dataField="logMeterReading" />
                    <Item dataField="scrollAfterPassing" />
                    <Item dataField="allowNA" />
                    <Item dataField="category" />
                  </Item>
                </Form>
              </Editing>

              <Column type="buttons">
                <Button name="edit" onClick={handleEditClick} icon="edit" />
                <Button name="delete" />
              </Column>
              <Column dataField="inspectionTemplateOID" allowEditing={false} visible={false}></Column>
              <Column dataField="templateName">
                <RequiredRule />
              </Column>
              <Column dataField="logMeterReading">
                <RequiredRule />
              </Column>
              <Column dataField="scrollAfterPassing">
                <RequiredRule />
              </Column>

              <Column dataField="allowNA">{/* <RequiredRule /> */}</Column>
              <Column dataField="category">
                <RequiredRule />
              </Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <InspectionTemplateForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default InspectionTemplatesGrid;
