import React, { useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Lookup,
  // Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule

  // Form,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from "devextreme-react/form";
import { useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';
import { IStateProps } from '../../../types/auth';

import 'whatwg-fetch';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */
const WorkOrderGrid = () => {
  const [dataSource, setDataSource] = useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'workOrderOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSWorkOrders`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSWorkOrders`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSWorkOrders/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/CAMSWorkOrders/${e.newData.CAMSInspectionLogs}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.inspectionLogOID);
    setMode('FORM-EDIT');
  };

  const handleNewInspection = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };
  // const toolbarItemRender = () => {
  //   return (
  //     <>
  //       {/* <Icon name="mail" /> 23 */}
  //       <Button name="add" />
  //       {/* <span className="name">Add new Invoice</span> */}
  //     </Icon>
  //   );
  // };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      // template: "Add New Equipment",
      template: 'Add New Inspections',
      option: {
        icon: 'plus'
      },
      onClick: () => handleNewInspection()
    });
    console.log(e.toolbarOptions);
  };
  return (
    <Segment>
      <div>
        <Header as="h2"> Work Order </Header>
        <Message color="brown">
          <Message.Content>
            <Message.Header> Manage Work Order </Message.Header>
            Create and manage Work Order.
          </Message.Content>
        </Message>
      </div>
      <div>
        <DataGrid
          id="grdCamsLocation"
          dataSource={dataSource && dataSource}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={onToolbarPreparing}
        >
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
          <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}></Editing>
          <Export enabled={true} fileName="Inspection" allowExportSelectedData={true} />
          <Column type="buttons">
            <Button name="edit" onClick={handleEditClick} icon="edit" />
            <Button name="delete" />
          </Column>
          <Column
            dataField="workOrderOID"
            allowEditing={false}
            width={180}
            // visible={false}
          ></Column>
          <Column dataField="workorderNumber">
            <RequiredRule />
          </Column>
          <Column dataField="equipmentOID">
            <RequiredRule />
          </Column>
          <Column dataField="camsUserOID">
            <RequiredRule />
          </Column>
          <Column dataType="date" dataField="scheduledDate">
            <RequiredRule />
          </Column>
          <Column dataType="date" dataField="dueDate">
            <RequiredRule />
          </Column>
          <Column dataField="priorty">
            <RequiredRule />
          </Column>
          <Column dataField="workOrderType">
            <RequiredRule />
          </Column>
          <Column dataField="initialNotes">
            <RequiredRule />
          </Column>
          <Column dataField="tax1">
            <RequiredRule />
          </Column>
          <Column dataField="tax2">
            <RequiredRule />
          </Column>

          <Column dataField="currentHours">
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
    </Segment>
  );
};
export default WorkOrderGrid;
