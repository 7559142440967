import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import { FETCH_CAMS_USER_PROFILES, FETCH_CAMS_USER_PROFILES_FAILED } from '../../actionTypes';
import { ICamsUserProfilesAPIProps } from '../../../types';

export const fetchCamsUserProfiles = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/CAMSUsers`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<ICamsUserProfilesAPIProps>) => {
        let result = res.data.result.data;

        dispatch({
          type: FETCH_CAMS_USER_PROFILES,
          payload: {
            camsUserProfiles: result
          }
        });
      })
      .catch((err: AxiosError) => {
        console.log('FETCH_CAMS_USER_PROFILES_FAILED', err);

        dispatch({
          type: FETCH_CAMS_USER_PROFILES_FAILED,
          payload: {
            camsUserProfiles: err
          }
        });
      });
  };
};
// export const fetchLMSPrograms = () => {
//   return (dispatch: Dispatch) => {
//     const getUserStatusUrl = `${baseURL}/LMSPrograms`;

//     Axios.get(getUserStatusUrl)
//       .then(async (res: AxiosResponse<ICamsUserProfileProps>) => {
//         let result = res.data.result.data;

//         dispatch({
//           type: FETCH_CAMS_USER_PROFILE,
//           payload: {
//             lmsProgram: result
//           }
//         });
//       })
//       .catch((err: AxiosError) => {
//         console.log('LMS_PROGRAMS_FETCH_FAILED', err);

//         dispatch({
//           type: FETCH_CAMS_USER_PROFILE_FAILED,
//           payload: {
//             lmsProgram: err
//           }
//         });
//       });
//   };
// };
