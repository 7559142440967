import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import FullPageLoader from '../elements/general/FullPageLoader';
import FormEngine from '../ecosystem/modules/form-engine/FormEngine';
import ModuleUIController from '../ecosystem/modules/form-engine/ModuleUIController';
import Roles from '../ecosystem/modules/roles/Roles';
import {
  CamsPart,
  EquipmentsGrid,
  InspectionTemplatesGrid,
  LocationGrid,
  MaintenanceTemplatesGrid,
  MeterReading,
  PerformInspectionGrid,
  PMOperationGrid,
  PMTaskGrid,
  RepairNameGrid,
  UserProfileGrid,
  Vendors,
  // WarehouseGrid,
  WorkOrderGrid,
  CamsReport,
  CamsChart
} from '../projects/cams/pages';
import { setHeaders } from '../api';
import UserProfileGridV2 from '../projects/cams/pages/UserProfileGridV2';

// import LocationGrid from '../projects/cams/pages/LocationGrid';

export default function CAMSRoutes() {
  setHeaders();

  return (
    // <Suspense fallback={<FullPageLoader />}>
    <Routes>
      <Route path={`form-engine`} element={<FormEngine />} />
      <Route path={`ecoTask`} element={<ModuleUIController />} />
      <Route path={`role-definition`} element={<Roles />} />
      <Route path={`cams_equipment_management`} element={<EquipmentsGrid />} />
      <Route path={`cams_locations`} element={<LocationGrid />} />
      {/* <Route path={`cams_warehouses`} element={<WarehouseGrid />} /> */}
      <Route path={`cams_inspection_template`} element={<InspectionTemplatesGrid />} />
      <Route path={`cams_maintenance_templates`} element={<MaintenanceTemplatesGrid />} />
      <Route path={`cams_repair_names`} element={<RepairNameGrid />} />
      <Route path={`cams_inspections`} element={<PerformInspectionGrid />} />
      <Route path={`cams_meter_readings`} element={<MeterReading />} />
      <Route path={`cams_parts`} element={<CamsPart />} />
      <Route path={`cams_vendors`} element={<Vendors />} />
      <Route path={`cams_workorder`} element={<WorkOrderGrid />} />
      <Route path={`cams_pmtasks`} element={<PMTaskGrid />} />
      <Route path={`cams_pm_operations`} element={<PMOperationGrid />} />
      <Route path={`cams_user_privileges`} element={<UserProfileGrid />} />
      <Route path={`cams_user_privileges_v2`} element={<UserProfileGridV2 />} />

      <Route path={'cams_report_gallary'} element={<CamsReport />} />
      <Route path={'cams_craft_report'} element={<CamsChart />} />
    </Routes>
    // </Suspense>
  );
}
