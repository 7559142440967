import React, { useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  LoadPanel,
  //   Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule
  // Toolbar,
  // Item
  // Form
  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';
import { IStateProps } from '../../../types/auth';
import 'whatwg-fetch';
import WareHouseForm from '../components/WareHouseForm';
import { fetchCamsLocations } from '../../../store/actions';
//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const WarehouseGrid = () => {
  const [mode, setMode] = React.useState('GRID');
  const [rowID, setRowID] = React.useState(0);
  const [dataSource, setDataSource] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  // const [location, setLocation] = React.useState({
  //   locationOID: 0,
  //   locationName: 'string',
  //   taxRate1: 0,
  //   taxRate2: 0,
  //   labourTax: 0,
  //   category: 'string',
  //   street: 'string',
  //   city: 'string',
  //   state: 'string',
  //   country: 'string',
  //   postCode: 'string'
  // });
  // const [rowData, setRowData]: any = React.useState({});
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsLocations } = useSelector((state: IStateProps) => state?.cams?.camsLocation);
  const dispatch = useDispatch();

  // console.log('1. baseapi', baseAPI);
  // console.log('1. jwt', jwt);
  useEffect(() => {
    dispatch(fetchCamsLocations());
  }, [dispatch]);

  const camsLocationLookup = {
    store: camsLocations
  };

  React.useEffect(() => {
    setLoader(true);
    const customDataSource: any = new CustomStore({
      key: 'wareHouseOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSWarehouses`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            setLoader(false);
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        return fetch(`${baseAPI}/CAMSWarehouses`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      // insert: (values: any) => {
      //   // console.log("Value =" + JSON.stringify(values));
      //   return fetch(`${baseAPI}/CAMSWarehouses`, {
      //     method: "POST",
      //     body: JSON.stringify(values),
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(handleErrors);
      // },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSWarehouses/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    // console.log(Object.assign(e.oldData, e.newData));
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${baseAPI}/CAMSWarehouses/${e.newData.wareHouseOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const handleNewInspection = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  const onToolbarPreparing = (e: any) => {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewInspection;
        break;
      }
    }
  };
  const handleEditClick = (e: any) => {
    setRowID(e.row.data.wareHouseOID);
    setMode('FORM-EDIT');
  };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2"> Warehouse </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage Warehouses</Message.Header>
                CAMS has the ability to add separate Warehouses to a Location to store your part. Each Warehouse is meant to be a separate
                physical location where you store your parts. This works similarly to Equipment locations, in that it's another layer of
                organization.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsWarehouse"
              dataSource={dataSource && dataSource}
              // className="dx-datagrid dx-row-alt"
              hoverStateEnabled={true}
              columnAutoWidth={true}
              activeStateEnabled={true}
              columnResizingMode="widget"
              allowColumnResizing={true}
              onRowUpdating={onRowUpdating}
              //   onRowUpdating={(e: any) => console.log(e)}
              columnHidingEnabled={true}
              onToolbarPreparing={onToolbarPreparing}
            >
              {/* <Toolbar>
                <Item name="addRowButton" />
                <Item name="exportButton" />
                <Item name="searchPanel" options={{ onClick: () => handleNewInspection }} visible={true} />
              </Toolbar> */}
              <RowDragging allowReordering={true} />
              <LoadPanel enabled={loader} />
              <Export enabled={true} fileName="WarehouseGrid" allowExportSelectedData={false} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                <Popup title="Warehouse" showTitle={true} width={700} height={500}></Popup>
              </Editing>

              <Column type="buttons">
                <Button name="edit" icon="edit" onClick={handleEditClick} />
                <Button name="delete" />
              </Column>
              <Column dataField="wareHouseOID" allowEditing={false} visible={false}></Column>
              <Column dataField="wareHouseName">
                <RequiredRule />
              </Column>
              <Column dataField="taxRate1">
                <RequiredRule />
              </Column>
              <Column dataField="taxRate2">
                <RequiredRule />
              </Column>

              <Column allowEditing={true} dataField="locationOID">
                <Lookup dataSource={camsLocationLookup} valueExpr="locationOID" displayExpr="locationName" />
              </Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <WareHouseForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default WarehouseGrid;
