import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storeReducers from './combineReducers';

const persistConfig = {
  key: 'erpStore',
  storage
  //   whitelist: [ 'user', 'ecosystem']
};

const persistedReducer = persistReducer(persistConfig, storeReducers);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store);
export { store, persistor };
