//*************************************************************
// PROJECT NAME : CADERP
// CLASS        : AspNetRoles
// DATE CREATED : 22 April 2021
// TIME CREATED : 08:46:15 AM
// DEVELOPER    : Simeon Kenneth Tobechi (KenChi)
//*************************************************************
import React, { useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
// import { Item } from "devextreme-react/form";
// import { baseAPI } from "../../../api/APIEndpoints";
// import DataGrid, {
//   FilterPanel,
//   FilterRow,
//   SearchPanel,
//   Paging,
//   Lookup,
//   Popup,
//   Position,
//   Editing,
//   Export,
//   Pager,
//   Grouping,
//   GroupPanel,
//   RowDragging,
//   Column,
//   RequiredRule,
//   PatternRule,
//   EmailRule,
//   AsyncRule,
// } from "devextreme-react/data-grid";
// import {
//   ButtonItem,
//   GroupItem,
//   SimpleItem,
//   Label,
//   CompareRule,
//   // EmailRule,
//   // PatternRule,
//   RangeRule,
//   // RequiredRule,
//   StringLengthRule,
//   // AsyncRule,
// } from "devextreme-react/form";
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
import Toolbar, { Item } from 'devextreme-react/toolbar';
// import styled from "@emotion/styled";
import './toolbar.scss';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
// import { baseAPI2 } from "../../api/APIEndpoints";
import { useSelector } from 'react-redux';
import { IStateProps } from '../../types/auth';

interface IToolbar {
  hideBackButton?: any;
  setMode?: any;
  isEditMode?: any;
  setShouldEdit?: any;
  setFormData?: any;
  formData?: any;
  formKey?: any;
  keyField?: any;
  setLoadPanelVisible?: any;
  privilege?: any;
  url?: any;
  deleteUrl?: any;
  putUrl?: any;
  postUrl?: any;
  setEngineDetailsUIOID?: any;
  formRef?: any;
  btnRef?: any;
  requestHandler?: any;
}

const CustomToolbar = ({
  hideBackButton,
  setMode,
  isEditMode,
  setShouldEdit,
  setFormData,
  formData,
  formKey,
  keyField,
  setLoadPanelVisible,
  url,
  privilege,
  setEngineDetailsUIOID,
  // uirequestHandler,
  // getOneUrl,
  // getAllUrl,
  formRef,
  btnRef,
  deleteUrl,
  putUrl,
  postUrl,
  requestHandler
}: IToolbar) => {
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseUrl = useSelector((state: IStateProps) => state.config.baseURL);

  const putUrlAPI = `${baseUrl}/${putUrl.includes(keyField) ? putUrl.replace(`[${keyField.toString().trim()}]`, `${formKey}`) : putUrl}`;

  const makeRequestCall = async (url: string, formData: any, action?: any) => {
    if (formKey) {
      setLoadPanelVisible(true);

      fetch(putUrlAPI, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      })
        .then((res: any) => {
          setLoadPanelVisible(false);
          //FIXME:: check if response status code is not 400 return error
          if (action.toLowerCase() === 'save and continue') {
            notify('saved Successfully!. Enter new record');
            setShouldEdit(true);
            return setFormData({}); //clear form fields}
          }
          if (action.toLowerCase() === 'save and close') {
            setShouldEdit(false);
            notify('Saved Successfully!');
            return setMode('GRID');
          }
          return notify('Saved Successfully!');
        })
        .catch((error) => {
          // console.log(error);
          notify({ message: 'An error occurred!, try again later.' }, 'error', 3000);
        });
    } else {
      fetch(`${baseUrl}/${postUrl}`, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      })
        .then((res: any) => {
          try {
            setLoadPanelVisible(false);
            if (action.toLowerCase() === 'save and continue') {
              notify('saved Successfully! Enter new record');
              setShouldEdit(true);
              return setFormData({}); //clear form fields}
            }
            if (action.toLowerCase() === 'save and close') {
              setShouldEdit(false);
              notify('Saved Successfully!');
              return setMode('GRID');
            }

            res.json().then((res: any) => {
              // console.log(res.result.data[0]);
              //set oID for FORM DETAILS to reference
              if (res.status === 201 && res.result.hasOwnProperty('data')) {
                if (res.result.data[0]) {
                  setEngineDetailsUIOID(res.result.data[0].uioid);
                }
              }
              return notify('Saved Successfully!');
              // if(res.result[0]) setEngineDetailsUIOID(res.result[0].taskOID)
            });
          } catch (error) {
            return notify({ message: 'Error Saving record!' }, 'error', 3000);
          }
        })
        .catch((error) => {
          // console.log(error);
          setLoadPanelVisible(false);
          notify({ message: 'An error occurred!, try again later.' }, 'error', 3000);
        });
    }
  };

  const handleRequest = (url: string, formData: any, action?: any) => {
    // console.log(putUrlAPI, "URL IN");
    if (requestHandler === 'toolbar') {
      makeRequestCall(url, formData, action);
    } else {
      const btn = document.querySelector('.hidden-btn input');
      //@ts-ignore
      btn.click();
      //check if there is a validation message
      const validationSummary = formRef.current.instance._$element[0].children[1].children;
      // console.log(formRef, formRef.current.instance);

      if (validationSummary.length > 0) {
        return false;
      } else {
        makeRequestCall(url, formData, action);
      }
    }
  };

  // `${baseUrl}/${
  //   putUrl.includes(keyField)
  //     ? putUrl.replace(`[${keyField}]`, `${formKey}`)
  //     : putUrl
  // }`;
  const deleteData = (data: any) => {
    setLoadPanelVisible(true);
    // console.log(data, formKey, url);
    if (formKey) {
      fetch(`${baseUrl}/${deleteUrl.includes(keyField) ? deleteUrl.replace(`[${keyField.toString().trim()}]`, `${formKey}`) : deleteUrl}`, {
        method: 'DELETE',
        // body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        notify('Record deleted successfully!');
        setLoadPanelVisible(false);
        setShouldEdit(false);
        setMode('GRID');
      });
    }
  };

  const confirmDeletion = () => {
    let result = confirm('<i>Are you sure you want to delete?</i>', 'Confirm Delete');
    result.then((dialogResult) => {
      deleteData(formData);
    });
  };

  const previousData = { ...formData };

  const backButtonOptions = {
    type: 'back',
    onClick: () => {
      setFormData({});
      setShouldEdit(false);
      setMode('GRID');
      // notify("Back button has been clicked!");
    }
  };

  const refreshButtonOptions = {
    icon: 'refresh',
    onClick: () => {
      // dataGrid.instance.refresh();
      setFormData(previousData);
      // notify("Refresh button has been clicked!");
    }
  };

  const postButtonOptions = {
    icon: 'upload',
    text: 'Post',
    onClick: () => {
      // setFormData({});
      notify({ message: 'POST button has been clicked!' }, 'warning', 3000);
    }
  };

  const editButtonOptions = {
    icon: 'edit',
    text: 'Edit',
    onClick: () => {
      setShouldEdit(true);
      setMode(true);
      // notify("edit option has been clicked!");
    }
  };

  const saveOnlyOptions = {
    text: 'Save',
    icon: 'save',
    onClick: () => {
      handleRequest(baseUrl, formData, 'save');
    }
  };

  const saveButtonOptions = {
    items: [
      {
        text: 'Save',
        icon: 'save',
        items: [
          {
            text: 'Save and Continue',
            action: 'Save and Continue'
            // extension: ".txt",
          },
          {
            text: 'Save and Close',
            action: 'Save and Close'
          },
          {
            text: 'Save',
            action: 'Save'
          }
        ]
      }
    ],

    onItemClick: (e: any) => {
      if (e.itemData.action) {
        switch (e.itemData.text) {
          case 'Save and Continue':
            handleRequest(baseUrl, formData, e.itemData.text);
            // setFormData(previousData); //clear form fields
            break;
          case 'Save and Close':
            handleRequest(baseUrl, formData, e.itemData.text);
            // setMode("GRID");
            break;
          default:
            handleRequest(baseUrl, formData, 'save');
            break;
        }
      }
    }
  };

  const deleteButtonOptions = {
    text: 'Delete',
    icon: 'trash',
    onClick: () => {
      confirmDeletion();
    }
  };

  useEffect(() => {}, []);

  return (
    <Toolbar className="toolbar">
      {!hideBackButton && <Item location="before" widget="dxButton" options={backButtonOptions} />}

      <Item location="before" widget="dxButton" options={refreshButtonOptions} />
      {/* <Item location="after" widget="dxButton" options={addButtonOptions} /> */}
      <Item
        locateInMenu="auto"
        location="after"
        widget="dxButton"
        visible={privilege.canCreate ? true : privilege.canEdit ? true : false}
        options={editButtonOptions}
      />
      <Item
        locateInMenu="auto"
        location="after"
        widget={privilege.canCreate ? 'dxMenu' : 'dxButton'}
        visible={privilege.canCreate ? true : privilege.canEdit ? true : false}
        disabled={!isEditMode}
        options={privilege.canCreate ? saveButtonOptions : saveOnlyOptions}
      />
      <Item
        locateInMenu="auto"
        location="after"
        widget="dxButton"
        visible={privilege.canDelete ? true : false}
        options={deleteButtonOptions}
      />
      <Item locateInMenu="auto" location="after" widget="dxButton" visible={privilege.canPost ? true : false} options={postButtonOptions} />
    </Toolbar>
  );
};

export default CustomToolbar;
