import { Dispatch } from 'redux';
import { LOGOUT_USER, SAVE_USER_DATA } from '../actionTypes';

// type TSetupTypes = 'brand' | 'gender' | 'country' | 'equipmentType' | 'workOrderPriority' | 'equipment';

export const saveUserDataToStore = (payload: any) => {
  //   console.log(payload);
  return (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_USER_DATA,
      payload
    });
  };
};

export const logoutUser = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LOGOUT_USER
    });
  };
};
