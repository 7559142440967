import React, { useEffect, useState } from 'react';

import { Chart, Series } from 'devextreme-react/chart';
import './CamsReport.css';

import { Segment, Card, Grid, Form, Button, Header } from 'semantic-ui-react';
import { Formik, Form as FForm } from 'formik';
import MyDateInput from '../../components/input-controls/MyDateInput';
import axios from 'axios';
import { useAppSelector } from '../../../../store/hook';
import { mapFunction } from '../../../../util';

function groupBy(list: any[], keyGetter: any) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const CamsChart = () => {
  const baseAPI = useAppSelector((state) => state.config.baseURL);
  const [reportData, setReportData] = useState<any[]>([]);
  const [loadState, setLoadState] = useState(false);

  const [initialValues]: any = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    const date = new Date();
    const startDate = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const startDate2 = new Date(date.setDate(startDate)).toISOString();

    const endDate = date.getDate() - date.getDay() + (date.getDay() === 0 ? 0 : 7);
    const endDate2 = new Date(date.setDate(endDate)).toISOString();

    const reportUrl = `${baseAPI}/CAMSReports/GetCraftReport?startDate=${startDate2}&endDate=${endDate2}`;

    axios
      .get(reportUrl)
      .then((res) => {
        const response = res.data.result;
        setReportData(response);
      })
      .then(() => {
        setLoadState(false);
      });
  }, [baseAPI]);

  const fetchReport = (task: any) => {
    const startDate = new Date(task.startDate).toISOString();
    const endDate = new Date(task.endDate).toISOString();
    const reportUrl = `${baseAPI}/CAMSReports/GetCraftReport?startDate=${startDate}&endDate=${endDate}`;
    setLoadState(true);
    axios
      .get(reportUrl)
      .then((res) => {
        const response = res.data.result;
        setReportData(response);
      })
      .then(() => {
        setLoadState(false);
      });
  };

  const mappedData = mapFunction(reportData, (value: any) => {
    const splitFullName = value.user.fullName.split(' ');
    return {
      countPMOperationDone: value.countPMOperationDone,
      countPMOperationNotStarted: value.countPMOperationNotStarted,
      countPMOperationOngoing: value.countPMOperationOngoing,
      pmOperationCount: value.pmOperationCount,
      fullName: splitFullName.slice(1).join(' '),
      rigNo: splitFullName[0],
      id: value.user.id
    };
  });

  const uniqueRig = reportData.reduce((value, key) => {
    const rigNo = key.user.fullName.split(' ');
    if (!value.includes(rigNo[0])) {
      value.push(rigNo[0]);
    }
    return value;
  }, []);

  const groupUser = groupBy(mappedData, (user: any) => user.rigNo);

  // ? this is going to be something useful maybe
  // for (let index = 0; index < uniqueRig.length; index++) {
  //   const element1 = uniqueRig[index];
  //   for (let i = 0; i < reportData.length; i++) {
  //     const element2 = reportData[i];
  //   }
  // }

  // * This might also be useful maybe
  // const groupUser = reportData.filter((value) => {
  //   const uniqueRig = value.user.fullName.split(' ')[0]

  //   if(value.user.fullName.includes(uniqueRig)){
  //     return value
  //   }
  // })

  // console.table(mappedData);

  return (
    <Segment>
      <Header as="h2">Cams Chart </Header>
      <div className="text">
        <div className="form-text">
          <Formik enableReinitialize initialValues={initialValues} onSubmit={async (values) => fetchReport(values)}>
            <FForm className="ui form">
              <Grid columns={2}>
                <Grid.Row>
                  <Form.Group floated="right" widths={2} inline>
                    <MyDateInput label="Start Date" placeholder="" name="startDate" dateFormat="MMMM d, yyyy " />
                    <MyDateInput label="End Date" placeholder="" name="endDate" dateFormat="MMMM d, yyyy " />
                    <Button loading={loadState} style={{ marginTop: '20px' }} type="submit" primary content="View" />
                  </Form.Group>
                </Grid.Row>
              </Grid>
            </FForm>
          </Formik>
        </div>
      </div>
      {reportData.length ? (
        <>
          <Card.Group itemsPerRow={2} style={{ marginBottom: '40px', marginTop: '8px' }} className="card-class">
            {uniqueRig.map((item: string, key: number) => (
              <Card color="brown" key={key + item}>
                <Card.Content style={{ display: 'flex' }}>
                  <div className="custom-margin-class">
                    {groupUser.get(item).map((user: any) => (
                      <div key={user.id}>
                        <Card.Header className="pending">{user.fullName}</Card.Header>
                        <Card.Meta>
                          Task done: {Math.floor(user.pmOperationCount ? (user.countPMOperationDone / user.pmOperationCount) * 100 : 0)}%
                        </Card.Meta>
                      </div>
                    ))}
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>

          <div className="chartbox">
            <Grid style={{ width: '100%' }}>
              <Grid.Row columns={2}>
                {uniqueRig.map((item: string, key: number) => (
                  <Grid.Column>
                    <Chart id="chart" dataSource={groupUser.get(item)}>
                      <h1>Charts UI</h1>
                      <Series valueField="countPMOperationDone" argumentField="fullName" name={item} type="bar" color="#a5673f" />
                    </Chart>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </div>
        </>
      ) : null}
    </Segment>
  );
};

export default CamsChart;
