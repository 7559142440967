import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button as DXButton,
  Paging,
  Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  // GroupPanel,
  RowDragging,
  Column,
  Lookup
  // Sorting
  // LoadPanel
} from 'devextreme-react/data-grid';
// import CustomStore from 'devextreme/data/custom_store';
import { LoadPanel } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Segment,
  Message,
  Header,
  Button,
  Dropdown,
  DropdownMenu,
  Input,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  Confirm
} from 'semantic-ui-react';
import axios, { AxiosError } from 'axios';
import type { AxiosResponse } from 'axios';
import 'whatwg-fetch';
import PMOperationForm from '../components/PMOperationForm';
import { IStateProps } from '../../../types/auth';
import {
  editPMOperations,
  fetchApplicationUsers,
  fetchPMOperations,
  fetchPMOperationsByStatus,
  fetchPMTasks
} from '../../../store/actions';
import { dxGridProps } from '../static';
import notify from 'devextreme/ui/notify';
import Validator, { CustomRule } from 'devextreme-react/validator';
import { pmOperationMonthsOptions, pmOperationStatusOptions, pmOperationYearOptions } from '../static/lookup-options';
import './PMOperationGrid.css';
import { CamsPMOperation } from '../../../store/reducers/cams/camsPMOperationType';
import { deletePMOperations } from '../../../store/actions/cams/camsPMOperationActions';

//********************FUNCTIONS******************************* */
// function handleErrors(response: any) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
//********************FUNCTIONS******************************* */

interface Props {}

const PMOperationGrid = (props: Props) => {
  // const [dataSource, setDataSource] = React.useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  // const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { pmTasks } = useSelector((state: IStateProps) => state?.cams.pmTask);
  const { pmOperations, pmOperationLoaded } = useSelector((state: IStateProps) => state?.cams.pmOperation);
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  const userID = useSelector((state: IStateProps) => state?.user?.profile?.userID);
  const userRoles = useSelector((state: IStateProps) => state?.user?.profile?.userRoles);
  const [fileURL, setFileURL] = useState('');
  const [filterParams, setFilterParams] = useState({
    status: '',
    year: 0,
    month: 0
  });
  // DELETING PM OPERATION
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deletingRowId, setDeletingRowId] = useState(0);

  const dispatch = useDispatch();

  const isAdmin = userRoles.includes('ADMIN');

  // eslint-disable-next-line array-callback-return
  const userFilter = applicationUsers.filter((item) => {
    if (item.id === userID) {
      return item;
    }
  });
  // for  application user
  const applicationUsersLookup = {
    store: isAdmin ? applicationUsers : userFilter
  };

  const pmTasksLookup = {
    store: pmTasks
  };

  useEffect(() => {
    dispatch(fetchPMTasks());
    dispatch(fetchApplicationUsers());

    if (isAdmin) {
      dispatch(fetchPMOperations());
    } else {
      if (userID === undefined || typeof userID !== 'string') return;
      dispatch(fetchPMOperationsByStatus('ALL TASKS', new Date().getFullYear(), new Date().getMonth() + 1, userID));
    }
  }, [dispatch, userID, isAdmin]);

  // const fetchOngoingOperation = () => {
  //   dispatch(fetchPMOperationsByStatus('ONGOING', 2024, userID));
  // };
  // const fetchDoneOperation = () => {
  //   dispatch(fetchPMOperationsByStatus('DONE', 2024, userID));
  // };
  // const fetchNotStatedOperation = () => {
  //   dispatch(fetchPMOperationsByStatus('NOT STARTED', 2024, userID));
  // };
  // const fetchAllOperation = () => {
  //   dispatch(fetchPMOperationsByStatus('ALL TASKS', 2024, userID));
  // };

  const filterSearch = () => {
    dispatch(fetchPMOperationsByStatus(filterParams.status, filterParams.year, filterParams.month, userID));
  };

  // useEffect(() => {
  //   const customDataSource: any = new CustomStore({
  //     key: 'camspmOperationOID',
  //     load: () => {
  //       return fetch(`${baseAPI}/CAMSPMOperations`, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`
  //         }
  //       })
  //         .then(handleErrors)
  //         .then((response) => response.json())
  //         .then((response) => {
  //           return {
  //             data: response.result.data,
  //             totalCount: response.count,
  //             summary: response.summary,
  //             groupCount: response.count
  //           };
  //         });
  //     },
  //     insert: (values) => {
  //       console.log('Value =' + JSON.stringify(values));
  //       return fetch(`${baseAPI}/CAMSPMOperations`, {
  //         method: 'POST',
  //         body: JSON.stringify(values),
  //         headers: {
  //           Authorization: `Bearer ${jwt}`,
  //           'Content-Type': 'application/json'
  //         }
  //       }).then(handleErrors);
  //     },
  //     remove: (key) => {
  //       return fetch(`${baseAPI}/CAMSPMOperations/${key}`, {
  //         method: 'DELETE',
  //         headers: {
  //           Authorization: `Bearer ${jwt}`
  //         }
  //       }).then(handleErrors);
  //     },
  //     update: (values) => {
  //       return values;
  //     }
  //   });

  //   //Save to store
  //   setDataSource(customDataSource);
  // }, [baseAPI, jwt]);

  const handleEditClick = (e: any) => {
    let operationOID = e.row.data.camspmOperationOID;
    setRowID(operationOID);
    const url = `${baseAPI}/CAMSReports/PMOperationsBlankTemplate?vPMOperationOID=${operationOID}`;
    axios
      .get(url)
      .then((res: AxiosResponse) => {
        const response = res.data;
        const filePath = response.reportURL;
        setFileURL(filePath);
        setMode('FORM-EDIT');
        console.log(res.data);
      })
      .catch((err: AxiosError) => {
        const error = err.message;
        notify(error, 'error');
      });
  };

  const downloadReport = (e: any) => {
    let operationOID = e.row.data.camspmOperationOID;
    let OperationDate = new Date(e.row.data.operationDate);

    const downloadUrl = `${baseAPI}/FileManagement/Uploaded_Docs/pmoperation-${operationOID}/pdf`;
    axios
      .get(downloadUrl, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        console.log('URL', url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${OperationDate.toISOString()}.pdf`);
        document.body.appendChild(link);
        link.click();
      })

      .catch((error) => {
        notify(`File not found, Upload a file for the record to download`, 'error', 5_000);
      });
  };

  const handleNewTask = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewTask;
        break;
      }
    }
  }
  // const position = { of: '#grdCamsWarehouse' };
  const validateDate = (e: any) => {
    return e.value % 2 === 0;
  };

  const updateDateFromOffset = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    const pmData: CamsPMOperation = e.newData;

    const date = new Date(pmData.operationDate);
    const getOpDay = date.getDate();

    const newDay = getOpDay + Number(pmData.offsetDay);
    date.setDate(newDay);

    pmData.operationDate = new Date(date).toJSON();

    // console.log('New date:', pmData.operationDate);
    dispatch(editPMOperations(pmData, pmData.camspmOperationOID));
  };

  const handleDelete = () => {
    // const deleteRowId = e.row.data.camspmOperationOID;
    dispatch(deletePMOperations(deletingRowId));
    toggleConfirmDelete();
  };

  const toggleConfirmDelete = () => {
    setShowConfirmDelete((prev) => !prev);
  };

  const handleOpenConfirmDelete = (e: any) => {
    const deleteRowId = e.row.data.camspmOperationOID;
    setDeletingRowId(deleteRowId);
    toggleConfirmDelete();
  };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Preventive Maintenance Operations </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage PM Operations</Message.Header>
                Create and manage PM Operations.
              </Message.Content>
            </Message>
            {/* {!isAdmin ? (
              <>
                <Button onClick={fetchNotStatedOperation}>Not Started</Button>
                <Button onClick={fetchOngoingOperation}>Ongoing</Button>
                <Button onClick={fetchDoneOperation}>Done</Button>
                <Button onClick={fetchAllOperation}>All Tasks </Button>
              </>
            ) : null} */}

            {!isAdmin ? (
              <div
                className=""
                style={{
                  display: 'flex',
                  gap: '1rem',
                  marginTop: '1rem',
                  alignItems: 'end'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                  <label>Status</label>
                  <Dropdown
                    text={filterParams.status ? filterParams.status : 'Filter Status'}
                    icon="filter"
                    floating
                    labeled
                    button
                    className="icon"
                  >
                    <DropdownMenu>
                      <Input icon="search" iconPosition="left" className="search" />
                      <DropdownDivider />
                      <DropdownHeader icon="tags" content="Tag Label" />
                      <DropdownMenu scrolling>
                        {pmOperationStatusOptions.map((option) => (
                          <DropdownItem
                            {...option}
                            onClick={() => {
                              setFilterParams((prev) => ({ ...prev, status: option.value }));
                            }}
                          />
                        ))}
                      </DropdownMenu>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                  <label>Year</label>
                  <Dropdown
                    text={filterParams.year ? String(filterParams.year) : 'Filter Year'}
                    icon="filter"
                    floating
                    labeled
                    button
                    className="icon"
                  >
                    <DropdownMenu>
                      <Input icon="search" iconPosition="left" className="search" />
                      <DropdownDivider />
                      <DropdownHeader icon="tags" content="Tag Label" />
                      <DropdownMenu scrolling>
                        {pmOperationYearOptions.map((option) => (
                          <DropdownItem
                            {...option}
                            onClick={() => {
                              setFilterParams((prev) => ({ ...prev, year: option.value }));
                            }}
                          />
                        ))}
                      </DropdownMenu>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                  <label>Month</label>
                  <Dropdown
                    text={filterParams.month ? pmOperationMonthsOptions.find((p) => p.value === filterParams.month)?.text : 'Filter Month'}
                    icon="filter"
                    floating
                    labeled
                    button
                    className="icon"
                  >
                    <DropdownMenu>
                      <Input icon="search" iconPosition="left" className="search" />
                      <DropdownDivider />
                      <DropdownHeader icon="tags" content="months" />
                      <DropdownMenu scrolling>
                        {pmOperationMonthsOptions.map((option) => (
                          <DropdownItem
                            {...option}
                            onClick={() => {
                              setFilterParams((prev) => ({ ...prev, month: option.value }));
                            }}
                          />
                        ))}
                      </DropdownMenu>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <Button primary onClick={filterSearch}>
                  Filter
                </Button>
              </div>
            ) : null}
          </div>
          <div>
            <DataGrid
              id="grdCamsWarehouse"
              keyExpr="camspmOperationOID"
              dataSource={pmOperations && pmOperations}
              onToolbarPreparing={onToolbarPreparing}
              {...dxGridProps}
              onRowUpdating={updateDateFromOffset}
            >
              <LoadPanel visible={!pmOperationLoaded} showPane={true} showIndicator />
              <RowDragging allowReordering={true} />
              <Export enabled={true} fileName="Preventive maintenance Operations" allowExportSelectedData={false} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Grouping contextMenuEnabled={true} />
              {/* <GroupPanel visible={true} /> */}
              {isAdmin ? (
                <Editing mode="cell" useIcons={true} allowUpdating={true} allowAdding={false} allowDeleting={true} />
              ) : (
                // <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={false} allowAdding={false}>
                //   <Popup title="Inspection Templates" showTitle={true} width={700} height={550}></Popup>
                // </Editing>
                <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={false}>
                  <Popup title="Inspection Templates" showTitle={true} width={700} height={550}></Popup>
                </Editing>
              )}

              {/* {isAdmin ? (
                <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={false} allowAdding={true} />
              ) : (
                <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={false} />
              )} */}

              <Column type="buttons">
                <DXButton name="edits" onClick={handleEditClick} icon="edit" />
                {/* <DXButton name="edit" onClick={handleEditClick} icon="edit" /> */}
                <DXButton name="download" onClick={downloadReport} icon="download" />
                <DXButton name="delete" onClick={handleOpenConfirmDelete} />
              </Column>

              {/* <Column dataField="camspmOperationOID" caption="Operation OID" allowEditing={false}></Column> */}
              {/* <Column dataField="pmTaskOID" caption="pmTaskOID"></Column> */}
              <Column dataField="pmTaskOID" caption="PM Task" allowEditing={false}>
                <Lookup dataSource={pmTasksLookup} displayExpr="pmTaskDescription" valueExpr="pmTaskOID"></Lookup>
              </Column>
              <Column dataField="assignedTo_UserGUID" caption="Assigned To" allowEditing={false}>
                <Lookup dataSource={applicationUsersLookup} displayExpr="fullName" valueExpr="id" />
              </Column>

              <Column dataField="doneBy_UserGUID" caption="Done By" allowEditing={false}>
                <Lookup dataSource={applicationUsersLookup} displayExpr="fullName" valueExpr="id" />
              </Column>

              <Column
                dataType="datetime"
                format="MMMM dd, yyyy h:mm aa"
                dataField="operationDate"
                caption="Operation Date"
                defaultSortOrder="asc"
                allowEditing={false}
              >
                <Validator>
                  <CustomRule validationCallback={validateDate} />
                </Validator>
              </Column>
              {isAdmin ? <Column dataField={'offsetDay'} caption={'OffSet'} dataType={'number'}></Column> : null}
              <Column dataField="overallStatus" caption="Overall Status" allowEditing={false}></Column>
              {/* <Column dataField="loggedByOID" caption="Logged By OID"></Column> */}
            </DataGrid>
          </div>
        </>
      ) : (
        <PMOperationForm fileURL={fileURL} rowID={rowID} setMode={setMode} />
      )}
      {/* This is confirmation for the deleting of pmOperation */}
      <Confirm
        open={showConfirmDelete}
        header="Delete Pm Operation"
        content="Are you sure you want to delete this particular pm"
        onCancel={toggleConfirmDelete}
        onConfirm={handleDelete}
        // style={{}}
      />
    </Segment>
  );
};

export default PMOperationGrid;
