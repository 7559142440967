import React, { useState, useEffect } from 'react';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  Lookup
} from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Loader, Dimmer } from 'semantic-ui-react';
import 'whatwg-fetch';
import UserProfilesFormV2 from '../components/UserProfilesFormV2';
import { IStateProps } from '../../../types/auth';
import { fetchApplicationUsers, fetchCamsLocations } from '../../../store/actions';
import { dxGridProps } from '../static';
import CustomStore from 'devextreme/data/custom_store';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import axios, { AxiosError } from 'axios';
import type { AxiosResponse } from 'axios';
//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
// ********************FUNCTIONS******************************* */

interface Props {}

const UserProfileGridV2 = (props: Props) => {
  const [dataSource, setDataSource] = React.useState([]);
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const [fileURL, setFileURL] = useState('');
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsUserProfilesLoaded } = useSelector((state: IStateProps) => state?.cams?.camsUserProfiles);
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  const dispatch = useDispatch();

  const applicationUser = {
    store: applicationUsers
  };

  useEffect(() => {
    // dispatch(fetchCamsUserProfiles());
    dispatch(fetchCamsLocations());
    dispatch(fetchApplicationUsers());
  }, [dispatch]);

  useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'camsEmployeeOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSUsers`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      remove: (key) => {
        return fetch(`${baseAPI}/camsEmployeeOID/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEditClick = (e: any) => {
    let employeeOID = e.row.data.camsEmployeeOID;
    let userGUID = e.row.data.userGUID;
    setRowID(employeeOID);
    // const url = `${baseAPI}/CAMSReports/UserPMOperationReport?vUserGUID=${userGUID}`;
    const url = `${baseAPI}/CAMSReports/UserPMOperationReport?vUserGUID=${userGUID}`;
    axios
      .get(url)
      .then((res: AxiosResponse) => {
        const response = res.data;
        const filePath = response.reportURL;
        setFileURL(filePath);
        setMode('FORM-EDIT');
      })
      .catch((err: AxiosError) => {
        const error = err.message;
        notify(error, 'error');
      });
  };

  const handleNewClick = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewClick;
        break;
      }
    }
  }

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Users Profile </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage user profile </Message.Header>
                Create and manage user profile.
              </Message.Content>
            </Message>
          </div>
          {!camsUserProfilesLoaded ? (
            <Dimmer active inverted>
              <Loader size="medium" inverted>
                Loading
              </Loader>
            </Dimmer>
          ) : (
            <div>
              <DataGrid
                // keyExpr="camsEmployeeOID"
                dataSource={dataSource && dataSource}
                onToolbarPreparing={onToolbarPreparing}
                {...dxGridProps}
              >
                <RowDragging allowReordering={true} />
                <Export enabled={true} fileName="UserProfile" allowExportSelectedData={false} />
                <FilterPanel visible={true} />
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
                <Grouping contextMenuEnabled={true} />
                <GroupPanel visible={true} />
                <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}></Editing>

                <Column type="buttons">
                  <Button name="edit" onClick={handleEditClick} icon="edit" />
                  <Button name="delete" />
                </Column>
                {/* <Column dataField="camsEmployeeOID" /> */}
                <Column
                  dataField="userGUID"
                  caption="User Name"
                  allowEditing={false}
                  // visible={false}
                >
                  <Lookup dataSource={applicationUser} displayExpr="fullName" valueExpr="id" />
                </Column>
              </DataGrid>
            </div>
          )}
        </>
      ) : (
        <UserProfilesFormV2 rowID={rowID} setMode={setMode} fileURL={fileURL} />
      )}
    </Segment>
  );
};

export default UserProfileGridV2;
