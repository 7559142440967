/**
 * this is the ideal props  every dev-express data-grid should have
 */
export const dxGridProps: any = {
  wordWrapEnabled: true,
  hoverStateEnabled: true,
  columnAutoWidth: true,
  activeStateEnabled: true,
  columnResizingMode: 'widget',
  allowColumnResizing: true,
  showBorders: true,
  columnHidingEnabled: true,
  rowAlternationEnabled: true
};
