import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Message,
  Form,
  Header,
  Icon
  // Container,
  // Label,
} from 'semantic-ui-react';
import { Formik, Form as FForm } from 'formik';
import MyTextInput from './input-controls/MyTextInput';
// import MySelectInput from "./input-controls/MySelectInput";
// import MyTextArea from "./input-controls/MyTextArea";
// import MyDateInput from "./input-controls/MyDateInput";
import styled from '@emotion/styled';
import MyDateInput from './input-controls/MyDateInput';
import { IStateProps } from '../../../types/auth';

// import { inspectionTemplatetasks } from "../assets/tempJson";

function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

interface IPIGridProps {
  rowID?: number;
  setMode: any;
}

const MeterReadingForm: React.FC<IPIGridProps> = ({ rowID, setMode }) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const [meterReadings, setMeterReadings] = useState({
    meterReadingOID: 0,
    equipmentOID: 0,
    lastUpdated: '',
    primaryMeter: 0,
    secondaryMeter: 0,
    recordedOn: ''
  });

  //   [API] - fetching inspection logs
  useEffect(() => {
    if (rowID) {
      const fetchRowData = async () => {
        fetch(`${baseAPI}/CAMSMeterReadings/${rowID}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((data: any) => setMeterReadings(data.result[0]));
      };
      fetchRowData();
    }
  }, [rowID, baseAPI, jwt]);

  const handleEditFormSubmit = (meterReadings: any) => {
    // e.preventDefault();
    console.log('1. submit function', meterReadings);
    fetch(`${baseAPI}/CAMSMeterReadings/${rowID}`, {
      method: 'PUT',
      body: JSON.stringify(meterReadings),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(handleErrors);
    setMode('GRID');
  };

  const handleAddFormSubmit = (meterReadings: any) => {
    // e.preventDefault();
    console.log('2. submit function', meterReadings);
    fetch(`${baseAPI}/CAMSMeterReadings`, {
      method: 'POST',
      body: JSON.stringify(meterReadings),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(handleErrors);
    setMode('GRID');
  };
  const handleCancelClick = () => {
    setMode('GRID');
  };
  const handleDeleteClick = () => {
    fetch(`${baseAPI}/CAMSMeterReadings/${rowID}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  // const handleAddDetailsClick = (event: any) => {
  //   const temp = { ...inspectionsTemplate };
  //   temp.camsInspectionTemplateTasksVirtual.push({
  //     taskDescription: "",
  //     camsInspectionTemplateTasksOID: 0,
  //   });
  //   setInspectionsTemplate(temp);
  // };

  // const handleDeleteDetailClick = (event: any, i: any) => {
  //   event.preventDefault();
  //   const temp = { ...inspectionsTemplate };
  //   temp.camsInspectionTemplateTasksVirtual.splice(i, 1);
  //   setInspectionsTemplate(temp);
  // };

  // const handleFormHeaderChange = (event: any) => {
  //   event.preventDefault();
  //   const fieldName = event.target.getAttribute("name");
  //   const fieldValue: any = event.target.value;
  //   const newFormData: any = { ...inspectionsTemplate };
  //   newFormData[fieldName] = fieldValue;
  //   setInspectionsTemplate(newFormData);
  // };

  // const handleFormDetailsChange = (event: any, i: any) => {
  //   const temp: any = { ...inspectionsTemplate };
  //   const fieldName = event.target.name;
  //   const fieldValue: any = event.target.value;
  //   // console.log({ index: i, fieldName: fieldName, fieldValue: fieldValue });
  //   temp.camsInspectionTemplateTasksVirtual[i][fieldName] = fieldValue;
  //   setInspectionsTemplate(temp);
  // };

  // console.log("1 .InspectionTemplate", meterReadings);

  return (
    <StyledProvider>
      <>
        <Header>
          <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
          Meter Reading
        </Header>
        <Message color="brown" size="mini">
          <Message.Header>Meter Readings</Message.Header>
          <p>Create and manage meter readings</p>
        </Message>
        <Formik
          initialValues={meterReadings}
          onSubmit={async (values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
          enableReinitialize
          // onSubmit={(values: any) => console.log(values)}
        >
          {({ values, handleSubmit, isValid, isSubmitting, dirty }) => (
            <FForm className="ui form" onSubmit={handleSubmit} autoComplete="off">
              <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
              <Form.Group widths="equal">
                <MyTextInput disabled label="Meter Reading Template OID" placeholder="Meter Reading OID" name="meterReadingOID" />
                <MyTextInput name="equipmentOID" placeholder="Equipment" label="Equipment" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyDateInput
                  // label="last Updated"
                  // placeholder="lastUpdated"
                  name="lastUpdated"
                  placeholderText="Last Updated"
                  // name="date"
                  showTimeSelect
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <MyTextInput label="primary Meter" placeholder="primaryMeter" name="primaryMeter" />
              </Form.Group>
              <Form.Group widths="equal">
                <MyDateInput
                  // label="recorded On"
                  placeholderText="Recorded On"
                  name="recordedOn"
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <MyTextInput label="secondary Meter" placeholder="secondary Meter" name="secondaryMeter" />
              </Form.Group>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  lineHeight: '25px',
                  justifyContent: 'flex-end'
                }}
              >
                <Button content="Cancel" onClick={handleCancelClick} />
                <Button type="submit" primary content="Save" loading={isSubmitting} />
                <Button negative content="Delete" onClick={handleDeleteClick} />
              </div>
            </FForm>
          )}
        </Formik>
      </>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .field {
    width: 40%;
  }

  .it__details_form {
    align-items: center;
    // width: 60%;
    display: flex;
    column-gap: 5px;
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }
`;

export default MeterReadingForm;
// [SOLUTION]
// [LINK] - https://dev.to/hussainsafwan/handling-nested-inputs-in-react-1gj3
