import { useField } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';

interface Props {
  placeholder: string;
  name: string;
  rows: number;
  label?: string;
  id?: string;
}

const MyTextArea = (props: Props) => {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field className="w-full" error={meta.touched && !!meta.error}>
      <label htmlFor={props.id}>{props.label}</label>
      <textarea id={props.id} {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};
export default MyTextArea;
