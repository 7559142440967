// AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';

// action - MENU
export const SET_MENU = 'SET_MENU';

// GLOBAL
export const SET_SERVICE_BASE_URL = 'SET_SERVICE_BASE_URL';

// ECOSYSTEM
export const FETCH_SYSTEM_CODES = 'FETCH_SYSTEM_CODES';
export const LOOKUP_ADDED = 'LOOKUP_ADDED';

// PM TASK
export const FETCH_PM_TASKS: string = 'FETCH_PM_TASKS';
export const FETCH_PM_TASKS_FAILED: string = 'FETCH_PM_TASKS_FAILED';

export const FETCH_CAMS_USER_PROFILES = 'FETCH_CAMS_USER_PROFILES';
export const FETCH_CAMS_USER_PROFILES_FAILED = 'FETCH_CAMS_USER_PROFILES_FAILED';

export const FETCH_CAMS_LOCATION = 'FETCH_CAMS_LOCATION';
export const FETCH_CAMS_LOCATION_FAILED = 'FETCH_CAMS_LOCATION_FAILED';

export const FETCH_CAMS_HR_EMPLOYEE_V2S = 'FETCH_CAMS_HR_EMPLOYEE_V2S';
export const FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED = 'FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED';

export const FETCH_CAMS_PM_TASKS = 'FETCH_CAMS_PM_TASKS';
export const FETCH_CAMS_PM_TASKS_FAILED = 'FETCH_CAMS_PM_TASKS_FAILED';

export const FETCH_CAMS_PM_OPERATIONS_ALL = 'FETCH_CAMS_PM_OPERATIONS_ALL';
export const FETCH_CAMS_PM_OPERATIONS_BY_STATUS = 'FETCH_CAMS_PM_OPERATIONS_BY_STATUS';
export const FETCH_CAMS_PM_OPERATIONS_ALL_FAILED = 'FETCH_CAMS_PM_OPERATIONS_ALL_FAILED';
export const EDIT_CAMS_PM_OPERATION = 'EDIT_CAMS_PM_OPERATION';
export const EDIT_CAMS_PM_OPERATION_V2 = 'EDIT_CAMS_PM_OPERATION_V2';

export const CREATE_CAMS_PM_OPERATION = 'CREATE_CAMS_PM_OPERATION';
export const DELETE_CAMS_PM_OPERATION = 'DELETE_CAMS_PM_OPERATION';

export const FETCH_APPLICATION_USER = 'FETCH_APPLICATION_USER';
export const FETCH_APPLICATION_USER_FAILED = 'FETCH_APPLICATION_USER_FAILED';
