//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : form UI
// SIGNIFICANCE: The Form UI generator logic
// DATE CREATED : 20 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************
import * as React from 'react';
import { useState, useEffect } from 'react';

// import axios from "axios";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';

// import Form, { FormItem } from "devextreme-react/form";

import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  ButtonItem
  // TabbedItem,
  // TabPanelOptions,
  // Tab,
  // EmptyItem,
} from 'devextreme-react/form';
// import Toolbar, { Item } from "devextreme-react/toolbar";
// import styled from "@emotion/styled";
import '../grid.scss';
// import notify from "devextreme/ui/notify";

import { useSelector } from 'react-redux';
// import CustomStore from "devextreme/data/custom_store";
// import "whatwg-fetch";
// import { baseAPI2 } from "../../../api/APIEndpoints";
// import DynamicForm from "../DynamicForm";
import CustomToolbar from '../CustomToolbar';
import { createLookup } from '../../../util/createLookup';
import { IStateProps } from '../../../types/auth';
// import { IFormUI, IGridConfig } from "./types";
// import { formUIConfig, uiData, mockData } from "./data";
// import CustomStore from "devextreme/data/custom_store";
// import DataSource from "devextreme/data/data_source";
// import { createLookup } from "../../../utils/createLookup";
// import fetchLookup from "../../../utils/fetchLookup";
// import SelectBox from "devextreme-react/select-box";
// import CustomSimpleItem from "./CustomSimpleItem";

// import { trigger } from "devextreme/events";

//********************FUNCTIONS******************************* */
// function handleErrors(response: any) {
//   if (!response.ok) {
//     console.log(response);
//     if (response.status === 401) {
//       throw Error(
//         "Your Login session has expired. Please logout and sign in again."
//       );
//     }
//     throw Error(response.statusText);
//   }
//   return response;
// }
//********************FUNCTIONS******************************* */

const buttonOptions = {
  text: 'Save',
  type: 'success',
  useSubmitBehavior: true
};

const FormUI = ({
  formData,
  gridData,
  columnFieldData,
  rowData,
  setRowData,
  setMode,
  mode,
  uiType,
  setLoadPanelVisible,
  getOneUrl,
  getAllUrl,
  deleteUrl,
  putUrl,
  postUrl
}: any) => {
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const url = `${baseAPI}/${formData.endPoint_GetAllFormData}`;
  const jwt = useSelector((state: IStateProps) => state?.user?.token);
  const lookUps = useSelector((state: IStateProps) => state.lookup);

  const formRef: any = React.useRef();
  const hiddenBtnRef: any = React.useRef();

  type TIndex = {
    [key: string]: any;
  };

  const privilege = {
    canEdit: 1,
    canCreate: 1,
    canDelete: 1,
    canPost: 1,
    canExport: 1
  };

  const colCountByScreen: TIndex = {
    sm: 1,
    md: 2,
    lg: 4
  };

  const formColSpan: TIndex = {
    sm: 4,
    md: 2,
    lg: 1
  };

  //   const [mode, setMode] = React.useState("GRID");
  // const [rowID, setRowID]: any = React.useState(null);
  //   const [rowData, setRowData]: any = React.useState({});
  const [, setShouldEdit] = React.useState(false);

  const [dataSource, setDataSource] = React.useState({});

  // const [currentFormEntry, setCurrentFormEntry] = React.useState({});
  // const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [, setEngineDetailsUIOID]: any = useState(undefined);

  const allData = [...columnFieldData];
  let sortedData: any[] = [];
  let sortedFormDetails: any[] = [];

  //sort data
  allData.sort((a: any, b: any) => {
    if (a.grouping_GroupOrder < b.grouping_GroupOrder) {
      return -1;
    }
    if (a.grouping_GroupOrder > b.grouping_GroupOrder) {
      return 1;
    }
    // order are equal
    return 0;
  });

  // console.log("SORTED DATA", allData);

  //groupData
  allData.forEach((data: any, index: number) => {
    sortedData.push(data.grouping_GroupCode || 'OTHERS');
    allData[index].grouping_GroupCode = allData[index].grouping_GroupCode || 'OTHERS';
  });

  //remove duplicate groupNames
  let groupCodeNames = Array.from(new Set(sortedData));
  // console.log(groupCodeNames, allData);

  //get form Elements for each groups
  groupCodeNames.forEach((groupName: string) => {
    let groupDetails = allData.filter((data: any) => data.grouping_GroupCode === groupName);
    groupDetails.sort((a: any, b: any) => {
      if (a.uI_SortOrder < b.uI_SortOrder) {
        return -1;
      }
      if (a.uI_SortOrder > b.uI_SortOrder) {
        return 1;
      }
      // order are equal
      return 0;
    });
    sortedFormDetails.push(groupDetails);
  });

  // console.log(rowData, dataSource);

  const [colCount, setColCount] = useState(3);
  const [colSpan, setColSpan] = useState(1);

  function screenByWidth(width: any) {
    const screen: any = width > 1140 ? 'lg' : width > 720 ? 'md' : 'sm';

    let col = colCountByScreen[screen];
    let span = formColSpan[screen];

    setColCount(col);
    setColSpan(span);
    return screen;
  }

  useEffect(() => {
    if (!uiType.includes('LISTVIEW')) {
      const fetchData = async (url: string) => {
        let fetchStatus = false;
        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });

        if (res.status === 200) {
          const data = await res.json();
          return [data, fetchStatus];
        } else {
          fetchStatus = true;
          return ['Failed to fetch, try again', fetchStatus];
        }
      };

      fetchData(url).then((res) => {
        // console.log(res[0].result);
        setDataSource(res[0].result);
        setLoadPanelVisible(false);
      });
    }
  }, [jwt, uiType, setLoadPanelVisible, url]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <CustomToolbar
        // getOneUrl={getOneUrl}
        // getAllUrl={getAllUrl}
        formRef={formRef}
        btnRef={hiddenBtnRef}
        deleteUrl={deleteUrl}
        putUrl={putUrl}
        postUrl={postUrl}
        setMode={setMode}
        isEditMode={mode}
        setShouldEdit={setShouldEdit}
        setFormData={setRowData}
        formData={rowData}
        formKey={rowData && rowData[gridData.entity_KeyFieldName]}
        keyField={gridData.entity_KeyFieldName}
        setLoadPanelVisible={setLoadPanelVisible}
        // url={`${url}`}
        url={`${url}${rowData[gridData.entity_KeyFieldName] ? '/' + rowData[gridData.entity_KeyFieldName] : ''}`}
        privilege={privilege}
        setEngineDetailsUIOID={setEngineDetailsUIOID}
        // uiType={uiType}
      />
      <form onSubmit={handleSubmit}>
        <Form
          ref={formRef}
          colCountByScreen={colCountByScreen}
          id="form"
          screenByWidth={screenByWidth}
          minColWidth={233}
          colCount={colCount}
          showValidationSummary={true}
          validationGroup="companyData"
          // labelLocation={sortedFormDetails[0][0].uI_ControlCaptionPosition}
          formData={rowData || dataSource}
        >
          {/* LOGIC  FLOW */}
          {sortedFormDetails.map((groupedUI: any, index: any) => (
            <GroupItem
              key={`${groupedUI[0].grouping_GroupCode}`}
              cssClass={'group-caption'}
              caption={groupedUI[0].grouping_GroupCode.toUpperCase()}
              colCount={colCount}
              colSpan={4}
            >
              {groupedUI.map((formElem: any, index: any) => (
                <SimpleItem
                  key={`${formElem.entity_FieldName}+${index}`}
                  dataField={formElem.entity_FieldName}
                  isRequired={formElem.entity_Required}
                  colSpan={colSpan}
                  editorType={formElem.uI_ControlType}
                  label={formElem.uI_ControlCaption}
                  editorOptions={{
                    readOnly: formElem.uI_Readonly,
                    disabled: formElem.uI_disabled,
                    items: formElem.lookUp_ComboList
                      ? createLookup(formElem.lookUp_ComboList, formElem.entity_DataType, 'FORM')
                      : lookUps[formElem.entity_FieldName]
                      ? lookUps[formElem.entity_FieldName].data.form
                      : [],
                    value: rowData[formElem.entity_FieldName],
                    defaultValue: rowData[formElem.entity_FieldName],
                    required: formElem.entity_Required,
                    displayExpr: 'title',
                    valueExpr: 'value'
                  }}
                >
                  {formElem.entity_Required && <RequiredRule message={`${formElem.entity_FieldName} is Required`} />}
                </SimpleItem>
                // <CustomSimpleItem
                //   formElem={formElem}
                //   rowData={rowData}
                //   formData={formData}
                // />
              ))}
            </GroupItem>
          ))}
          {/* <GroupItem caption="Contact Information">
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title="Phone">
                <SimpleItem dataField="Phone" />
              </Tab>            
            </TabbedItem>
          </GroupItem> */}
          <ButtonItem cssClass={'hidden-btn'} ref={hiddenBtnRef} horizontalAlignment="left" buttonOptions={buttonOptions} />
        </Form>
      </form>
    </>
  );
};

export default FormUI;
