import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Message,
  Form,
  Header,
  Icon

  // Container,
  // Label,
} from 'semantic-ui-react';
// import { Formik, Form as FForm } from "formik";
// import MyTextInput from "./input-controls/MyTextInput";
// import MySelectInput from "./input-controls/MySelectInput";
// import MyTextArea from "./input-controls/MyTextArea";
// import MyDateInput from "./input-controls/MyDateInput";
import styled from '@emotion/styled';
import { IStateProps } from '../../../types/auth';

// import { inspectionTemplatetasks } from "../assets/tempJson";

function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

interface IPIGridProps {
  rowID?: number;
  setMode: any;
}

const InspectionTemplateForm: React.FC<IPIGridProps> = ({ rowID, setMode }) => {
  const [open, setOpen] = useState(false);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const [inspectionsTemplate, setInspectionsTemplate] = useState({
    allowNA: '',
    category: '',
    inspectionTemplateOID: 1,
    logMeterReading: '',
    scrollAfterPassing: '',
    templateName: '',
    camsInspectionTemplateTasksVirtual: [
      {
        camsInspectionTemplateTasksOID: 0,
        taskDescription: ''
      }
    ]
  });

  //   [API] - fetching inspection logs
  useEffect(() => {
    if (rowID) {
      const fetchRowData = async () => {
        fetch(`${baseAPI}/CAMSInspectionTemplates/${rowID}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((data: any) => setInspectionsTemplate(data.result[0]));
      };
      fetchRowData();
    }
  }, [rowID, baseAPI, jwt]);

  const handleEditFormSubmit = (e: any) => {
    e.preventDefault();
    console.log('2. submit function', inspectionsTemplate);
    fetch(`${baseAPI}/CAMSInspectionTemplates/${rowID}`, {
      method: 'PUT',
      body: JSON.stringify(inspectionsTemplate),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleDeleteClick = (e: any) => {
    e.preventDefault();
    fetch(`${baseAPI}/CAMSInspectionTemplates/${rowID}`, {
      method: 'DELETE',
      body: JSON.stringify(inspectionsTemplate),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  const handleAddDetailsClick = (event: any) => {
    const temp = { ...inspectionsTemplate };
    temp.camsInspectionTemplateTasksVirtual.push({
      taskDescription: '',
      camsInspectionTemplateTasksOID: 0
    });
    setInspectionsTemplate(temp);
  };

  const handleDeleteDetailClick = (event: any, i: any) => {
    event.preventDefault();
    const temp = { ...inspectionsTemplate };
    temp.camsInspectionTemplateTasksVirtual.splice(i, 1);
    setInspectionsTemplate(temp);
  };

  const handleFormHeaderChange = (event: any) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue: any = event.target.value;
    const newFormData: any = { ...inspectionsTemplate };
    newFormData[fieldName] = fieldValue;
    setInspectionsTemplate(newFormData);
  };

  const handleFormDetailsChange = (event: any, i: any) => {
    const temp: any = { ...inspectionsTemplate };
    const fieldName = event.target.name;
    const fieldValue: any = event.target.value;
    // console.log({ index: i, fieldName: fieldName, fieldValue: fieldValue });
    temp.camsInspectionTemplateTasksVirtual[i][fieldName] = fieldValue;
    setInspectionsTemplate(temp);
  };

  // console.log("InspectionTemplate", inspectionsTemplate);
  // const show = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  return (
    <StyledProvider>
      <>
        <Header>
          <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
          Inspection Template
        </Header>
        <Message color="brown" size="mini">
          <Message.Header>Inspection Templates</Message.Header>
          <p>Create and manage Inspection Template</p>
        </Message>

        <Form className="ui form" onSubmit={handleEditFormSubmit} autoComplete="off">
          <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
          <Form.Group widths="equal">
            <Form.Input
              disabled
              label="Inspection Template OID"
              placeholder="Inspection Template OID"
              name="inspectionTemplateOID"
              value={inspectionsTemplate.inspectionTemplateOID}
              onChange={handleFormHeaderChange}
            />
            <Form.Input
              name="category"
              placeholder="category"
              label="Category"
              value={inspectionsTemplate.category}
              onChange={handleFormHeaderChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Template Name"
              placeholder="Template Name"
              name="templateName"
              value={inspectionsTemplate.templateName}
              onChange={handleFormHeaderChange}
            />
            <Form.Input
              label="Log Meter Reading"
              placeholder="log meter reading"
              name="logMeterReading"
              value={inspectionsTemplate.logMeterReading}
              onChange={handleFormHeaderChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Scroll After Passing"
              placeholder="scroll after passing"
              name="scrollAfterPassing"
              value={inspectionsTemplate.scrollAfterPassing}
              onChange={handleFormHeaderChange}
            />
            <Form.Input
              label="Allow NA"
              placeholder="allow NA"
              name="allowNA"
              value={inspectionsTemplate.allowNA}
              onChange={handleFormHeaderChange}
            />
          </Form.Group>
          <Header size="medium" color="brown" content="Inspection Task" dividing />
          <div className="it__details_add" onClick={handleAddDetailsClick}>
            <Icon name="plus" /> Add Item
          </div>
          <div className="it__details_forms">
            {inspectionsTemplate.camsInspectionTemplateTasksVirtual.map((task, index) => (
              <div className="it__details_form" key={index}>
                <span className="it__number">{index + 1}. </span>
                <Form.Input
                  type="text"
                  placeholder="please enter a task"
                  name="taskDescription"
                  value={task.taskDescription}
                  onChange={(event) => handleFormDetailsChange(event, index)}
                  action={{
                    color: 'red',
                    labelPosition: 'right',
                    icon: 'cut',
                    content: 'Del',
                    onClick: (event: any) => handleDeleteDetailClick(event, index)
                  }}
                />
              </div>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              lineHeight: '25px',
              justifyContent: 'flex-end'
            }}
          >
            <Button content="Cancel" onClick={handleCancelClick} />

            <Button type="submit" primary content="Save" />
            <div>
              <Button negative content="Delete" onClick={handleDeleteClick} />
              {/* <Confirm
                open={open}
                content="Are you sure you wish to delete this"
                confirmButton="Yes"
                cancelButton="No"
                onConfirm={() => handleDeleteClick(rowID)}
                // onCancel={handleClose}
                size="tiny"
                style={{ width: "250px" }}
              /> */}
            </div>
          </div>
        </Form>
      </>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .field {
    width: 40%;
  }

  .it__details_form {
    align-items: center;
    // width: 60%;
    display: flex;
    column-gap: 5px;
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }
`;

export default InspectionTemplateForm;
// [SOLUTION]
// [LINK] - https://dev.to/hussainsafwan/handling-nested-inputs-in-react-1gj3
