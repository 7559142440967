import { FETCH_CAMS_PM_TASKS, FETCH_CAMS_PM_TASKS_FAILED } from '../../actionTypes'; // import {  ILMSUser } from "../../../types/types";

const InitialState = {
  pmTaskLoaded: false,
  pmTasks: [],
  pmTask: {}
};

const camsPMTaskReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMS_PM_TASKS:
      return {
        ...state,
        pmTaskLoaded: true,
        pmTasks: [...action.payload.pmTasks]
      };

    case FETCH_CAMS_PM_TASKS_FAILED:
      return {
        ...state,
        pmTaskLoaded: true,
        pmTasks: [...action.payload.pmTasks]
      };

    default:
      return state;
  }
};

export default camsPMTaskReducer;
