import React from "react";
import { useField } from "formik";
import "./radio.css";

interface IProps {
  name: string;
  label?: string | undefined;
  value: string;
  checked: boolean;
}

const RadioPass: React.FC<IProps> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <label htmlFor={`pass${props.name}`}>
      <input
        type="radio"
        // name={props.name}
        className="pass"
        id={`pass${props.name}`}
        // value={props.value}
        {...field}
        {...props}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30"
        height="30"
        viewBox="0 0 30 30">
        <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21.707,12.707l-7.56,7.56 c-0.188,0.188-0.442,0.293-0.707,0.293s-0.52-0.105-0.707-0.293l-3.453-3.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l2.746,2.746l6.853-6.853c0.391-0.391,1.023-0.391,1.414,0S22.098,12.316,21.707,12.707z"></path>
      </svg>
      <span>{props.label}</span>
    </label>
  );
};
export default RadioPass;
