import React, { useMemo } from 'react';
// import PropTypes from 'prop';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// import colors from '../assets/globalStyles/_theme-vars.module.scss';

// import muiComponentStyleOverrides from './muiStyleOverride';

import palette from './palette2';
// import themePalette from './palette';
import typography from './typography2';
import ComponentsOverrides from './componentOverrides';
import GlobalStyles from './globalStyles';
import PropTypes from 'prop-types';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

// export function theme(customization) {
//     const color = colors;

//     console.log(colors);

//     const themeOption = {
//         colors: color,
//         heading: color.grey900,
//         paper: color.paper,
//         backgroundDefault: color.paper,
//         background: color.primaryLight,
//         darkTextPrimary: color.grey700,
//         darkTextSecondary: color.grey500,
//         textDark: color.grey900,
//         menuSelected: color.secondaryDark,
//         menuSelectedBack: color.secondaryLight,
//         divider: color.grey200,
//         customization
//     };

//     return createTheme({
//         direction: 'ltr',
//         palette: themePalette,
//         mixins: {
//             toolbar: {
//                 minHeight: '48px',
//                 padding: '16px',
//                 '@media (min-width: 600px)': {
//                     minHeight: '48px'
//                 }
//             }
//         },
//         breakpoints: {
//             values: {
//                 xs: 0,
//                 sm: 600,
//                 md: 960,
//                 lg: 1280,
//                 xl: 1920
//             }
//         },
//         typography: themeTypography(themeOption),
//         components: muiComponentStyleOverrides(themeOption)
//     });
// }

// export  theme;

export default function ThemeConfig({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node
};
