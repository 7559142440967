import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  // Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule
} from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
// import axios from "axios";
import CustomStore from 'devextreme/data/custom_store';
import { IStateProps } from '../../../types/auth';
const Branches = () => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseURL = useSelector((state: IStateProps) => state?.config?.baseURL);
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const branchName: any = new CustomStore({
    key: 'branchOID',
    load: () => {
      return fetch(`${baseURL}/FABranchs`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseURL}/FABranchs`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      }).then(handleErrors);
    },
    remove: (key: any) => {
      return fetch(`${baseURL}/FABranchs/${key}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }).then(handleErrors);
    },
    update: (values: any) => {
      return values;
    }
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseURL}/FABranchs/`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(branchName.load());
  };

  return (
    <div>
      <header className={'page-ui-header'}>FABranch</header>

      <DataGrid
        id="[$GridID]"
        dataSource={branchName}
        className="dx-datagrid dx-row-alt"
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        activeStateEnabled={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        showBorders={true}
        onRowUpdating={onRowUpdating}
        columnHidingEnabled={true}
      >
        <RowDragging allowReordering={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible={true} />
        <FilterRow visible={true} />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <Editing mode="popup" useIcons={true} allowUpdating={true} allowAdding={true} allowDeleting={true} />

        <Export enabled={true} fileName="FABranchs" allowExportSelectedData={true} />
        <Column type="buttons" />
        <Column dataField="branchOID" allowEditing={false}></Column>
        <Column dataField="branchCode">
          <RequiredRule />
        </Column>
        <Column dataField="branchName">
          <RequiredRule />
        </Column>
        <Column dataField="phoneNumber">
          <RequiredRule />
        </Column>
        <Column dataField="address">
          <RequiredRule />
        </Column>
        <Column dataField="alternateBranchCode"></Column>
        <Column dataField="empolyees"></Column>
      </DataGrid>
    </div>
  );
};
export default Branches;
