// import { DispatchProp } from 'react-redux';
import * as actionTypes from '../../actionTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { IConfig } from '../../../types/auth';

export const initialState: IConfig = {
  baseURL: '',
  theme: ''
};

const configReducers = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case actionTypes.SET_SERVICE_BASE_URL:
      return {
        ...state,
        baseURL: action.payload
      };
    default:
      return state;
  }
};

export default configReducers;
