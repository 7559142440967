import React, { useState, useEffect } from 'react';
import { Formik, Form as FForm, FieldArray } from 'formik';
import styled from '@emotion/styled';
import { Grid, Header, Icon, Form, Button, Message, Label, Confirm } from 'semantic-ui-react';
import axios, { AxiosError } from 'axios';
import { object, string, number, date } from 'yup';
import MyTextInput from './input-controls/MyInlineTextInput';
import { useSelector } from 'react-redux';
import MySelectInput from './input-controls/MyInlineSelectInput';
import MyDateInput from './input-controls/MyInlineDateInput';
import { useGetSystemCodeBySetupType } from '../../../store/actions/ecosystem/systemCodesActions';
import { IStateProps } from '../../../types/auth';
import { mapFunction } from '../../../util';
import { dueEvery, status, trackByDate } from '../static';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react';

interface IProps {
  rowID?: number;
  setMode?: any;
}

const PMTaskForm: React.FC<IProps> = ({ rowID, setMode }) => {
  console.log(rowID);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const taskTypeOptions = useGetSystemCodeBySetupType('TaskType');
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  const [trackByDateValue, setTrackByDateValue] = useState<string>('');
  const [loadState, setLoadState] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const userRoles = useSelector((state: IStateProps) => state?.user?.profile?.userRoles);

  const isAdmin = userRoles.includes('ADMIN');

  const [initialValues, setInitialValues] = useState({
    pmTaskOID: 0,
    equipmentOID: 0,
    taskType: '',
    status: '',
    parentTaskOID: 0,
    trackByDate: '',
    trackByDate_DueEvery: 0,
    trackByDate_DueEvery_UOM: '',
    trackByDate_AdvancedNotice: 0,
    trackByDate_LastPerformed: new Date(),
    trackByHour: '',
    trackByHour_DueEvery: 0,
    trackByHour_AdvancedNotice: 0,
    trackByHour_LastPerformed: new Date(),
    pmTaskDescription: '',
    trackByHour_RecordedOn: new Date(),
    expectedLabourHours: 0,
    notes: '',
    assignedTo_UserGUID: '',
    pmScheduleStartDate: new Date(),
    camspmTaskDetailVirtual: [
      {
        taskDescription: ''
      }
    ]
  });
  const [equipments, setEquipments] = useState([]);

  const taskType = taskTypeOptions.map((element: any) => {
    const { description } = element;
    return {
      key: description,
      text: description,
      value: description
    };
  });

  const userOptions = mapFunction(applicationUsers, (element) => {
    const { id, fullName } = element;
    return {
      key: id,
      text: fullName,
      value: id
    };
  });

  useEffect(() => {
    if (!rowID) return setLoadState(false);
    const url = `${baseAPI}/CAMSPMTasks/${rowID}`;
    setLoadState(true);
    axios
      .get(url)
      .then((response) => {
        return response.data.result[0];
      })
      .then((data: any) => {
        const { trackByDate_LastPerformed, trackByHour_RecordedOn, trackByDate } = data;
        setTrackByDateValue(trackByDate);
        const datas = {
          ...data,
          trackByDate_LastPerformed: new Date(trackByDate_LastPerformed).toLocaleDateString(),
          trackByHour_RecordedOn: new Date(trackByHour_RecordedOn).toLocaleDateString()
        };
        setInitialValues(datas);
        setLoadState(false);
      })
      .catch((err: AxiosError) => {
        const error = err.message;
        notify(error, 'error', 3000);
      });
  }, [rowID, baseAPI]);

  useEffect(() => {
    const url = `${baseAPI}/CAMSEquipments`;
    axios
      .get(url)
      .then((res) => {
        return res.data.result.data;
      })
      .then((data) => {
        const selectedResponse = data.map((element: any) => {
          const { description, equipmentOID } = element;
          return {
            key: equipmentOID,
            text: description,
            value: equipmentOID
          };
        });
        return selectedResponse;
      })
      .then((data) => {
        setEquipments(data);
      })
      .catch((err) => {
        console.log('Equipment Does not exist');
      });
  }, [baseAPI]);

  useEffect(() => {
    const url = `${baseAPI}/CAMSPMTasks`;
    axios
      .get(url)
      .then((res) => {
        return res.data.result.data;
      })
      .then((data) => {
        const selectedResponse = data.map((element: any) => {
          const { notes, pmTaskOID } = element;
          return {
            key: pmTaskOID,
            text: notes,
            value: pmTaskOID
          };
        });
        return selectedResponse;
      })
      .then((data) => {
        // setPMTask(data);
      })
      .catch((err) => {
        console.log('PM Task Does not exist');
      });
  }, [baseAPI]);

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleDeleteClick = () => {
    setDeleteConfirm(true);
  };

  const handleDeleteConfirmClick = () => {
    axios
      .delete(`${baseAPI}/CAMSPMTasks/${initialValues.pmTaskOID}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then(() => {
        setMode('GRID');
      });
  };

  const handleDeleteCancelClick = () => {
    setDeleteConfirm(false);
  };

  const handleEditFormSubmit = (task: any) => {
    console.log(task);
    const url = `${baseAPI}/CAMSPMTasks/${rowID}`;
    const condition = trackByDateValue === 'YES' ? true : false;
    const { trackByDate_AdvancedNotice, trackByDate_DueEvery, trackByDate_DueEvery_UOM, trackByDate_LastPerformed } = task;
    const { trackByHour_AdvancedNotice, trackByHour_DueEvery, trackByHour_LastPerformed, trackByHour_RecordedOn } = task;
    const data = {
      ...task,
      trackByDate: condition ? 'YES' : 'NO',
      trackByHour: condition ? 'NO' : 'YES',
      trackByDate_AdvancedNotice: condition ? trackByDate_AdvancedNotice : 0,
      trackByDate_DueEvery: condition ? trackByDate_DueEvery : 0,
      trackByDate_DueEvery_UOM: condition ? trackByDate_DueEvery_UOM : '',
      trackByDate_LastPerformed: condition ? trackByDate_LastPerformed : '',
      trackByHour_AdvancedNotice: condition ? 0 : trackByHour_AdvancedNotice,
      trackByHour_DueEvery: condition ? 0 : trackByHour_DueEvery,
      trackByHour_LastPerformed: condition ? '' : trackByHour_LastPerformed,
      trackByHour_RecordedOn: condition ? '' : trackByHour_RecordedOn
    };

    axios
      .put(url, data)
      .then(() => setMode('GRID'))
      .catch((err) => {
        const error = err.message;
        notify(error, 'error', 3000);
      });
  };

  const handleAddFormSubmit = (task: any) => {
    const url = `${baseAPI}/CAMSPMTasks`;
    const condition = trackByDateValue === 'YES' ? true : false;
    const { trackByDate_AdvancedNotice, trackByDate_DueEvery, trackByDate_DueEvery_UOM, trackByDate_LastPerformed } = task;
    const { trackByHour_AdvancedNotice, trackByHour_DueEvery, trackByHour_LastPerformed, trackByHour_RecordedOn } = task;
    const data = {
      ...task,
      trackByDate: condition ? 'YES' : 'NO',
      trackByHour: condition ? 'NO' : 'YES',
      trackByDate_AdvancedNotice: condition ? trackByDate_AdvancedNotice : 0,
      trackByDate_DueEvery: condition ? trackByDate_DueEvery : 0,
      trackByDate_DueEvery_UOM: condition ? trackByDate_DueEvery_UOM : '',
      trackByDate_LastPerformed: condition ? trackByDate_LastPerformed : '',
      trackByHour_AdvancedNotice: condition ? 0 : trackByHour_AdvancedNotice,
      trackByHour_DueEvery: condition ? 0 : trackByHour_DueEvery,
      trackByHour_LastPerformed: condition ? '' : trackByHour_LastPerformed,
      trackByHour_RecordedOn: condition ? '' : trackByHour_RecordedOn
    };
    axios
      .post(url, data)
      .then(() => setMode('GRID'))
      .catch((err) => {
        const error = err.message;
        notify(error, 'error', 3000);
      });
  };

  const validationSchema = object({
    pmTaskOID: number(),
    equipmentOID: number().required('Equipment is required').min(1, 'Equipment is required'),
    taskType: string().required('Task Type is required'),
    status: string().required('Status is required'),
    parentTaskOID: number(),
    notes: string().nullable(),
    assignedTo_UserGUID: string().nullable().required('User Is required'),
    pmTaskDescription: string().required('Description is required'),
    pmScheduleStartDate: date().required('PM Schedule Start Date is required'),
    expectedLabourHours: number(),
    trackByDate: string(),
    trackByDate_DueEvery: number()
      .nullable()
      .when('trackByDate', {
        is: 'YES',
        then: (schema) => schema.moreThan(0, 'Due Every Should be greater than 0') //.positive('Due Every Should be a positive value')
      }),
    trackByDate_DueEvery_UOM: string().when('trackByDate', {
      is: 'YES',
      then: (schema) => schema.required('Due Every Duration is required')
    }),
    trackByDate_AdvancedNotice: number().when('trackByDate', {
      is: 'YES',
      then: (schema) => schema.moreThan(0, 'Advanced Notice Should be greater than 0') //positive('Advanced Notice Should be a positive value')
    }),
    trackByDate_LastPerformed: date(),
    trackByHour: string(),
    trackByHour_DueEvery: number().when('trackByDate', {
      is: 'NO',
      then: (schema) => schema.moreThan(0, 'Due Every Should be greater than 0') //.positive('Due Every Should be a positive value')
    }),
    trackByHour_AdvancedNotice: number().when('trackByDate', {
      is: 'NO',
      then: (schema) => schema.moreThan(0, 'Advanced Notice Should be greater than 0') //.positive('Advanced Notice Should be a positive value')
    }),
    trackByHour_LastPerformed: date(),
    trackByHour_RecordedOn: date()
  });

  return (
    <StyledProvider>
      <Header as="h2">
        <Icon onClick={handleCancelClick} className="cursor-pointer" size="big" name="arrow circle left" color="blue" />
        PM Task
      </Header>
      <Message color="brown" size="mini">
        <Message.Header>Manage PM task</Message.Header>
        <p> You can create and manage PM tasks. </p>
      </Message>
      {loadState ? (
        <div className="text-center">
          <LoadIndicator id="large-indicator" height={60} width={60} />
          <p>Loading</p>
        </div>
      ) : (
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
        >
          {({ isValid, isSubmitting, values }) => (
            <FForm className="ui form">
              <Grid columns={2}>
                {/* first form section */}
                <Grid.Row>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MySelectInput label="Equipment" placeholder="" name="equipmentOID" options={equipments} />
                    <MySelectInput label="Status" placeholder="" name="status" options={status} />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MySelectInput label="PM Schedules" placeholder="" name="taskType" options={taskType} />
                    {/* <MyTextInput label="Notes" placeholder="notes" name="notes" /> */}
                    <MySelectInput label="Assigned To" placeholder="Please pick a user" name="assignedTo_UserGUID" options={userOptions} />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MyTextInput label="Description" placeholder="Description" name="pmTaskDescription" />
                    <MyDateInput type="date" label="PM Schedule Start Date" placeholder="" name="pmScheduleStartDate" />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    {/* <MySelectInput label="Track By" placeholder="Please pick a user" name="trackByDate" options={trackByDate} /> */}
                    <Form.Select
                      inline
                      fluid
                      label="Track By"
                      options={trackByDate}
                      onChange={(e, { value }: any) => {
                        setInitialValues({
                          ...values,
                          trackByDate: value
                        });
                        setTrackByDateValue(value);
                      }}
                      value={trackByDateValue}
                      placeholder="track  by"
                    />
                  </Form.Group>
                </Grid.Row>

                {/* Form SECTION 2 */}
                <Grid.Row width="16">
                  {trackByDateValue === 'YES' ? (
                    <Grid.Column width="16">
                      <Header className="text-pink-500" as="h4" dividing>
                        <Icon className="outline-none" name="edit outline" />
                        Track by Date?
                      </Header>
                      <Form.Group widths="equal">
                        <MyTextInput label="Due Every" type="number" placeholder="" name="trackByDate_DueEvery" />
                        <MySelectInput placeholder="Duration" name="trackByDate_DueEvery_UOM" options={dueEvery} />
                      </Form.Group>

                      <Form.Group widths={2} inline>
                        <MyTextInput label="Advanced Notice (Days)" type="number" placeholder="" name="trackByDate_AdvancedNotice" />
                        <MyDateInput type="date" label="Last Performed" placeholder="" name="trackByDate_LastPerformed" />
                      </Form.Group>
                      {/* <MyTextInput type="number" label="Expected Labour Hours" placeholder="" name="expectedLabourHours" /> */}
                    </Grid.Column>
                  ) : (
                    <Grid.Column width="16">
                      <Header as="h4" dividing>
                        <Icon className="outline-none" name="edit outline" />
                        Track by Hour?
                      </Header>
                      <MyTextInput label="Due Every" type="number" placeholder="" name="trackByHour_DueEvery" />
                      <MyTextInput label="Advanced Notice (Hourly)" type="number" placeholder="" name="trackByHour_AdvancedNotice" />

                      <MyDateInput type="date" label="Last Performed" name="trackByHour_LastPerformed" />
                      <MyDateInput type="date" label="Recorded On" placeholder="" name="trackByHour_RecordedOn" />
                    </Grid.Column>
                  )}
                </Grid.Row>

                <Header content="PM Procedure" style={{ width: '100%' }} as="h3" dividing />
                <Grid.Row columns={1} width={8}>
                  <FieldArray name="camspmTaskDetailVirtual">
                    {({ insert, remove, push }) => (
                      <div style={{ width: '100%' }}>
                        <div
                          className="it__details_add"
                          onClick={() =>
                            push({
                              pmTaskOID: values.pmTaskOID,
                              taskDescription: '',
                              taskStatus: '',
                              remarks: ''
                            })
                          }
                        >
                          <Icon name="plus" /> Add Item
                        </div>
                        {values.camspmTaskDetailVirtual.length > 0 &&
                          values.camspmTaskDetailVirtual.map((item, index) => (
                            <div className="it__details_form_item" key={index}>
                              <span className="it__number">{index + 1}. </span>
                              <MyTextInput placeholder="" name={`camspmTaskDetailVirtual.${index}.taskDescription`} />

                              <Label color="red" size="big" as="a" type="button" className="secondary" onClick={() => remove(index)}>
                                X
                              </Label>
                            </div>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                </Grid.Row>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  lineHeight: '25px',
                  justifyContent: 'flex-end',
                  gap: '0.5rem'
                }}
              >
                <Button
                  primary
                  content="Save"
                  type="button"
                  onClick={() => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
                  className="save-btn"
                  disabled={!isValid || isSubmitting}
                />

                <Button content="Cancel" onClick={handleCancelClick} />
                {isAdmin ? <Button content="Delete" color="red" onClick={handleDeleteClick} /> : null}
              </div>
            </FForm>
          )}
        </Formik>
      )}
      <Confirm
        header={`Delete Confirmation`}
        content={`Are you sure you want to delete the task (${initialValues.pmTaskDescription})`}
        open={deleteConfirm}
        onCancel={handleDeleteCancelClick}
        onConfirm={handleDeleteConfirmClick}
        size="mini"
      />
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  & .save-btn {
    cursor: pointer;
  }
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  .ui.grid > .row {
    padding: 1rem !important;
  }

  .it__details_form_item {
    align-items: center;
    width: 100%;
    display: flex;
    column-gap: 5px;

    & .ui.input {
      min-width: 300px;
    }
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }

  .inline.field {
    width: 100%;
  }
`;

export default PMTaskForm;
