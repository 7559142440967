import { useField } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  label?: string;
  id?: string;
  disabled?: boolean;
  width?: number | string;
  list?: any | string;
}

export default function MyTextInput(props: Props) {
  const [field, meta] = useField(props.name);

  return (
    // <Form.Group inline>
    <Form.Field error={meta.touched && !!meta.error} inline>
      {/* <label htmlFor={props.id}>{props.label}</label> */}
      {props.label && <label style={{ width: '20%' }}>{props.label}</label>}
      <input style={{ width: props.label ? '75%' : '100%' }} className="ui input" id={props.id} {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label className=" w-max  " style={{ display: 'block' }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
    // </Form.Group>
  );
}
