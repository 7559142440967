import React from "react";
import { useField } from "formik";
import "./radio.css";

interface IProps {
  name: string;
  label?: string | undefined;
  value: string;
  checked: boolean;
}

const RadioPending: React.FC<IProps> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <label htmlFor={`pending${props.name}`}>
      <input
        type="radio"
        // name={name}
        className="pending"
        id={`pending${props.name}`}
        // value={value}
        {...field}
        {...props}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <path d="M 12 0 C 5.382813 0 0 5.382813 0 12 C 0 18.617188 5.382813 24 12 24 C 18.617188 24 24 18.617188 24 12 C 24 5.382813 18.617188 0 12 0 Z M 12 2 C 17.535156 2 22 6.464844 22 12 C 22 14.398438 21.148438 16.59375 19.75 18.3125 L 5.6875 4.25 C 7.40625 2.851563 9.601563 2 12 2 Z M 4.25 5.6875 L 18.3125 19.75 C 16.59375 21.148438 14.398438 22 12 22 C 6.464844 22 2 17.535156 2 12 C 2 9.601563 2.851563 7.40625 4.25 5.6875 Z"></path>
      </svg>
      <span>{props.label}</span>
    </label>
  );
};
export default RadioPending;
