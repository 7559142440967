import React from 'react';
// import { Segment, Header, Message, Icon, Grid, Tab } from "semantic-ui-react";

// import WorkOrderChart from "./visual-reports/WorkOrderChart";
// import PurchaseOrderChart from "./visual-reports/PurchaseOrderChart";
// import MaintainanceGraph from "./visual-reports/MaintainanceGraph";

export const populationByRegions = [
  {
    region: 'Asia',
    val: 4119626293
  },
  {
    region: 'Africa',
    val: 1012956064
  },
  {
    region: 'Northern America',
    val: 344124520
  },
  {
    region: 'Latin America and the Caribbean',
    val: 590946440
  },
  {
    region: 'Europe',
    val: 727082222
  },
  {
    region: 'Oceania',
    val: 35104756
  }
];

// const panes = [
//   {
//     menuItem: {
//       menuItem: <Segment color="orange">Orange</Segment>,
//     },
//     render: () => <Tab.Pane attached={false}>Tab 1 Content</Tab.Pane>,
//   },
//   {
//     menuItem: "Tab 2",
//     render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
//   },
//   {
//     menuItem: "Tab 3",
//     render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
//   },
// ];

const CamsDashboard = () => {
  // const customizeTooltip = (arg: any) => {
  //   return {
  //     text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
  //   };
  // };

  return (
    <div>
      {/* <Header content="Home" size="small" />
      <Grid stackble>
        <Grid.Column width="9">
          <Tab menu={{ secondary: true }} panes={panes} />
        </Grid.Column>
        <Grid.Column width="7">
          <Grid.Row fluid width="16">
            <WorkOrderChart />
          </Grid.Row>
          <Grid.Row fluid width="16">
            <PurchaseOrderChart />
          </Grid.Row>
          <Grid.Row fluid width="16">
            <MaintainanceGraph />
          </Grid.Row>
        </Grid.Column>
      </Grid> */}
    </div>
  );
};

export default CamsDashboard;
