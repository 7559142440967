import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';

import { AppBar, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import clsx from 'clsx';

import Header from './header/Header';
import Sidebar from './sideBar';
import { drawerWidth } from '../../themes/constants';

import { SET_MENU } from '../../store/actionTypes';
import MergedERPRoutes from '../../routes/mergedERPRoutes';
import './side-nav-outer-toolbar.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: theme.palette.background.default
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default
  },
  content: {
    ...theme.typography.mainContent,
    marginTop: '80px',
    padding: '20px',
    flex: 1,
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20)
      // width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '20px',
      // width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
      // width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  },
  contentShift: {
    overflow: 'hidden',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('lg')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px'
    }
  }
}));

const MainLayout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  // Handle left drawer
  const menuState = useSelector((state) => state.global?.menuState);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, payload: !menuState });
  };

  React.useEffect(() => {
    dispatch({ type: SET_MENU, payload: !matchDownMd });
  }, [matchDownMd, dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar position="fixed" color="inherit" elevation={0} className={menuState ? classes.appBarWidth : classes.appBar}>
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={menuState} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: menuState
          }
        ])}
      >
        <MergedERPRoutes />
      </main>
    </div>
  );
};

export default MainLayout;
