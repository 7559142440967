import { FETCH_CAMS_USER_PROFILES, FETCH_CAMS_USER_PROFILES_FAILED } from '../../actionTypes'; // import {  ILMSUser } from "../../../types/types";

const InitialState = {
  camsUserProfilesLoaded: false,
  camsUserProfiles: [],
  camsUserProfile: {}
};

const camsUserProfilesReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMS_USER_PROFILES:
      return {
        ...state,
        camsUserProfilesLoaded: true,
        camsUserProfiles: [...action.payload.camsUserProfiles]
      };

    case FETCH_CAMS_USER_PROFILES_FAILED:
      return {
        ...state,
        camsUserProfilesLoaded: true,
        camsUserProfiles: [...action.payload.camsUserProfiles]
      };

    default:
      return state;
  }
};

export default camsUserProfilesReducer;
