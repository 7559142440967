import * as React from 'react';
import { useEffect } from 'react';

import Form, {
  // ButtonItem,
  // GroupItem,
  SimpleItem,
  // Label,
  // CompareRule,
  // EmailRule,
  PatternRule,
  // RangeRule,
  RequiredRule
  // StringLengthRule,
  // AsyncRule,
} from 'devextreme-react/form';

// import CheckBox from "devextreme-react/check-box";
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
import './dynamicform.scss';

const colCountByScreen = {
  sm: 1,
  md: 2,
  lg: 3
};

function screenByWidth(width: any) {
  return width > 1140 ? 'lg' : width > 720 ? 'md' : 'sm';
}

const DynamicForm = ({ formData, setFormData, shouldEdit }: any) => {
  // console.log(formData);
  // const [readOnly, setReadOnly] = React.useState(true);
  // const [mockFormData, setMockFormData] = React.useState(formData);

  const onFieldDataChanged = (e: any) => {
    // console.log(e.component.option("formData"));
    setFormData(e.component.option('formData'));
  };

  useEffect(() => {
    // console.log("RENDERING AGAIN");
  }, []);
  return (
    <div className="form-container">
      {/* <div>Select company:</div> */}
      {/* <SelectBox
        displayExpr="Name"
        dataSource={formData}
        value={formData}
        onValueChanged={() => console.log("hello")}
      /> */}
      {/* <Form
        id="form"
        formData={formData}
        readOnly={!shouldEdit}
        //   showColonAfterLabel={showColon}
        labelLocation={"top"}
        minColWidth={300}
        colCount={3} //dynamic changes
        width={"auto"} //dynamic changes
      /> */}
      <Form
        formData={formData}
        readOnly={!shouldEdit}
        showColonAfterLabel
        showValidationSummary
        onFieldDataChanged={onFieldDataChanged}
        validationGroup="companyData"
        colCountByScreen={colCountByScreen}
        screenByWidth={screenByWidth}
        labelLocation="top"
        minColWidth={233}
        colCount="auto"
      >
        {/* <GroupItem caption="Info"> */}
        <SimpleItem dataField="id" editorOptions={{ readOnly: true }}>
          <RequiredRule message="ID is required" />
          <PatternRule message="" pattern={/[0-9]+$/} />
        </SimpleItem>
        <SimpleItem dataField="name">
          <RequiredRule message="Name is required" />
          {/* <PatternRule message="" pattern={/w+$/} /> */}
        </SimpleItem>
        {/* <SimpleItem
            dataField="businessSector"
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: businessSector,
              searchEnabled: true,
              valueExpr: "value",
              displayExpr: "title",
              // defaultValue: "businessSector",
              onValueChange: (e: any) => {
                console.log(e);
              },
            }}>
            <RequiredRule message="Business sector is required" />
            
          </SimpleItem> */}
        <SimpleItem dataField="rolePriority" editorType="dxTextBox">
          <RequiredRule message="Role Priority is required" />
          {/* <EmailRule message="Email is invalid" /> */}
        </SimpleItem>
        <SimpleItem dataField="description">
          <RequiredRule message="description is required" />
          {/* <PatternRule message="" pattern={/w+$/} /> */}
        </SimpleItem>
        {/* <SimpleItem dataField="normalizedName"></SimpleItem> */}
        <SimpleItem dataField="concurrencyStamp"></SimpleItem>
        {/* </GroupItem> */}
      </Form>
    </div>
  );
};

export default DynamicForm;
