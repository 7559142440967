import React, { useState, useEffect } from 'react';

import axios from 'axios';
// import { baseAPI } from "../../../api/APIEndpoints";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
// import "../../components/Grid/Grid.module.css";
// import "../../../components/Grid/Grid.module.css";

import 'devextreme-react/text-area';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  Scrolling,
  Editing,
  // Button as Point,
  Pager,
  Grouping,
  GroupPanel,
  LoadPanel as Loader,
  // RowDragging,
  // Lookup,
  Export,
  Column
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

import 'whatwg-fetch';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import { useSelector } from 'react-redux';
import CustomCheckBox from './CustomCheckBox';
import { IStateProps } from '../../../types/auth';

// const actionLookupSource = [
//   { title: "YES", value: 1 },
//   { title: "NO", value: 0 },
// ];

const AssignTasksToRole: React.FC<any> = () => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseUrl = useSelector((state: IStateProps) => state?.config?.baseURL);

  const [loading, setLoading] = useState(false);
  // const [hasFetched, setHasFetched] = useState(false);
  const [lookupFetched, setLookupFetched] = useState(false);
  // const [rolesLookup, setRolesLookup]: any = useState([{}]);

  const [data, setData] = useState([]);
  const [role, setRole]: any = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  // const [mode, setMode] = useState("list");
  // const [rowID,setRowId ]: any = useState();
  const [roleOID, setRoleOID]: any = useState();
  // const [taskOID, setTaskOID]: any = useState();
  // const [userRoleTaskOID, setUserRoleTaskOID]: any = useState();
  // const [selectedRow, setSelectedRow]: any = React.useState({});
  // const [modalVisible, setModalVisible] = useState(false);
  // const [loadState, setLoadState] = useState(false);

  // const roleApiURL = `${baseUrl}/ecouserroletask/${rowID}`;
  // const putRoleApiURL = `${baseUrl}/ecouserroletask`;

  function handleErrors(response: any) {
    if (!response.ok) {
      setLoading(false);
      throw Error(response.statusText);
    }
    return response;
  }

  const handleSelectChange = (value: any) => {
    let isGetAll = true;
    let data: any;
    if (value) {
      isGetAll = false;
      data = value.selectedItem.value;
    }
    setRoleOID(value.selectedItem.value);

    const customDataSource: any = new CustomStore({
      key: `${!isGetAll && 'taskOID'}`,
      load: () => {
        //console.log(data);
        return fetch(`${baseUrl}/EcoUserRoleTask${isGetAll ? '' : '/getRoleTasks/' + data}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result
            };
          });
      }
    });
    if (isGetAll) {
      setRoleOID(data);
      console.log(data);
      setIsVisible(!isVisible);
    }
    setData(customDataSource);
  };

  function renderCheckBox(value: any) {
    // console.log(value);
    return <CustomCheckBox value={value} roleOID={roleOID} />;
  }

  useEffect(() => {
    setLoading(true);
    // handleSelectChange(null);

    axios.get(`${baseUrl}/ApplicationRole/getRolesForSelect`).then((res) => {
      setRole(res.data.result);
      // axios
      //   .get(`${baseUrl}/EcoTask`, {
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      // console.log(res.data.result);
      // setData(res.data.result);
      setLookupFetched(true);
      setLoading(false);
      // });
    });
  }, [baseUrl]);
  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>Assign Tasks to Role</header>
      <div className="module-wrapper" id={'user'}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#user' }}
          // onHiding={hideLoadPanel}
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          // closeOnOutsideClick={closeOnOutsideClick}
        />

        <div className="search-component">
          <label htmlFor="userRole">
            Select Role
            <SelectBox
              style={{ marginTop: '10px' }}
              name={'userRole'}
              id={'userRole'}
              className={'roles-select-box'}
              //   items={createLookup(optionsList, "string", "")}
              onSelectionChanged={handleSelectChange}
              // defaultValue={lookupFetched && rolesLookup[0]}
              dataSource={lookupFetched && role}
              displayExpr="title"
              valueExpr="value"
            />
          </label>
        </div>

        <DataGrid
          id="[$GridID]"
          dataSource={data}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          showBorders={true}
          columnHidingEnabled={true}
        >
          {/* <RowDragging allowReordering={true} /> */}
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
          <Grouping contextMenuEnabled={true} />

          <GroupPanel visible={false} />
          <Export enabled={true} fileName={'Assign_Task_to_Role'} allowExportSelectedData={false} />
          <Editing
            mode="popup"
            // useIcons={true}
            // allowUpdating={true}
            // allowDeleting={true}
          />
          <Scrolling mode="standard" />
          <Loader enabled />
          {/* <Column type="buttons" caption="Operation">
            <Point
              text="Permissions"
              cssClass={"manage-permission-btn"}
              // onClick={commandClick}
            />
          </Column> */}
          {/* <Column dataField="userRoleTaskOID"></Column> */}
          <Column dataField="taskOID"></Column>
          <Column dataField="applicationCode"></Column>
          <Column dataField="moduleDescription"></Column>
          <Column dataField="taskDescription"></Column>
          <Column dataField="canEdit" cellRender={renderCheckBox}></Column>
          <Column dataField="canDelete" cellRender={renderCheckBox}></Column>
          <Column dataField="canCreate" cellRender={renderCheckBox}></Column>
          <Column dataField="canPost" cellRender={renderCheckBox}></Column>
          <Column dataField="canPrint" cellRender={renderCheckBox}></Column>
          <Column dataField="canExport" cellRender={renderCheckBox}></Column>
        </DataGrid>
      </div>
    </div>
  );
};
export default AssignTasksToRole;
