import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import CamsDashboard from '../projects/cams/pages/CamsDashboard/CamsDashboard';
import AccountingRoutes from './accountingRoutes';
import CAMSRoutes from './camsRoutes';
import ServiceRoutes from './configRoutes';
import { AuthRoutes } from './configRoutes';

import EcosystemRoutes from './ecosystemRoutes';
import 'devextreme/dist/css/dx.light.css';

export default function MergedERPRoutes() {
  return (
    // <Suspense fallback={<FullPageLoader />}>
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route
        path="dashboard"
        element={
          <AuthRoutes redirectTo="/login">
            <CamsDashboard />
          </AuthRoutes>
        }
      />
      <Route
        path="ecosystem/*"
        element={
          <ServiceRoutes>
            <EcosystemRoutes />
          </ServiceRoutes>
        }
      />
      <Route
        path="accounting/*"
        element={
          <ServiceRoutes>
            <AccountingRoutes />
          </ServiceRoutes>
        }
      />
      <Route
        path="cams/*"
        element={
          <ServiceRoutes>
            <CAMSRoutes />
          </ServiceRoutes>
        }
      />
      <Route
        path="ppp/*"
        element={
          <ServiceRoutes>
            <EcosystemRoutes />
          </ServiceRoutes>
        }
      />
      {/* <Route
        path="tf/*"
        element={
          <ServiceRoutes>
            <EcosystemRoutes />
          </ServiceRoutes>
        }
      />
      <Route
        path="ff/*"
        element={
          <ServiceRoutes>
            <EcosystemRoutes />
          </ServiceRoutes>
        }
      />
      <Route
        path="gameplan/*"
        element={
          <ServiceRoutes>
            <EcosystemRoutes />
          </ServiceRoutes>
        }
      /> */}
    </Routes>
    // </Suspense>
  );
}
