// import React from "react";
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { logoutUser } from '../store/actions/authAction';
import { store } from '../store/createStore';

export const setHeaders = () => {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // const token = useSelector((state: any) => state.user.jwt);
      const authState = store.getState().user.token;
      // console.log('TOKEN from AXIOS', token);
      // console.log('authState', authState);

      if (authState && config) {
        config.headers = {
          Authorization: `Bearer ${authState}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        };
      }
      return config;
    },
    (err: AxiosError) => {
      return err;
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err: AxiosError) => {
      if (err.response) {
        const dispatch = useDispatch();
        const { status } = err.response;
        switch (status) {
          case 403:
            return Promise.reject(err);
          case 401:
            // break;
            dispatch(logoutUser());
            <Navigate to="login" />;
            return Promise.reject(err);

          case 400:
            return Promise.reject(err);

          default:
            return Promise.reject(err);
        }
      } else {
        //  Network issue,

        return Promise.reject(err);
      }
    }
  );
};
