import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Message, Form, Header, Icon } from 'semantic-ui-react';
import { Formik, Form as FForm } from 'formik';
import MyTextInput from './input-controls/MyTextInput';
import MySelectInput from './input-controls/MySelectInput';
// import MyTextArea from './input-controls/MyTextArea';
// import MyDateInput from './input-controls/MyDateInput';
import styled from '@emotion/styled';
import { useGetSystemCodeBySetupType } from '../../../store/actions/ecosystem/systemCodesActions';
import { IStateProps } from '../../../types/auth';
import { mapFunction } from '../../../util';
import axios from 'axios';

// function handleErrors(response: any) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }

// const genderOptions = [
//   { key: "m", text: "Male", value: "male" },
//   { key: "f", text: "Female", value: "female" },
//   { key: "o", text: "Other", value: "other" },
// ];

interface IEQGridProps {
  rowID?: number;
  setMode: any;
}

const EquipmentGridForm: React.FC<IEQGridProps> = ({ setMode, rowID }) => {
  const EquipmentStatus = useGetSystemCodeBySetupType('EquipmentStatus');
  // const EquipmentOwnership = useGetSystemCodeBySetupType('EquipmentOwnership');
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  const [initialValues, setInitialValues] = useState({
    equipmentOID: 0,
    unit: '',
    description: '',
    equipmntType: '',
    make: '',
    model: '',
    yearOfProduction: 0,
    color: '',
    keyword: '',
    serialNumber: '',
    tag: '',
    state: '',
    hasMeter: '',
    primaryMeter: '',
    primaryMeterCurrentReading: 0,
    secondaryMeter: '',
    secondaryMeterCurrentReading: 0,
    equipmentStatus: '',
    operator: 0,
    location: 0,
    category: '',
    maintenanceTemplateOID: 0,
    inspectionTemplateOID: 0,
    parentAsset: 0,
    purchaseDate: '',
    vendorOID: 0,
    purchasePrice: 0,
    initialPrimaryMeterReading: 0,
    initialSecondaryMeterReading: 0,
    salvageValue: 0,
    lifeSpan: 0,
    primaryWarranteeDate: '',
    secondaryWarranteeDate: '',
    equipmentOwnership: '',
    annualBudget: 0,
    notes: ''
  });
  const jwt = useSelector((state: IStateProps) => state.user.token);
  // const { hrEmployeesV2s } = useSelector((state: IStateProps) => state?.cams?.hrEmployeeV2);
  const { camsLocations } = useSelector((state: IStateProps) => state?.cams?.camsLocation);

  // const employeeOption = mapFunction(hrEmployeesV2s, (element) => {
  //   const { otherNames, surname, employeeOID } = element;
  //   return {
  //     key: employeeOID,
  //     text: otherNames + ' ' + surname,
  //     value: employeeOID
  //   };
  // });

  const locationOption = mapFunction(camsLocations, (element) => {
    const { locationOID, locationName } = element;
    return {
      key: locationOID,
      text: locationName,
      value: locationOID
    };
  });

  const equipmentStatusOptions = mapFunction(EquipmentStatus, (element: any) => {
    const { description } = element;
    return {
      key: description,
      text: description,
      value: description
    };
  });
  // const equipmentOwnershipOptions = mapFunction(EquipmentOwnership, (element: any) => {
  //   const { oid, description } = element;
  //   return {
  //     key: oid,
  //     text: description,
  //     value: oid
  //   };
  // });

  // const [inspection, setInspection] = useState([]);
  // const [maintenance, setMaintenance] = useState([]);
  // const [parent, setParent] = useState([]);

  // useEffect(() => {
  //   const fetchInspectionData = async () => {
  //     fetch(`${baseAPI}/CAMSInspectionTemplates`, {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`
  //       }
  //     })
  //       .then(handleErrors)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         const selectResponse = response.result.data.map((element: any) => {
  //           const { inspectionTemplateOID, templateName } = element;
  //           return {
  //             key: inspectionTemplateOID,
  //             text: templateName,
  //             value: inspectionTemplateOID
  //           };
  //         });
  //         return selectResponse;
  //       })
  //       .then((data: any) => setInspection(data));
  //   };
  //   fetchInspectionData();
  // }, [baseAPI, jwt]);

  // useEffect(() => {
  //   const url = `${baseAPI}/CAMSEquipments`;

  //   axios
  //     .get(url)
  //     .then((res) => {
  //       const response = res.data.result.data;
  //       const selectedResponse = mapFunction(response, (element: any) => {
  //         const { equipmentOID, description } = element;
  //         return {
  //           key: equipmentOID,
  //           text: description,
  //           value: equipmentOID
  //         };
  //       });
  //       return selectedResponse;
  //     })
  //     .then((data: any) => setParent(data));
  // }, [baseAPI, jwt]);

  // useEffect(() => {
  //   const fetchMaintenanceTemplateData = async () => {
  //     fetch(`${baseAPI}/CAMSMaintTemplates`, {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`
  //       }
  //     })
  //       .then(handleErrors)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         const selectedResponse = response.result.data.map((element: any) => {
  //           const { maintenanceTemplateOID, templateName } = element;
  //           return {
  //             key: maintenanceTemplateOID,
  //             text: templateName,
  //             value: maintenanceTemplateOID
  //           };
  //         });
  //         return selectedResponse;
  //       })
  //       .then((data: any) => setMaintenance(data));
  //   };
  //   fetchMaintenanceTemplateData();
  // }, [baseAPI, jwt]);

  useEffect(() => {
    if (rowID) {
      const url = `${baseAPI}/CAMSEquipments/${rowID}`;

      axios.get(url).then((res) => {
        const response = res.data.result[0];
        setInitialValues(response);
      });
    }
  }, [rowID, baseAPI, jwt]);

  const handleEditFormSubmit = (equipments: any) => {
    const url = `${baseAPI}/CAMSEquipments/${rowID}`;

    axios.put(url, equipments).then(() => setMode('GRID'));
  };

  const handleAddFormSubmit = (equipments: any) => {
    const url = `${baseAPI}/CAMSEquipments`;
    axios.post(url, equipments).then(() => setMode('GRID'));
  };

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleDeleteClick = (e: any) => {
    const url = `${baseAPI}/CAMSEquipments/${rowID}`;
    axios.delete(url).then(() => setMode('GRID'));
  };

  return (
    <StyledProvider>
      <>
        <Header>
          <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
          Equipment
        </Header>
        <Message color="brown" size="mini">
          <Message.Header>Manage Equipment</Message.Header>
          <p>Create and manage Equipment.</p>
        </Message>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <FForm className="ui form" onSubmit={handleSubmit} autoComplete="off">
              <Form.Group widths="equal">
                {/* <MyTextInput
                  id="form-input-control-equipment-oid"
                  label="Equipment OID"
                  disabled
                  placeholder="Equipment OID"
                  name="equipmentOID"
                /> */}
                <MyTextInput id="form-input-control-make" label="Make" placeholder="Make" name="make" />
                <MySelectInput
                  options={equipmentStatusOptions}
                  label="Status"
                  placeholder="select your equipment status"
                  name="equipmentStatus"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextInput
                  id="form-input-control-equipment-name"
                  label="Equipment Name"
                  placeholder="Equipment Name"
                  name="description"
                />
                <MyTextInput id="form-input-control-model" label="Model" placeholder="Model" name="model" />
                <MySelectInput options={locationOption} label="Location" placeholder="Location" name="location" />
                {/* <MySelectInput options={employeeOption} label="Operator" placeholder="select an operator" name="operator" /> */}
              </Form.Group>
              {/* <Form.Group widths="equal">
                <MyTextInput id="form-input-control-unit" label="Unit" placeholder="Unit" name="unit" />
                <MyTextInput
                  id="form-input-control-production-year"
                  label="Production Year"
                  placeholder="Production Year"
                  name="yearOfProduction"
                />
                <MySelectInput options={locationOption} label="Location" placeholder="Location" name="Location" />
              </Form.Group> */}
              <Form.Group widths="equal">
                <MyTextInput
                  id="form-input-control-equipment-type"
                  label="Equipment Type"
                  placeholder="Equipment Type"
                  name="equipmntType"
                />
                <MyTextInput id="form-input-control-keywords" label="Keywords" placeholder="Keywords" name="keyword" />

                {/* <MyTextInput id="form-input-control-serial-no" label="Serial No" placeholder="Serial No" name="serialNumber" /> */}
                {/* <MySelectInput
                  options={inspection}
                  label="Inspection Template"
                  placeholder="select  a inspection templatee"
                  name="inspectionTemplateOID"
                /> */}
                {/* <MySelectInput
                  options={maintenance}
                  label="Maintainance"
                  placeholder="select a maintainance template"
                  name="maintenanceTemplateOID"
                /> */}
              </Form.Group>
              {/* <Form.Group widths="equal">
                <MyTextInput id="form-input-control-color" label="Color" placeholder="Color" name="color" />
                <MyTextInput id="form-input-control-tag-no" label="Tag No. " placeholder="Tag No" name="tag" />
                <MySelectInput
                  options={inspection}
                  label="Inspection Template"
                  placeholder="select  a inspection templatee"
                  name="inspectionTemplateOID"
                />
              </Form.Group> */}
              <Form.Group widths="equal">
                {/* <MyTextInput id="form-input-control-has-meter" label="Has Meter" placeholder="Has Meter" name="hasMeter" /> */}
                {/* <MyTextInput id="form-input-control-keywords" label="Keywords" placeholder="Keywords" name="keyword" /> */}
                {/* <MyTextInput width="6" id="form-input-control-category" label="Category" placeholder="Category" name="category" /> */}
                {/* <MySelectInput options={parent} label="Parent" placeholder="Parent" name="parentAsset" /> */}
              </Form.Group>
              {/* <Form.Group widths="16">
                <MyTextInput width="6" id="form-input-control-category" label="Category" placeholder="Category" name="category" />
              </Form.Group> */}
              {/* 
              <Header color="brown" dividing content="Purchase Information" />
              <Form.Group widths="equal">
                <MyDateInput
                  id="form-input-control-purchase-date"
                  name="purchaseDate"
                  placeholderText="purchaseDate"
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy "
                />
                <MyTextInput id="form-input-control-salvage-value" label="Salvage Value" placeholder="Salvage Value" name="salvageValue" />
                <MyDateInput placeholderText="Primary Date" name="primaryWarranteeDate" timeCaption="time" dateFormat="MMMM d, yyyy " />
              </Form.Group> */}
              {/* <Form.Group widths="equal"> */}
              {/* <MyTextInput id="form-input-control-vendor" label="Vendor" placeholder="Vendor" name="vendorOID" /> */}
              {/* <MyTextInput id="form-input-control-life" label="Life" placeholder="Life" name="lifeSpan" />
                <MyDateInput dateFormat="MMMM d, yyyy " placeholderText="Secondary Date" name="secondaryWarranteeDate" />
              </Form.Group> */}
              {/* <Form.Group widths="equal"> */}
              {/* <MyTextInput
                  id="form-input-control-purchase-price"
                  label="Purchase Price"
                  placeholder="Purchase Price"
                  name="purchasePrice"
                /> */}
              {/* <MyTextInput id="form-input-control-annual-budget" label="Annual Budget" placeholder="Annual Budget" name="annualBudget" />

                <MySelectInput
                  options={equipmentOwnershipOptions}
                  label="Ownership"
                  placeholder="select an ownership category"
                  name="equipmentOwnership"
                />
              </Form.Group> */}
              {/* <Form.Group widths="3">
                <MyTextInput
                  id="form-input-control-primary-reading"
                  label="Primary Reading"
                  type="number"
                  placeholder="Primary Reading"
                  name="primaryMeterCurrentReading"
                />
                <MyTextInput
                  id="form-input-control-primary-reading"
                  label="Secondary Reading"
                  type="number"
                  placeholder="Secondary Reading"
                  name="secondaryMeterCurrentReading"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <MyTextArea id="form-input-control-notes" label="Notes" placeholder="Notes" name="notes" rows={3} />
              </Form.Group> */}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  lineHeight: '25px',
                  justifyContent: 'flex-end'
                }}
              >
                <Button content="Cancel" onClick={handleCancelClick} />
                <Button type="submit" primary content="Save" loading={isSubmitting} />
                <Button negative content="Delete" onClick={handleDeleteClick} />
              </div>
            </FForm>
          )}
        </Formik>
      </>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }
`;

export default EquipmentGridForm;
