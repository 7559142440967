import { PayloadAction } from '@reduxjs/toolkit';
import { IUserProps } from '../../../types/auth';
import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, SAVE_USER_DATA } from '../../actionTypes';

const InitialState: IUserProps = {
  profile: null,
  tasks: null,
  token: null,
  allowedRoutes: null
};

const authReducer = (state = InitialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case LOGOUT_USER:
      window.sessionStorage.clear();
      return {
        ...state,
        profile: null,
        token: null,
        tasks: null,
        allowedRoutes: null
      };

    case LOGIN_USER:
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload
      };

    case REGISTER_USER:
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload
      };

    case SAVE_USER_DATA:
      // console.log(action.payload);

      return {
        ...state,
        token: action.payload?.jwt,
        tasks: action.payload?.tasks,
        profile: action.payload?.user,
        allowedRoutes: action.payload?.allowedRoutes
      };

    default:
      return state;
  }
};

export default authReducer;
