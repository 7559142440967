import React, { useState, useEffect } from 'react';
import { Formik, Form as FForm, FieldArray } from 'formik';
import styled from '@emotion/styled';
import { Grid, Header, Icon, Form, Button, Message, Label } from 'semantic-ui-react';
// import axios from 'axios';
import { useSelector } from 'react-redux';
import { MySelectInput } from '.';
// import { useGetSystemCodeBySetupType } from '../../../store/actions';
import { IStateProps } from '../../../types';
import { mapFunction } from '../../../util';
// import notify from 'devextreme/ui/notify';
import axios from 'axios';
// import type { AxiosResponse } from 'axios';

interface IProps {
  rowID?: number;
  setMode?: any;
  fileURL: string;
}

const UserProfilesFormV2: React.FC<IProps> = ({ rowID, setMode, fileURL }) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsLocations } = useSelector((state: IStateProps) => state.cams?.camsLocation);
  // const { hrEmployeesV2s } = useSelector((state: IStateProps) => state?.cams?.hrEmployeeV2);
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  // const userID = useSelector((state: IStateProps) => state?.user?.profile?.userID);
  // const [reportURL, setReportURL] = useState('');
  // const [fileURL, setFileURL] = useState('');
  // const YesNoOptions: ISystemCodeProps[] = useGetSystemCodeBySetupType('YesNo');
  const [initialValues, setInitialValues] = useState({
    camsEmployeeOID: 0,
    labourRate: '',
    canBeAssigneToWO: '',
    notifyViaApp: '',
    notifyViaEmail: '',
    notifyMaintenanceDueLocation: '',
    notifyMaintenanceDueEquipment: '',
    notifyRenewalDueForLocations: '',
    notifyWorkOrderDueForLocation: '',
    notifyWorkOrderDue: '',
    notifyWorkOrderDueEquipment: '',
    notifyRequestRepairCompleted: '',
    notifyDailyInventoryLowStock: '',
    notifyImmediateInventoryLowStock: '',
    employeeOID: 0,
    hrEmployeeV2: null,
    camsUserLocationsVirtual: [
      {
        // camsUserLocationOID: 2,
        // camsUserOID: this,
        // locationOID: 0
        // camsUserVrtual: null,
        // camsLocationVrtual: null,
      }
    ]
  });

  // const YesNo = mapFunction(YesNoOptions, (element) => {
  //   const { description } = element;
  //   return {
  //     key: description,
  //     text: description,
  //     value: description
  //   };
  // });

  const locationOption = mapFunction(camsLocations, (element) => {
    const { locationName, locationOID } = element;
    return {
      key: locationOID,
      text: locationName,
      value: locationOID
    };
  });
  const userOption = mapFunction(applicationUsers, (element) => {
    const { fullName, id } = element;
    return {
      key: id,
      text: fullName,
      value: id
    };
  });

  useEffect(() => {
    if (rowID) {
      const fetchRowData = async () => {
        const url = `${baseAPI}/CAMSUsers/${rowID}`;
        axios
          .get(url)
          .then((response) => {
            return response.data.result[0];
          })
          .then((data: any) => {
            setInitialValues(data);
          });
      };
      fetchRowData();
    }
  }, [rowID, baseAPI]);

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleEditFormSubmit = (detail: any) => {
    // console.log(detail);
    const url = `${baseAPI}/CAMSUsers/${rowID}`;
    axios
      .put(url, detail)
      .then(() => setMode('GRID'))
      .catch((err) => {
        // console.log('Edit Form submission Failed')
      });
  };

  const handleAddFormSubmit = (detail: any) => {
    // console.log(detail);
    const url = `${baseAPI}/CAMSUsers`;
    axios
      .post(url, detail)
      .then(() => setMode('GRID'))
      .catch((err) => {
        // console.log('Add Form submission Failed')
      });
  };

  const downloadExcelAPI = `${baseAPI}/CAMSReports/DownloadReport?vFullFileName=${fileURL}`;

  return (
    <StyledProvider>
      <Header as="h2">
        <Icon onClick={handleCancelClick} size="big" name="arrow circle left" color="blue" />
        User Profiles
      </Header>
      <Message color="brown" size="mini">
        <Message.Header> Manage Cams users </Message.Header>
        <p> You can create and manage Cams users profile. </p>
      </Message>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values);
        }}
      >
        {({ values }) => (
          <FForm className="ui form">
            <Grid fluid columns={2}>
              <Grid.Row>
                <Form.Group style={{ width: '100%' }} widths={2} inline>
                  <MySelectInput label="User Name" placeholder="" name="userGUID" options={userOption} />
                  {rowID ? (
                    <Button
                      style={{ marginLeft: 'auto' }}
                      floated="right"
                      type="button"
                      primary
                      content="View Week Report"
                      target="_blank"
                      as="a"
                      href={`https://view.officeapps.live.com/op/embed.aspx?src=${downloadExcelAPI}`}
                    />
                  ) : null}
                </Form.Group>
              </Grid.Row>
              <Grid.Row width="16" columns={2}>
                <Grid.Column>
                  <FieldArray name="camsUserLocationsVirtual">
                    {({ insert, remove, push }) => (
                      <>
                        <Header as="h2" dividing>
                          Assign Location
                          {values.camsUserLocationsVirtual.length < 1 ? (
                            <Button
                              floated="right"
                              type="button"
                              primary
                              onClick={() =>
                                push({
                                  locationOID: 0,
                                  camsUserOID: values.camsEmployeeOID
                                })
                              }
                            >
                              Add
                            </Button>
                          ) : null}
                        </Header>
                        <div>
                          {values.camsUserLocationsVirtual.length > 0 &&
                            values.camsUserLocationsVirtual.map((friend, index) => (
                              <div className="row" key={index}>
                                <div className="it__details_form_item">
                                  <MySelectInput
                                    label={`Location ${index + 1}`}
                                    name={`camsUserLocationsVirtual.${index}.locationOID`}
                                    placeholder=""
                                    options={locationOption}
                                  />
                                  <Label color="red" size="big" as="a" type="button" className="secondary" onClick={() => remove(index)}>
                                    X
                                  </Label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </FieldArray>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div
              style={{
                display: 'flex',
                width: '100%',
                lineHeight: '25px',
                justifyContent: 'flex-end'
              }}
            >
              <Button type="submit" primary content="Save" />
              <Button content="Cancel" onClick={handleCancelClick} />
            </div>
          </FForm>
        )}
      </Formik>
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  .ui.grid > .row {
    padding: 1rem !important;
  }

  .it__details_form_item {
    align-items: baseline;
    width: 100%;
    display: flex;
    column-gap: 5px;

    div {
      width: 90%;
    }
  }
  .it__details_forms {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  .it__details_add {
    color: hsl(205, 78%, 60%);
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  input {
    height: 40px;
  }

  .it__number {
    font-size: 20px;
    font-weight: 600;
  }
`;

export default UserProfilesFormV2;
