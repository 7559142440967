//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : grid UI
// SIGNIFICANCE: The grid UI generator logic
// DATE CREATED : 20 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************
import * as React from 'react';
import { useState, useEffect } from 'react';

// import axios from "axios";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  // Lookup,
  // Popup,
  // Position,
  Scrolling,
  Selection,
  Button,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  // RowDragging,
  Column,
  RequiredRule,
  // PatternRule,
  EmailRule,
  Lookup
  // AsyncRule,
} from 'devextreme-react/data-grid';
// import Form, { FormItem } from "devextreme-react/form";
// import { LoadPanel } from "devextreme-react/load-panel";

import {
  // EmptyItem,
  // ButtonItem,
  // GroupItem,
  // SimpleItem,
  // Label,
  // CompareRule,
  // EmailRule,
  // PatternRule,
  // RangeRule,
  // RequiredRule,
  StringLengthRule
  // ColCountByScreen,
  // AsyncRule,
} from 'devextreme-react/form';
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form, {
//   SimpleItem,
//   GroupItem,
//   TabbedItem,
//   TabPanelOptions,
//   Tab,
// } from "devextreme-react/form";
// import Toolbar, { Item } from "devextreme-react/toolbar";
// import styled from "@emotion/styled";
// // import "../grid.scss";
// import notify from "devextreme/ui/notify";

import { useSelector } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
// import "whatwg-fetch";
// import { baseAPI2 } from "../../../api/APIEndpoints";
// import DynamicForm from "../DynamicForm";
// import CustomToolbar from "../CustomToolbar";
import { IFormUI } from './types';
// import { formUIConfig, uiData, mockData } from "./data";
// import { createLookup } from "../../../utils/createLookup";
// import { trigger } from "devextreme/events";

import '../grid.scss';
import { IStateProps } from '../../../types/auth';
// import fetchLookup from "../../../utils/fetchLookup";

const GridUI = ({ gridData, formData, columnFieldData, setMode, mode, setRowData, uiType, setLoadPanelVisible }: any) => {
  //********************FUNCTIONS******************************* */
  function handleErrors(response: any) {
    if (!response.ok) {
      setLoadPanelVisible(false);

      // console.log(response);
      if (response.status === 401) {
        throw Error('Your Login session has expired. Please logout and sign in again.');
      }
      throw Error(response.statusText);
    }
    return response;
  }
  //********************FUNCTIONS******************************* */

  //module functions here//
  const baseAPI = useSelector((state: IStateProps) => state.config?.baseURL);

  const jwt = useSelector((state: IStateProps) => state?.user?.token);

  // const [dataSource, setDataSource]: any = useState({});
  const [customDataSource, setCustomDataSource]: any = useState({});
  const [, setDataHolder]: any = React.useState([]);
  const url = `${baseAPI}`;

  const lookUps = useSelector((state: IStateProps) => state?.lookup);

  // console.log(columnFieldData);

  columnFieldData = columnFieldData.sort((a: any, b: any) => {
    if (a.uI_SortOrder < b.uI_SortOrder) {
      return -1;
    }
    if (a.uI_SortOrder > b.uI_SortOrder) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    const customDataSource: any = new CustomStore({
      // key: `${gridData.entity_KeyFieldName}`,
      load: () => {
        return fetch(`${url}/${gridData.endPoint_GetAllGridData}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            console.log(response.result, 'RETURN ARRAY');
            gridData.endPoint_GetAllGridData.includes('FA') || gridData.endPoint_GetAllGridData.includes('PPP')
              ? setDataHolder(response.result.data)
              : setDataHolder(response.result);
            setLoadPanelVisible(false);
            return {
              data:
                gridData.endPoint_GetAllGridData.includes('FA') || gridData.endPoint_GetAllGridData.includes('PPP')
                  ? response.result.data
                  : response.result,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values): any => {
        setLoadPanelVisible(true);
        return fetch(`${url}/${gridData.endPoint_PostGridData}`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            // const data = response.result.data;
            // console.log(data);
            setLoadPanelVisible(false);
            // setDataSource({ data, ...dataSource });
            // return {
            //   data: data,
            // };
          });
      },
      remove: (key) => {
        setLoadPanelVisible(true);

        // console.log(key);
        return fetch(
          `${url}/${gridData.endPoint_DeleteGridData.replace(`[${gridData.entity_KeyFieldName}]`, `${key[gridData.entity_KeyFieldName]}`)}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        ).then(handleErrors);
      },
      update: (values) => {
        setLoadPanelVisible(false);
        return values;
      }
    });

    setCustomDataSource(customDataSource);
  }, [gridData.endPoint_DeleteGridData]);

  const handleRowUpdating = (e: any) => {
    // setIsCloneIconVisible(false);
    setLoadPanelVisible(true);

    // const fieldOID = e.oldData.fieldOID;
    // console.log(e);
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${url}/${gridData.endPoint_PutGridData}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then((data) => {
      // console.log(data);
      // setIsCloneIconVisible(true);
      setLoadPanelVisible(false);
    });
  };

  const handleEdit = (e: any) => {
    setLoadPanelVisible(true);
    setRowData(e.row.data);
    // console.log(e.row.data);
    setMode('FORM');
    setLoadPanelVisible(false);
  };

  // console.log(columnFieldData);
  return (
    <DataGrid
      // key={}
      id={formData.applicationOID}
      // keyExpr={gridData.entity_KeyFieldName}
      dataSource={customDataSource && customDataSource}
      className="dx-datagrid dx-row-alt"
      wordWrapEnabled={true}
      hoverStateEnabled={true}
      columnAutoWidth={true}
      // scrolling={""}
      width={'100%'}
      activeStateEnabled={true}
      columnResizingMode="widget"
      allowColumnResizing={true}
      showColumnHeaders={gridData.grid_ShowColumnHeaders}
      onRowUpdating={handleRowUpdating}
      onInitNewRow={(e) => {
        // console.log(e.data);
        // e.data.id = Math.floor(Math.random() * 10 + 2);
        // const id = Math.floor(Math.random() * 10 + 2);
        // if (privilege.canCreate) {
        //   setRowData({});
        //   setShouldEdit(true);
        if (uiType.includes('FORM')) {
          //   setLoadPanelVisible(true);
          setMode('ADD-NEW');
        }
        // }
      }}
      columnHidingEnabled={true}
      rowAlternationEnabled={gridData.grid_ShowAlternatingRowColor}
    >
      {/* <RowDragging allowReordering={true} /> */}
      <Selection
        mode="multiple"
        selectAllMode={'allPages'} //allPages , page
        showCheckBoxesMode={'onLongTap'} //['none', 'onClick', 'onLongTap', 'always'
      />

      <Scrolling scrollByContent={true} columnRenderingMode="virtual" />
      <FilterPanel visible={gridData.grid_ShowFilterRow} />
      <SearchPanel visible={true} />
      <FilterRow visible={true} />
      <Paging defaultPageSize={10} />
      <Pager visible={gridData.grid_ShowPager} showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
      <Grouping contextMenuEnabled={gridData.grid_ShowGroupPanel} />
      <GroupPanel visible={gridData.grid_ShowGroupPanel} />
      <Editing
        mode="row"
        useIcons={true}
        // allowUpdating={privilege.canEdit ? true : false}
        // allowDeleting={privilege.canDelete ? true : false}
        // allowAdding={privilege.canCreate ? true : false}
        allowUpdating={gridData.grid_AddEditButton}
        allowDeleting={gridData.grid_AddDeleteButton}
        allowAdding={gridData.grid_AddNewButton}
      />
      {/* <Popup
            title="Application Roles"
            showTitle={true}
            width={20}
            height={30}></Popup> */}
      <Export
        // enabled={privilege.canExport}
        enabled={gridData.grid_ShowExportButton}
        fileName={gridData.formName}
        allowExportSelectedData={gridData.grid_ShowExportButton}
      />
      <Column caption={'Actions'} type="buttons" width={110} fixedPosition={gridData.grid_CommandPosition} fixed={true}>
        {uiType === 'LISTVIEWDETAILFORMCRUD' ? (
          <Button
            // name="edit"
            onClick={handleEdit}
            cssClass={'edit-btn'}
            text="View"
            // render={() => <span className={"grid-view-btn"}>VIEW</span>}
          />
        ) : uiType === 'LISTVIEWINLINECRUD' ? (
          <Button name="edit" />
        ) : null}

        {uiType === 'LISTVIEWINLINECRUD' && <Button name="delete" />}

        {/* <Button
            hint="Clone"
            icon="repeat"
            visible={isCloneIconVisible}
            onClick={(e: any) => cloneIconClick(e, dataHolder)}
          /> */}
      </Column>
      {/* <Column
          type="buttons"
          visible={gridData.grid_CommandPosition.toUpperCase() === "LEFTSIDE"}>
          <Point
            text="View"
            // text="&#10004; Admit"
            onClick={handleEdit}
            cssClass={"edit-btn"}
          />
        </Column> */}
      {/* Dynamically generate the columns */}

      {columnFieldData &&
        columnFieldData.map((ui: IFormUI, index: number) => (
          <Column
            key={`${ui.entity_FieldName}+${index}`}
            dataType={ui.entity_DataType}
            dataField={ui.entity_FieldName}
            // allowEditing={ui.uI_Disabled}
            width={ui.uI_Width === 0 ? '100' : ui.uI_Width}
          >
            {ui.entity_Required && <RequiredRule message={`${ui.entity_FieldName} is required`} />}
            {ui.uI_ValidationType !== null ? (
              ui.uI_ValidationType.toUpperCase() === 'STRINGLENGTH' ? (
                <StringLengthRule max={ui.entity_StringLength} message={ui.uI_ValidationMessage} />
              ) : ui.uI_ValidationType && ui.uI_ValidationType.toUpperCase() === 'EMAIL' ? (
                <EmailRule message={ui.uI_ValidationMessage} />
              ) : null
            ) : null}

            {ui.lookUp_IsLookUp && (
              <Lookup
                dataSource={lookUps[ui.entity_FieldName] && lookUps[ui.entity_FieldName].data.form}
                displayExpr="title"
                valueExpr="value"
              />
            )}
          </Column>
        ))}
    </DataGrid>
  );
};

export default GridUI;
