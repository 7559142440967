import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router from './routes/routes';
import { SET_SERVICE_BASE_URL } from './store/actionTypes';

const ERP = () => {
  const dispatch = useDispatch();
  const baseURLs: { [key: string]: string | undefined } = {
    _3p: process.env.REACT_APP_BASE_URL_3P,
    _cams: process.env.REACT_APP_BASE_URL_CAMS,
    _tf: process.env.REACT_APP_BASE_URL_TF,
    _ff: process.env.REACT_APP_BASE_URL_FF,
    _coinage: process.env.REACT_APP_BASE_URL_COINAGE,
    _gameplan: process.env.REACT_APP_BASE_URL_GAMEPLAN
  };

  const hostName = window.location.hostname;
  let [serviceName] = hostName.split('.') || 'cams';
  serviceName = `_${serviceName}`;
  serviceName = baseURLs.hasOwnProperty(serviceName) ? serviceName : '_cams';

  useEffect(() => {
    dispatch({ type: SET_SERVICE_BASE_URL, payload: baseURLs[serviceName] });
  });

  return <Router />;
};

export default ERP;
