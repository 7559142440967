import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  Lookup,
  LoadPanel
  // RequiredRule,
  // Form
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';
import 'whatwg-fetch';
import PMTaskForm from '../components/PMTaskForm';
import { IStateProps } from '../../../types/auth';
import { fetchApplicationUsers } from '../../../store/actions';
import { dxGridProps } from '../static';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

interface Props {}

const PMTaskGrid = (props: Props) => {
  const [dataSource, setDataSource] = React.useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  const dispatch = useDispatch();
  const [loadState, setLoadState] = useState(false);

  const applicationUsersLookup = {
    store: applicationUsers
  };

  useEffect(() => {
    dispatch(fetchApplicationUsers());
  }, [dispatch]);

  useEffect(() => {
    setLoadState(true);
    const customDataSource: any = new CustomStore({
      key: 'pmTaskOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSPMTasks`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            setLoadState(false);
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSPMTasks`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSPMTasks/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.pmTaskOID);
    setMode('FORM-EDIT');
  };

  const handleNewTask = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewTask;
        break;
      }
    }
  }

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Preventive Maintenance Tasks </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage PM Task</Message.Header>
                Create and manage PM Tasks.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsWarehouse"
              dataSource={dataSource && dataSource}
              // className="dx-datagrid dx-row-alt"
              // hoverStateEnabled={true}
              // columnAutoWidth={true}
              // activeStateEnabled={true}
              // columnResizingMode="widget"
              // allowColumnResizing={true}
              {...dxGridProps}
              onToolbarPreparing={onToolbarPreparing}
              // onRowUpdating={onRowUpdating}
              // columnHidingEnabled={true}
            >
              <LoadPanel enabled={loadState} />
              <RowDragging allowReordering={true} />
              <Export enabled={true} fileName="PM Tasks" allowExportSelectedData={false} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={false} />
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
                <Popup title="Inspection Templates" showTitle={true} width={700} height={550}></Popup>

                {/* <Form>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="inspectionTemplateOID" />
                    <Item dataField="templateName" />
                    <Item dataField="logMeterReading" />
                    <Item dataField="scrollAfterPassing" />
                    <Item dataField="allowNA" />
                    <Item dataField="category" />
                  </Item>
                </Form> */}
              </Editing>

              <Column type="buttons">
                <Button name="edit" onClick={handleEditClick} icon="edit" />
                {/* <Button name="delete" /> */}
              </Column>
              <Column dataField="taskOID" caption="Task OID" allowEditing={false} visible={false}></Column>
              <Column dataField="taskType" caption="Task"></Column>
              <Column dataField="assignedTo_UserGUID" caption="User">
                <Lookup dataSource={applicationUsersLookup} displayExpr="fullName" valueExpr="id" />
              </Column>

              <Column dataField="status" caption="Status"></Column>
              {/* <Column dataField="trackByDate_DueEvery_UOM" caption="Recurring/Due"></Column> */}
              <Column dataField="pmTaskDescription" caption="Description"></Column>
              <Column dataType="date" format="MMMM d, yyyy " dataField="trackByDate_LastPerformed" caption="Last Performed"></Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <PMTaskForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default PMTaskGrid;
