import * as React from 'react';
import { useState } from 'react';
import CheckBox from 'devextreme-react/check-box';
import axios from 'axios';
// import { baseAPI2 } from "../../../api/APIEndpoints";
import { useSelector } from 'react-redux';
import { IStateProps } from '../../../types/auth';
const CustomCheckBox = ({ value, roleOID }: any) => {
  // const baseAPI = useSelector((state: any) => state.service.baseUrl);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  //   const [currentVal, setCurrentVal]: any = useState(!!value.value);
  const [rowData, setRowData] = useState(() => {
    let noNullData: any = {};
    let nullData = value.row.data;

    for (let o in nullData) {
      if (nullData[o] === null) {
        noNullData[o] = '';
      } else {
        noNullData[o] = nullData[o];
      }
    }
    return noNullData;
  });
  // const jwt = useSelector((state: any) => state.auth.jwt);
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const [column] = useState(value.column.dataField);
  const [roleID] = useState(roleOID);

  // console.log("ROLE OID", roleID);
  return (
    <CheckBox
      defaultValue={!!value.value}
      style={{ marginTop: '20px' }}
      onValueChanged={(e) => {
        setRowData({ ...rowData, [`${column}`]: +e.value });
        console.log(roleID);
        axios
          .put(
            `${baseAPI}/EcoUserRoleTask/GetEcoUserRoleTaskByUserIDRoleIDTaskIDV2Async`,
            { ...rowData, roleOID: roleID, [`${column}`]: +e.value },
            {
              headers: {
                Authorization: `Bearer ${jwt}`
              }
            }
          )
          .then((res) => {
            console.log(res.data.result);
          });
      }}
      //   onValueChange={handleValueChange}
      // text="Clear Existing roles"
      // onValueChange={handleCheckSelect}
    />
  );
};

export default CustomCheckBox;
