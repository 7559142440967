//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : FormEngine
// SIGNIFICANCE: The form engine main grid having all configured forms and grid in the entire ecosystem.
// DATE CREATED : 10 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************

import * as React from 'react';
// import { useEffect } from "react";
import { useSelector } from 'react-redux';

import CustomStore from 'devextreme/data/custom_store';
// import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  // ButtonItem,
  // GroupItem,
  // SimpleItem,
  // Label,
  // CompareRule,
  // EmailRule,
  // PatternRule,
  // RangeRule,
  RequiredRule
  // StringLengthRule,
  // AsyncRule,
} from 'devextreme-react/form';

// import CheckBox from "devextreme-react/check-box";
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
import '../dynamicform.scss';
import DataGrid, { Column, ColumnFixing, Editing, Lookup, Button, Scrolling } from 'devextreme-react/data-grid';
import { IStateProps } from '../../../types/auth';
// import CustomToolbar from "../CustomToolbar";
// import { baseAPI2, ECOSYSTEM_APIS } from "../../../api/APIEndpoints";
// import CustomStore from "devextreme/data/custom_store";

// const colCountByScreen = {
//   sm: 1,
//   md: 2,
//   lg: 4,
// };

// const colCountByScreen2 = {
//   sm: 1,
//   md: 2,
//   lg: 3,
// };
// function screenByWidth(width: any) {
//   return width > 1140 ? "lg" : width > 720 ? "md" : "sm";
// }

// const booleanValueOptions = {
//   items: [true, false],
//   value: "",
// };

// const formBeautyOptions = {
//   items: ["ImageOnly", "TextOnly", "ImageText"],
//   value: "",
// };

// const formColumnsOptions = {
//   items: [2, 3, 4, 5],
//   value: "",
// };

// const formSizeOptions = {
//   items: ["Small", "Medium", "Large"],
//   value: "",
// };

const captionPosition = [
  { title: 'Top', value: 'Top' },
  { title: 'Left', value: 'Left' }
];

//'dxAutocomplete' | 'dxCalendar' | 'dxCheckBox' | 'dxColorBox' | 'dxDateBox' | 'dxDropDownBox' | 'dxHtmlEditor' | 'dxLookup' | 'dxNumberBox' | 'dxRadioGroup' | 'dxRangeSlider' | 'dxSelectBox' | 'dxSlider' | 'dxSwitch' | 'dxTagBox' | 'dxTextArea' | 'dxTextBox'

const uiControlTypeOptions = [
  { title: 'dxTextBox', value: 'dxTextBox' },
  { title: 'dxTextArea', value: 'dxTextArea' },
  { title: 'dxSelectBox', value: 'dxSelectBox' },
  { title: 'dxNumberBox', value: 'dxNumberBox' },
  { title: 'dxCheckBox', value: 'dxCheckBox' },
  { title: 'dxCalendar', value: 'dxCalendar' },
  { title: 'dxDateBox', value: 'dxDateBox' },
  { title: 'dxDropDownBox', value: 'dxDropDownBox' },
  { title: 'dxLookup', value: 'dxLookup' }
];
const uiDataTypeOptions = [
  { title: 'string', value: 'string' },
  { title: 'number', value: 'number' },
  { title: 'boolean', value: 'boolean' },
  { title: 'date', value: 'date' },
  { title: 'object', value: 'object' },
  { title: 'datetime', value: 'datetime' }
];

const validationType = [
  { title: 'Required', value: 'Required' },
  { title: 'Range', value: 'Range' },
  { title: 'StringLength', value: 'StringLength' },
  { title: 'Email', value: 'Email' }
];

// const commandPositionOptions = {
//   items: ["left", "right"],
//   value: "",
// };

const booleanLookup = [
  { title: true, value: true },
  { title: false, value: false }
];

// const valueOptions = {
//   items: ["Yes", "No"],
//   value: "",
// };

//  // ********************FUNCTIONS******************************* */
//   function handleErrors(response: any) {
//     setDataSource({});
//     if (!response.ok) {
//       console.log(response);
//       if (response.status === 401) {
//         throw Error(
//           "Your Login session has expired. Please logout and sign in again."
//         );
//       }
//       throw Error(response.statusText);
//     }
//     return setDataSource({});
//   }
//   // ********************FUNCTIONS******************************* */

const FormDetailsGrid = ({ privilege, uioid }: any) => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const detailUrl = `${baseAPI}/EcoCADUIEngineDtls/GetEcoCADUIEngineDtlByCADUISettingsUIOID/${uioid}`;
  const url = `${baseAPI}/EcoCADUIEngineDtls`;

  const [dataSource, setDataSource]: any = React.useState({});
  const [dataHolder, setDataHolder]: any = React.useState([]);

  const [isCloneIconVisible, setIsCloneIconVisible]: any = React.useState(true);

  const customDataSource: any = new CustomStore({
    key: 'fieldOID',
    load: (): any => {
      return (
        fetch(`${detailUrl}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          // .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            // console.log(response.result);
            setDataHolder(response.result);
            return {
              data: response.result,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          })
      );
    },
    insert: (values: any) => {
      return (
        fetch(`${url}`, {
          method: 'POST',
          body: JSON.stringify({ ...values, caduiSettingsUIOID: uioid }),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        })
          // .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            // const data = response.result.data;
            // console.log(data, dataHolder);
            // setDataSource({ data, ...dataSource });
            // return {
            //   data: data,
            // };
          })
      );
    },
    remove: (key: any): any => {
      // console.log(key);
      return fetch(`${url}/${key}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      // .then(handleErrors);
    },
    update: (values) => {
      return values;
    }
  });

  const cloneIconClick = (e: any, source: any) => {
    const allData = [...source];
    const clonedItem = { ...e.row.data, fieldOID: '' };
    //insert cloned record
    dataSource.insert(clonedItem).then((response: any) => customDataSource.reload);

    allData.splice(e.row.rowIndex, 0, clonedItem);
    setDataSource(allData);
    setDataHolder(allData);

    e.event.preventDefault();
  };

  React.useEffect(() => {
    if (uioid) setDataSource(customDataSource);
  }, [uioid]);

  const handleRowUpdating = (e: any) => {
    setIsCloneIconVisible(false);
    const fieldOID = e.oldData.fieldOID;
    // console.log(e);
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${url}/${fieldOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then((data) => {
      // console.log(data);
      setIsCloneIconVisible(true);
    });
  };

  return (
    <>
      <header className={'details-header'}>Form Details Config</header>
      <DataGrid
        dataSource={dataSource || {}}
        keyExpr="fieldOID"
        showBorders={true}
        hoverStateEnabled={true}
        columnAutoWidth={true}
        activeStateEnabled={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        onSaved={() => setDataSource(dataSource)}
        // onEditorPreparing={() => alert("HELLO")}
        onRowUpdating={handleRowUpdating}
      >
        <Scrolling columnRenderingMode="virtual" />
        <ColumnFixing enabled={true} />
        <Editing mode="row" useIcons={true} allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing>
        <Column type="buttons" width={110} fixed={true}>
          <Button name="edit" />
          <Button name="delete" />
          <Button hint="Clone" icon="repeat" visible={isCloneIconVisible} onClick={(e: any) => cloneIconClick(e, dataHolder)} />
        </Column>
        <Column dataField="caduiSettingsUIOID" caption="Settings UIOID" dataType="number" allowEditing={false} />
        <Column dataField="fieldOID" caption="Field OID" allowEditing={false}>
          {/* <RequiredRule message="Field OID is required" /> */}
        </Column>
        <Column dataField="entity_DataType" caption="Data type">
          <Lookup dataSource={uiDataTypeOptions} displayExpr="title" valueExpr="value" />
          <RequiredRule message="Data type is required" />
        </Column>

        <Column dataField="entity_FieldName" caption="Field name" dataType="string">
          <RequiredRule message="Field name is required" />
        </Column>
        <Column dataField="uI_ControlType">
          <Lookup dataSource={uiControlTypeOptions} displayExpr="title" valueExpr="value" />
        </Column>
        <Column dataField="entity_Required" caption="IS Required" dataType="boolean">
          <Lookup dataSource={booleanLookup} displayExpr="title" valueExpr="value" />
        </Column>
        <Column dataField="entity_StringLength" caption="String Length" dataType="number">
          <RequiredRule message="String length is required" />
        </Column>
        <Column dataField="entity_UICode" caption="UI Code" dataType="string">
          {' '}
          <RequiredRule message="UI code is required" />
        </Column>
        <Column dataField="grid_ShowInGrid" caption="Show in Grid" dataType="boolean">
          {' '}
          <Lookup dataSource={booleanLookup} displayExpr="title" valueExpr="value" />{' '}
        </Column>
        <Column dataField="grouping_GroupCaption" caption="Group caption" dataType="string" />
        <Column dataField="grouping_GroupCode" caption="Group code" dataType="string" />
        <Column dataField="grouping_GroupType" caption="Group type" dataType="string" />
        <Column dataField="grouping_GroupOrder" caption="Group order" dataType="number" />
        <Column dataField="lookUp_IsLookUp" dataType="boolean" />
        <Column dataField="lookUp_LookUpName" dataType="string" />
        <Column dataField="lookUp_FetchURL" caption="Lookup Fetch Url" dataType="string" />
        <Column dataField="lookUp_ComboList" dataType="string" />
        <Column dataField="lookUp_DisplayMember" dataType="string" />
        <Column dataField="lookUp_ValueMember" />
        <Column dataField="uI_ControlCaption" />
        <Column dataField="uI_ControlCaptionPosition">
          <Lookup dataSource={captionPosition} displayExpr="title" valueExpr="value" />{' '}
        </Column>
        <Column dataField="uI_Disabled" dataType="boolean" />
        <Column dataField="uI_PlaceHolder" />
        <Column dataField="uI_Readonly" dataType="boolean" />
        <Column dataField="uI_SortIndex" />
        <Column dataField="uI_SortOrder" dataType="number" />
        <Column dataField="uI_ValidationType" dataType="string">
          <Lookup dataSource={validationType} displayExpr="title" valueExpr="value" />{' '}
        </Column>
        <Column dataField="uI_ValidationMessage" />
        <Column dataField="uI_Width" dataType="number" />
      </DataGrid>
    </>
  );
};

export default FormDetailsGrid;
