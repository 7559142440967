import { combineReducers } from 'redux';
import applicationUserReducer from './applicationUserReducer';
import camsLocationsReducer from './camsLocationsReducer';
import camsPMOperationReducer from './camsPMOperationReducer';
import camsPMTaskReducer from './camsPMTaskReducer';
import camsUserProfilesReducer from './camsUserProfilesReducer';
import hrEmployeesV2Reducer from './hrEmployeesV2Reducer';

const camsReducers = combineReducers({
  camsUserProfiles: camsUserProfilesReducer,
  camsLocation: camsLocationsReducer,
  hrEmployeeV2: hrEmployeesV2Reducer,
  pmTask: camsPMTaskReducer,
  pmOperation: camsPMOperationReducer,
  applicationUser: applicationUserReducer
  // allowedRoutes: allowedRouteReducer
});

export default camsReducers;
