import React from 'react';
import { useField } from 'formik';
import { Form, Label } from 'semantic-ui-react';
// import 'react-datepicker/dist/react-datepicker.css';
import { DateBox } from 'devextreme-react';

interface IProps {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
}

export default function MyDateInput(props: Partial<IProps | any>) {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <Form.Field className="flex item-center pt-4 " error={meta.touched && !!meta.error}>
      {props.label && <label style={{ width: '20%' }}>{props.label}</label>}
      {/* <div style={{ width: props.label ? '75%' : '100%' }}> */}
      <div style={{ display: 'inline-block' }}>
        <DateBox
          // type="date"
          // displayFormat="MMMM d, yyyy"
          {...field}
          {...props}
          onValueChanged={(e) => helpers.setValue(e.value)}
          // onValueChanged={(e) => console.log('onValueChanged', e.value)}
          onChange={(e: any) => helpers.setValue(new Date(e.event?.currentTarget?.value))}
          // onChange={(e: any) => console.log(e.event?.currentTarget?.value)}
          valueChangeEvent="keyup"
          useMaskBehavior={true}
        />
      </div>
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
