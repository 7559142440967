import React from 'react';
import { useSelector } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import CustomStore from 'devextreme/data/custom_store';
// import styled from "@emotion/styled";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Paging,
  Editing,
  Export,
  RequiredRule,
  Pager,
  Grouping,
  GroupPanel,
  Column
} from 'devextreme-react/data-grid';
import { IStateProps } from '../../../types/auth';

const Banks = () => {
  const jwt = useSelector((state: IStateProps) => state.user?.token);
  const baseURL = useSelector((state: IStateProps) => state?.config?.baseURL);
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const bankName: any = new CustomStore({
    key: 'bankOID',
    load: () => {
      return fetch(`${baseURL}/FABanks`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseURL}/FABanks`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      }).then(handleErrors);
    },
    remove: (key: any) => {
      return fetch(`${baseURL}/FABanks/${key}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }).then(handleErrors);
    },
    update: (values: any) => {
      return values;
    }
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseURL}/FABanks`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    }).then(bankName.load());
  };

  return (
    <div>
      <header className={'page-ui-header'}>FABanks</header>
      <DataGrid
        className="dx-datagrid dx-row-alt"
        hoverStateEnabled={true}
        dataSource={bankName}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        showBorders={true}
        onRowUpdating={onRowUpdating}
        activeStateEnabled={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        columnHidingEnabled={true}
      >
        <FilterPanel visible={true} />
        <SearchPanel visible={true} />
        <FilterRow visible={true} />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[2, 10, 20, 50, 100]} showInfo={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <Editing mode="popup" useIcons={true} allowUpdating={true} allowAdding={true} allowDeleting={true} />
        <Export
          enabled={true}
          // fileName="FABranchs"
          allowExportSelectedData={true}
        />
        <Column type="buttons" />
        <Column dataField="bankOID" allowEditing={false}></Column>
        <Column dataField="bankCode"></Column>
        <Column dataField="bankName">
          <RequiredRule />
        </Column>
        <Column dataField="routeCode"></Column>
        <Column dataField="address1">
          <RequiredRule />
        </Column>
        <Column dataField="address2"></Column>
        <Column dataField="address3"></Column>
        <Column dataField="Phone">
          <RequiredRule />
        </Column>
        <Column dataField="email">
          <RequiredRule />
        </Column>
        <Column dataField="accountName">
          <RequiredRule />
        </Column>
        <Column dataField="shortName"></Column>
        <Column dataField="accountNumber"></Column>
        <Column dataField="contact">
          <RequiredRule />
        </Column>
        <Column dataField="website"></Column>
        <Column dataField="comment"></Column>
      </DataGrid>
    </div>
  );
};
export default Banks;
