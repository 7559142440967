import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserManagement from '../ecosystem/modules/application-user/UserManagement';
import Banks from '../ecosystem/modules/banks/Banks';
import Branches from '../ecosystem/modules/branches/Branches';
import CamsCompanyInfoForm from '../ecosystem/modules/company-info/CompanyInfoForm';
import CompanyInfoForm from '../ecosystem/modules/company-info/CompanyInfoForm';
// import FullPageLoader from '../elements/general/FullPageLoader';
import FormEngine from '../ecosystem/modules/form-engine/FormEngine';
import ModuleUIController from '../ecosystem/modules/form-engine/ModuleUIController';
import EcoSystemSettings from '../ecosystem/modules/preferences/EcosystemSettings';
import AssignTasksToRole from '../ecosystem/modules/roles/AssignTasksToRole';
import Roles from '../ecosystem/modules/roles/Roles';
import EcoSystemCodes from '../ecosystem/modules/system-codes/EcosystemCodes';

export default function EcosystemRoutes() {
  return (
    // <Suspense fallback={<FullPageLoader />}>
    <Routes>
      <Route path={`form-engine`} element={<FormEngine />} />
      <Route path={`ecoTask`} element={<ModuleUIController />} />
      <Route path={`role-definition`} element={<Roles />} />
      <Route path={`master-data`} element={null} />
      <Route path={`applications`} element={<ModuleUIController />} />
      <Route path={`preferences`} element={<EcoSystemSettings />} />
      <Route path={`company-information`} element={<CamsCompanyInfoForm />} />
      <Route path={`company-information-v2`} element={<CompanyInfoForm />} />
      <Route path={`role-tasks`} element={<AssignTasksToRole />} />
      <Route path={`system-codes`} element={<EcoSystemCodes />} />
      <Route path={`user-management`} element={<UserManagement />} />
      <Route path={`user-roles`} element={<ModuleUIController />} />
      {/* <Route path={`application-Task`} element={null} /> */}
      <Route path={`application-user`} element={<ModuleUIController />} />
      <Route path={`message-template`} element={<ModuleUIController />} />
      <Route path={`FFVendors`} element={<ModuleUIController />} />
      <Route path={`ecoState`} element={<ModuleUIController />} />
      <Route path={`ecoLga`} element={<ModuleUIController />} />
      <Route path={`ecoTask`} element={<ModuleUIController />} />
      <Route path={`ecoCountry`} element={<ModuleUIController />} />
      <Route path={`banks`} element={<Banks />} />
      <Route path={`fa-branches`} element={<Branches />} />
      <Route path={`EcoBusinessSector`} element={<ModuleUIController />} />
      <Route path={`customers`} element={<ModuleUIController />} />
      <Route path={`items`} element={<ModuleUIController />} />
      <Route path={`taxcodes`} element={<ModuleUIController />} />
      <Route path={`HREmployeeV2s`} element={<ModuleUIController />} />
    </Routes>
    // </Suspense>
  );
}
