import React from 'react';
import { useField } from 'formik';
import { Form, Label } from 'semantic-ui-react';
import { DateBox } from 'devextreme-react';

export default function MyDateInput(props: Partial<any>) {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label>{props.placeholderText}</label>

      <DateBox
        type="date"
        displayFormat="MMMM d, yyyy"
        {...field}
        {...props}
        onChange={(e: any) => helpers.setValue(new Date(e.event?.currentTarget?.value))}
        onValueChanged={(e) => helpers.setValue(e.value)}
        //onValueChanged={(e) => helpers.setValue(e.value)}
      />

      {/* <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(value) => helpers.setValue(value)}
      /> */}
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
