import React, { useState } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Lookup,
  // Popup,
  //   Position,
  Editing,
  Export,
  Pager,
  // Grouping,
  // GroupPanel,
  RowDragging,
  Column,
  RequiredRule

  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { Segment, Message, Icon, Header } from 'semantic-ui-react';

import 'whatwg-fetch';
import VendorGridForm from '../../components/VendorGridForm';
import { IStateProps } from '../../../../types/auth';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const Vendors = () => {
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  const [dataSource, setDataSource] = React.useState({});
  // const [rowData, setRowData]: any = React.useState({});
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  console.log('1. baseapi', baseAPI);
  console.log('1. jwt', jwt);

  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: 'vendorOID',
      load: () => {
        return fetch(`${baseAPI}/FFVendors`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/FFVendors`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/FFVendors/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/FFVendors/${e.newData.vendorOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const handleEditClick = (e: any) => {
    setRowID(e.row.data.vendorOID);
    setMode('FORM-EDIT');
  };
  const handleNewEquipment = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  const toolbarItemRender = () => {
    return (
      <>
        {/* <Icon name="mail" /> 23 */}
        <Button name="add" />
        {/* <span className="name">Add new Invoice</span> */}
      </>
    );
  };
  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: 'before',

      template: 'Add New Equipment',
      option: {
        icon: 'plus'
      },
      onClick: () => handleNewEquipment()
    });

    console.log(e.toolbarOptions);
  };

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2"> Vendors </Header>

            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage Vendors</Message.Header>
                CAMS has the ability to add separate Vendors to a Location to store your part.
              </Message.Content>
            </Message>
          </div>
          <div>
            <DataGrid
              id="grdCamsLocation"
              dataSource={dataSource && dataSource}
              hoverStateEnabled={true}
              columnAutoWidth={true}
              activeStateEnabled={true}
              columnResizingMode="widget"
              allowColumnResizing={true}
              onRowUpdating={onRowUpdating}
              columnHidingEnabled={true}
              rowAlternationEnabled={true}
              showBorders={true}
              onToolbarPreparing={onToolbarPreparing}
            >
              <RowDragging allowReordering={true} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
              <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}></Editing>
              <Export enabled={true} fileName="Inspection" allowExportSelectedData={true} />
              <Column type="buttons">
                <Button name="edit" onClick={handleEditClick} icon="edit" />
                <Button name="delete" />
              </Column>
              <Column
                dataField="vendorOID"
                allowEditing={false}
                width={180}
                // visible={false}
              ></Column>
              <Column dataField="vendorName">
                <RequiredRule />
              </Column>
              <Column dataField="address">
                <RequiredRule />
              </Column>
              <Column dataField="telephoneNumber">
                <RequiredRule />
              </Column>
              <Column dataField="email">
                <RequiredRule />
              </Column>
              <Column dataField="website">
                <RequiredRule />
              </Column>
              <Column dataField="contactPerson">
                <RequiredRule />
              </Column>
              <Column dataField="status">
                <RequiredRule />
              </Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <VendorGridForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};
export default Vendors;
