import React from 'react';
import styled from '@emotion/styled';

// import CircularProgress from "@material-ui/core/CircularProgress";

export default function FullPageLoader() {
  return (
    <StyledLoaderLayout>
      {/* <CircularProgress color={"primary"} size={"64px"} /> */}
      <div id="loader" />
    </StyledLoaderLayout>
  );
}

const StyledLoaderLayout = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  /* background-color: #dfffdf8e; */
  background-color: #fdfdfd8e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  #loader {
    /* border: 16px solid #f3f3f3; */
    border: 6px solid transparent;
    border-radius: 50%;
    border-top: 6px solid #00447e;
    border-left: 6px solid #00447e;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
