import { Dispatch } from 'redux';
import { LOOKUP_ADDED } from '../../actionTypes';

export const addLookup = (props: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LOOKUP_ADDED,
      payload: props
    });
  };
};
