import { FETCH_CAMS_HR_EMPLOYEE_V2S, FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED } from '../../actionTypes';

const InitialState = {
  hrEmployeeV2Loaded: false,
  hrEmployeesV2s: [],
  hrEmployeeV2: {}
};

const hrEmployeesV2Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMS_HR_EMPLOYEE_V2S:
      return {
        ...state,
        hrEmployeeV2Loaded: true,
        hrEmployeesV2s: [...action.payload.hrEmployeesV2s]
      };

    case FETCH_CAMS_HR_EMPLOYEE_V2S_FAILED:
      return {
        ...state,
        hrEmployeeV2Loaded: true,
        hrEmployeesV2s: [...action.payload.hrEmployeesV2s]
      };

    default:
      return state;
  }
};

export default hrEmployeesV2Reducer;
