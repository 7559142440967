//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : FormGeneratorForm
// SIGNIFICANCE: The form that is used to enter configuration settings of various CAD ECOSYSTEM grids and forms.
// DATE CREATED : May 11 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************

import * as React from "react";
import { useEffect } from "react";

import Form, {
  // ButtonItem,
  GroupItem,
  SimpleItem,
  // Label,
  // CompareRule,
  // EmailRule,
  // PatternRule,
  // RangeRule,
  RequiredRule,
  // StringLengthRule,
  // AsyncRule,
} from "devextreme-react/form";

// import CheckBox from "devextreme-react/check-box";
// import SelectBox from "devextreme-react/select-box";
// import NumberBox from "devextreme-react/number-box";
// import Form from "devextreme-react/form";
import "../dynamicform.scss";
// import DataGrid, {
//   Column,
//   ColumnFixing,
//   Editing,
//   Lookup,
//   Button,
// } from "devextreme-react/data-grid";
// import CustomToolbar from "../CustomToolbar";
import FormDetailsGrid from "./FormDetailsGrid";
// import { LoadPanel } from "devextreme-react/load-panel";

const colCountByScreen = {
  sm: 1,
  md: 2,
  lg: 4,
};

// const colCountByScreen2 = {
//   sm: 1,
//   md: 2,
//   lg: 3,
// };
function screenByWidth(width: any) {
  return width > 1140 ? "lg" : width > 720 ? "md" : "sm";
}

const booleanValueOptions = {
  items: [true, false],
  value: "",
};

const formBeautyOptions = {
  items: ["ImageOnly", "TextOnly", "ImageText"],
  value: "",
};

const formColumnsOptions = {
  items: [2, 3, 4, 5],
  value: "",
};

const formSizeOptions = {
  items: ["Small", "Medium", "Large"],
  value: "",
};

const commandPositionOptions = {
  items: ["left", "right"],
  value: "",
};

// const booleanLookup = [
//   { title: true, value: true },
//   { title: false, value: false },
// ];
// const booleanLookup = [
//   { title: true, value: true },
//   { title: false, value: false },
// ];

const yesNoOption = {
  items: ["Yes", "No"],
  value: "",
};

const uiTypeOption = {
  items: [
    "ListView",
    "ListView ReadOnly Detail Form",
    "ListView Inline CRUD",
    "ListView Detail Form CRUD",
    "Detail Form CRUD",
    "Detail Form ReadOnly",
    "Custom",
  ],
  value: "",
};
const FormGeneratorForm = ({
  formData,
  setFormData,
  shouldEdit,
  privilege,
  url,
  formUIOID,
  loadPanelVisible,
}: any) => {
  const onFieldDataChanged = (e: any) => {
    // console.log(e.component.option("formData"));
    setFormData(e.component.option("formData"));
  };

  useEffect(() => {
    // console.log("RENDERING AGAIN");
  }, []);
  return (
    <div className="form-container" id={"header-form"}>
      <header className={"details-header"}>Form/Grid Header Config</header>
      {/* <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: "#header-form" }}
        // onHiding={hideLoadPanel}
        visible={loadPanelVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
        // closeOnOutsideClick={closeOnOutsideClick}
      /> */}
      <Form
        formData={formData}
        readOnly={!shouldEdit}
        showColonAfterLabel={true}
        showValidationSummary={true}
        onFieldDataChanged={onFieldDataChanged}
        validationGroup="companyData"
        colCountByScreen={colCountByScreen}
        screenByWidth={screenByWidth}
        labelLocation="top"
        minColWidth={233}
        colCount="auto">
        <GroupItem caption="END POINTS">
          <SimpleItem
            dataField="endPoint_DeleteFormData"
            label={{ text: "Delete Form Endpoint" }}>
            <RequiredRule message="Form delete endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_DeleteGridData"
            label={{ text: "Delete Grid Endpoint" }}>
            <RequiredRule message="Grid delete endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_GetAllFormData"
            label={{ text: "GetAll form Endpoint" }}>
            <RequiredRule message="GetAll endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_GetAllGridData"
            label={{ text: "GetAll Grid Endpoint" }}>
            <RequiredRule message="GetAll endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_GetSingleFormData"
            label={{ text: "GetSingle form Endpoint" }}>
            <RequiredRule message="GetSingle endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_GetSingleGridData"
            label={{ text: "GetSingle Grid Endpoint" }}>
            <RequiredRule message="GetSingle endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_PostFormData"
            label={{ text: "Post form Endpoint" }}>
            <RequiredRule message="post formData endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_PostGridData"
            label={{ text: "Post Grid Endpoint" }}>
            <RequiredRule message="Grid endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_PutFormData"
            label={{ text: "Put Form Endpoint" }}>
            <RequiredRule message="Put FormData endpoint is required" />
          </SimpleItem>
          <SimpleItem
            dataField="endPoint_PutGridData"
            label={{ text: "Put Grid Endpoint" }}>
            <RequiredRule message="Put grid Data endpoint is required" />
          </SimpleItem>
        </GroupItem>

        <GroupItem caption="ENTITIES">
          <SimpleItem
            editorOptions={{ disabled: true }}
            dataField="uioid"
            label={{ text: "UIOID" }}></SimpleItem>
          <SimpleItem
            dataField="entity_ApplicationCode"
            label={{ text: "Application Code" }}>
            <RequiredRule message="Application is required" />
          </SimpleItem>
          <SimpleItem
            dataField="entity_DataSourceType"
            label={{ text: "DataSource Type" }}>
            <RequiredRule message="Data source type is required" />
          </SimpleItem>
          <SimpleItem
            dataField="entity_KeyFieldName"
            label={{ text: "Key Field Name" }}>
            <RequiredRule message="Key fieldName is required" />
          </SimpleItem>
          <SimpleItem
            dataField="entity_MenuCaption"
            label={{ text: "Menu Caption" }}>
            <RequiredRule message="Grid endpoint is required" />
          </SimpleItem>
          <SimpleItem dataField="entity_TaskOID" label={{ text: "Task OID" }}>
            <RequiredRule message="Task OID is required" />
          </SimpleItem>
          <SimpleItem dataField="entity_UICode" label={{ text: "UI Code" }}>
            <RequiredRule message="UI Code is required" />
          </SimpleItem>
        </GroupItem>
        <GroupItem caption="FORM UI">
          <SimpleItem
            dataField="form_ButtonBeauty"
            editorType={"dxSelectBox"}
            editorOptions={{
              ...formBeautyOptions,
              value: formData.form_ButtonBeauty,
            }}
            label={{ text: "Form Button Theming" }}>
            {/* <RequiredRule message="Form theme is required" /> */}
          </SimpleItem>
          <SimpleItem dataField="form_FormName" label={{ text: "Form name" }}>
            <RequiredRule message="Form name is required" />
          </SimpleItem>
          <SimpleItem
            dataField="form_FormSize"
            editorType={"dxSelectBox"}
            editorOptions={{
              ...formSizeOptions,
              value: formData.form_FormSize,
            }}
            label={{ text: "Form size" }}>
            <RequiredRule message="Form size is required" />
          </SimpleItem>
          <SimpleItem dataField="form_FormTitle" label={{ text: "Form title" }}>
            <RequiredRule message="Form title is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowBackButton,
            }}
            dataField="form_ShowBackButton"
            label={{ text: "Show form BACK button" }}>
            {/* <RequiredRule message="show form back button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowClose,
            }}
            dataField="form_ShowClose"
            label={{ text: "Show CLOSE button" }}>
            {/* <RequiredRule message="show close button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowDelete,
            }}
            dataField="form_ShowDelete"
            label={{ text: "Show form DELETE button" }}>
            <RequiredRule message="show form delete button is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowExport,
            }}
            dataField="form_ShowExport"
            label={{ text: "Show form EXPORT button" }}>
            {/* <RequiredRule message="show form export button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowFinder,
            }}
            dataField="form_ShowFinder"
            label={{ text: "Show form FINDER button" }}>
            {/* <RequiredRule message="show form finder button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowNew,
            }}
            dataField="form_ShowNew"
            label={{ text: "Show form NEW button" }}>
            <RequiredRule message="show form new button is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowPreview,
            }}
            dataField="form_ShowPreview"
            label={{ text: "Show PREVIEW button" }}>
            {/* <RequiredRule message="show Preview button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowPrint,
            }}
            dataField="form_ShowPrint"
            label={{ text: "Show form PRINT button" }}>
            {/* <RequiredRule message="show form back button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowRequiredAsterics,
            }}
            dataField="form_ShowRequiredAsterics"
            label={{ text: "Show form required ASTERISKS" }}>
            {/* <RequiredRule message="show form export button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.form_ShowSave,
            }}
            dataField="form_ShowSave"
            label={{ text: "Show form SAVE button" }}>
            {/* <RequiredRule message="show form export button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{ ...yesNoOption, value: formData.form_StatusBar }}
            dataField="form_StatusBar"
            label={{ text: "Show form Status Bar" }}>
            <RequiredRule message="show form status bar is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{ ...yesNoOption, value: formData.form_ToolBar }}
            dataField="form_ToolBar"
            label={{ text: "Show form Toolbar" }}>
            <RequiredRule message="show form toolbar is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{ ...uiTypeOption, value: formData.form_UIType }}
            dataField="form_UIType"
            label={{ text: "Form UI Type" }}>
            <RequiredRule message="show form UI Type is required" />
          </SimpleItem>
        </GroupItem>

        <GroupItem caption="GRID UI">
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_AddDeleteButton,
            }}
            dataField="grid_AddDeleteButton"
            label={{ text: "Show GRID DELETE button" }}>
            {/* <RequiredRule message="show Grid DELETE button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_AddEditButton,
            }}
            dataField="grid_AddEditButton"
            label={{ text: "Show GRID EDIT button" }}>
            {/* <RequiredRule message="show Grid ADD button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_AddNewButton,
            }}
            dataField="grid_AddNewButton"
            label={{ text: "Show GRID NEW button" }}>
            {/* <RequiredRule message="show Grid NEW button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_AddSaveButton,
            }}
            dataField="grid_AddSaveButton"
            label={{ text: "Show GRID SAVE button" }}>
            {/* <RequiredRule message="show Grid SAVE button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_AddViewButton,
            }}
            dataField="grid_AddViewButton"
            label={{ text: "Show GRID VIEW button" }}>
            {/* <RequiredRule message="show Grid VIEW button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowColumnHeaders,
            }}
            dataField="grid_ShowColumnHeaders"
            label={{ text: "Show GRID Column Headers" }}>
            {/* <RequiredRule message="show Grid column Header is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowFilterRow,
            }}
            dataField="grid_ShowFilterRow"
            label={{ text: "Show GRID Filter Row" }}>
            {/* <RequiredRule message="show Grid ADD button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowGroupPanel,
            }}
            dataField="grid_ShowGroupPanel"
            label={{ text: "Show GRID group panel" }}>
            {/* <RequiredRule message="show Grid group panel is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowPager,
            }}
            dataField="grid_ShowPager"
            label={{ text: "Show GRID Pager" }}>
            {/* <RequiredRule message="show Grid pager is required" /> */}
          </SimpleItem>
          <SimpleItem
            dataField="grid_VerticalScrollableHeight"
            label={{ text: "Vertical scroll height" }}>
            {/* <RequiredRule message="Vertical scroll height is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowAlternatingRowColor,
            }}
            dataField="grid_ShowAlternatingRowColor"
            label={{ text: "Alternating Row color" }}>
            <RequiredRule message="Alternating Row color is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...commandPositionOptions,
              value: formData.grid_CommandPosition,
            }}
            dataField="grid_CommandPosition"
            label={{ text: "Grid Command Position" }}>
            <RequiredRule message="Command position is required" />
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_GroupByColumn,
            }}
            dataField="grid_GroupByColumn"
            label={{ text: "Grid Group by Column" }}>
            {/* <RequiredRule message="Grid Group by Column is required" /> */}
          </SimpleItem>
          <SimpleItem
            dataField="grid_VerticalScrollBarMode"
            label={{ text: "Grid Vertical Scroll bar Mode" }}>
            {/* <RequiredRule message="Notification Message is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.notification_AddNotification,
            }}
            dataField="notification_AddNotification"
            label={{ text: "Add Notification" }}>
            {/* <RequiredRule message="Command position is required" /> */}
          </SimpleItem>
          <SimpleItem
            dataField="notification_Message"
            label={{ text: "Notification Message" }}>
            {/* <RequiredRule message="Notification Message is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowExportButton,
            }}
            dataField="grid_ShowExportButton"
            label={{ text: "Show Grid Export button" }}>
            {/* <RequiredRule message="Show Grid Export button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...booleanValueOptions,
              value: formData.grid_ShowSearchButton,
            }}
            dataField="grid_ShowSearchButton"
            label={{ text: "Show Grid search button" }}>
            {/* <RequiredRule message="Show Grid search button is required" /> */}
          </SimpleItem>
          <SimpleItem
            editorType={"dxSelectBox"}
            editorOptions={{
              ...formColumnsOptions,
              value: formData.form_FormColumns,
            }}
            dataField="form_FormColumns"
            label={{ text: "Form Columns width" }}></SimpleItem>
        </GroupItem>
      </Form>
      {formUIOID ? (
        <FormDetailsGrid uioid={formUIOID} />
      ) : formData.uioid ? (
        <FormDetailsGrid uioid={formData.uioid} />
      ) : null}
    </div>
  );
};

export default FormGeneratorForm;
