import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Lookup,
  Popup,
  LoadPanel,
  //   Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  Form
  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Item } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { Segment, Message, Header } from 'semantic-ui-react';
import { IStateProps } from '../../../types/auth';

import 'whatwg-fetch';

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const LocationGrid = () => {
  const [dataSource, setDataSource] = React.useState({});
  // const [rowData, setRowData]: any = React.useState({});
  const [, setMode] = React.useState('GRID');
  const [loader, setLoader] = React.useState(false);
  const jwt = useSelector((state: IStateProps) => state.user.token);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);

  console.log('1. baseapi', baseAPI);
  console.log('1. jwt', jwt);

  React.useEffect(() => {
    setLoader(true);
    const customDataSource: any = new CustomStore({
      key: 'locationOID',
      load: () => {
        return fetch(`${baseAPI}/CAMSLocations`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            setLoader(false);
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count
            };
          });
      },
      insert: (values) => {
        console.log('Value =' + JSON.stringify(values));
        return fetch(`${baseAPI}/CAMSLocations`, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          }
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/CAMSLocations/${key}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      }
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const onRowUpdating = (e: any) => {
    console.log(Object.assign(e.oldData, e.newData));
    e.newData = Object.assign(e.oldData, e.newData);
    fetch(`${baseAPI}/CAMSLocations/${e.newData.locationOID}`, {
      method: 'PUT',
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(() => setMode('GRID'));
  };

  return (
    <Segment>
      <div>
        <Header as="h2"> Locations </Header>
        <Message color="brown">
          <Message.Content>
            <Message.Header> Manage Locations</Message.Header>
            Each piece of equipment in CAMS is assigned to a Location. Locations are used as a way of organizing your equipment list, so you
            can easily see where your equipment resides. Each location can also contain sub-categories for further organization.
          </Message.Content>
        </Message>
      </div>
      <div>
        <DataGrid
          id="grdCamsLocation"
          dataSource={dataSource && dataSource}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}
          // onToolbarPreparing={handleToolPreparing}
        >
          <LoadPanel enabled={loader} />
          <RowDragging allowReordering={true} />
          <Export enabled={true} fileName="Location" allowExportSelectedData={false} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={false} />
          <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
            <Popup title="Location" showTitle={true} width={700} height={700}></Popup>
            <Export enabled={true} fileName="Locations" allowExportSelectedData={true} />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="locationOID" />
                <Item dataField="locationName" />
                {/* <Item dataField="taxRate1" />
                <Item dataField="taxRate2" /> */}
                {/* <Item dataField="labourTax" /> */}
                <Item dataField="category" />
                {/* <Item dataField="street" /> */}
                <Item dataField="city" />
                <Item dataField="state" />
                <Item dataField="country" />
                <Item dataField="postCode" />
                {/* <Item dataField="camsWarehouseList" /> */}
              </Item>
            </Form>
          </Editing>

          <Column type="buttons">
            <Button name="edit" icon="edit" />
            <Button name="delete" />
          </Column>
          <Column dataField="locationOID" allowEditing={false} visible={false}></Column>
          <Column dataField="locationName">
            <RequiredRule />
          </Column>
          {/* <Column dataField="taxRate1">
            <RequiredRule />
          </Column>
          <Column dataField="taxRate2">
            <RequiredRule />
          </Column> */}

          {/* <Column dataField="labourTax">
            <RequiredRule />
          </Column> */}

          <Column dataField="category">
            <RequiredRule />
          </Column>
          {/* <Column dataField="street">
            <RequiredRule />
          </Column> */}
          <Column dataField="city"></Column>
          <Column dataField="state">
            <RequiredRule />
          </Column>
          <Column dataField="country"></Column>
          <Column dataField="postCode"></Column>
          {/* <Column dataField="camsWarehouseList"></Column> */}
        </DataGrid>
      </div>
    </Segment>
  );
};

export default LocationGrid;
