import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import { FETCH_CAMS_LOCATION, FETCH_CAMS_LOCATION_FAILED } from '../../actionTypes';
import { ICamsLocationsAPIProps } from '../../../types';

export const fetchCamsLocations = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/CAMSLocations`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<ICamsLocationsAPIProps>) => {
        let result = res.data.result.data;

        dispatch({
          type: FETCH_CAMS_LOCATION,
          payload: {
            camsLocations: result
          }
        });
      })
      .catch((err: AxiosError) => {
        console.log('FETCH_CAMS_LOCATION_FAILED', err);

        dispatch({
          type: FETCH_CAMS_LOCATION_FAILED,
          payload: {
            camsLocations: err
          }
        });
      });
  };
};
