import { IApplicationUserStateProps } from '../../../types';
import { FETCH_APPLICATION_USER, FETCH_APPLICATION_USER_FAILED } from '../../actionTypes';

const InitialState: IApplicationUserStateProps = {
  applicationUserLoaded: false,
  applicationUsers: [],
  applicationUser: {},
  applicationUsersError: false
};

const applicationUserReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_APPLICATION_USER:
      return {
        ...state,
        applicationUserLoaded: true,
        applicationUsers: [...action.payload.applicationUsers]
      };

    case FETCH_APPLICATION_USER_FAILED:
      return {
        ...state,
        applicationUserLoaded: false,
        applicationUsersError: action.payload.applicationUsersError
      };

    default:
      return state;
  }
};

export default applicationUserReducer;
