import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/text-box';
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  Lookup
  // RequiredRule,
  // Form
} from 'devextreme-react/data-grid';
// import CustomStore from 'devextreme/data/custom_store';
// import { Item } from "devextreme-react/form";
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Loader, Dimmer } from 'semantic-ui-react';
import 'whatwg-fetch';
import UserProfilesForm from '../components/UserProfilesForm';
import { IStateProps } from '../../../types/auth';
import { fetchCamsLocations, fetchCamsUserProfiles, fetchHREmployeesV2s } from '../../../store/actions';
import { dxGridProps } from '../static';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import axios, { AxiosError } from 'axios';
import type { AxiosResponse } from 'axios';
//********************FUNCTIONS******************************* */
// function handleErrors(response: any) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
//********************FUNCTIONS******************************* */

interface Props {}

const UserProfileGrid = (props: Props) => {
  // const [dataSource, setDataSource] = React.useState({});
  const [mode, setMode] = useState('GRID');
  const [rowID, setRowID] = useState(0);
  // const [loader, setLoader] = useState(false);
  // const [reportURL, setReportURL] = useState('');
  const [fileURL, setFileURL] = useState('');

  // const jwt = useSelector((state: IStateProps) => state.user.token);
  // const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const { camsUserProfilesLoaded, camsUserProfiles } = useSelector((state: IStateProps) => state?.cams?.camsUserProfiles);
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);
  const { hrEmployeesV2s } = useSelector((state: IStateProps) => state?.cams?.hrEmployeeV2);
  const userID = useSelector((state: IStateProps) => state?.user?.profile?.userID);
  const dispatch = useDispatch();

  const employeeLookup = {
    store: hrEmployeesV2s
    // filter: 'otherNames'
  };

  useEffect(() => {
    dispatch(fetchCamsUserProfiles());
    dispatch(fetchCamsLocations());
    dispatch(fetchHREmployeesV2s());
    // setLoader(false);
  }, [dispatch]);
  // const startDate = (e: Date) => {
  //   console.log(Date);
  // };
  const handleEditClick = (e: any) => {
    let employeeOID = e.row.data.camsEmployeeOID;
    setRowID(employeeOID);
    const url = `${baseAPI}/CAMSReports/UserPMOperationReport?vUserGUID=${userID}`;

    axios
      .get(url)
      .then((res: AxiosResponse) => {
        const response = res.data;
        const filePath = response.reportURL;
        setFileURL(filePath);
        setMode('FORM-EDIT');
      })
      .catch((err: AxiosError) => {
        const error = err.message;
        notify(error, 'error');
      });
    // setMode('FORM-EDIT');
  };

  const handleNewClick = () => {
    setRowID(0);
    setMode('ADD-NEW');
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === 'addRowButton') {
        item.options.onClick = handleNewClick;
        break;
      }
    }
  }

  return (
    <Segment>
      {mode === 'GRID' ? (
        <>
          <div>
            <Header as="h2">Users Profile </Header>
            <Message color="brown">
              <Message.Content>
                <Message.Header> Manage user profile </Message.Header>
                Create and manage user profile.
              </Message.Content>
            </Message>
          </div>
          {!camsUserProfilesLoaded ? (
            <Dimmer active inverted>
              <Loader size="medium" inverted>
                Loading
              </Loader>
            </Dimmer>
          ) : (
            <div>
              <DataGrid
                keyExpr="camsEmployeeOID"
                dataSource={camsUserProfiles && camsUserProfiles}
                onToolbarPreparing={onToolbarPreparing}
                // onRowUpdating={onRowUpdating}
                // columnHidingEnabled={true}
                {...dxGridProps}
              >
                <RowDragging allowReordering={true} />
                <Export enabled={true} fileName="UserProfile" allowExportSelectedData={false} />
                <FilterPanel visible={true} />
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 100]} showInfo={true} />
                <Grouping contextMenuEnabled={true} />
                <GroupPanel visible={true} />
                <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}></Editing>

                <Column type="buttons">
                  <Button name="edit" onClick={handleEditClick} icon="edit" />
                  <Button name="delete" />
                </Column>
                <Column
                  dataField="camsEmployeeOID"
                  caption="User Name"
                  allowEditing={false}
                  // visible={false}
                >
                  <Lookup dataSource={employeeLookup} displayExpr="otherNames" valueExpr="employeeOID" />
                </Column>

                <Column dataField="canBeAssigneToWO" caption="Assigned to Work Order"></Column>
                <Column dataField="notifyViaEmail" caption="Email"></Column>
                <Column dataField="notifyMaintenanceDueLocation" caption="Notify Maintenance Due Location"></Column>

                <Column
                  dataType="date"
                  format="MMMM d, yyyy h:mm aa"
                  dataField="trackByDate_LastPerformed"
                  caption="Last Performed"
                ></Column>
                {/* <Column type="buttons" dataField="View Report">
                 
                  <Button
                    text="view"
                    cssClass={'edit-btn'}
                    onClick={handleViewClick}
                    
                  />
                 
                </Column> */}
              </DataGrid>
            </div>
          )}
        </>
      ) : (
        <UserProfilesForm fileURL={fileURL} rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default UserProfileGrid;
