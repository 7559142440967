import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
// import FullPageLoader from '../elements/general/FullPageLoader';
import FormEngine from '../ecosystem/modules/form-engine/FormEngine';
import ModuleUIController from '../ecosystem/modules/form-engine/ModuleUIController';
import Roles from '../ecosystem/modules/roles/Roles';

export default function AccountingRoutes() {
  return (
    // <Suspense fallback={<FullPageLoader />}>
    <Routes>
      <Route path={`fa-accounts`} element={<FormEngine />} />
      <Route path={`account-class`} element={<ModuleUIController />} />
      <Route path={`fa-account-types`} element={<Roles />} />
      <Route path={`charts-of-account`} element={<Roles />} />
      <Route path={`invoice`} element={<Roles />} />
      {/* <Route path={`invoice`} element={<Roles />} /> */}
    </Routes>
    // </Suspense>
  );
}
