import React from 'react';
import CustomCompanyForm from './CustomCompanyForm';

const CamsCompanyInfoForm: React.FC = ({ fetchUrl, editUrl }: any) => {
  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>Company Information</header>
      <div className="module-wrapper" id={'user'}>
        <CustomCompanyForm formMode={'Form'} />
      </div>
    </div>
  );
};

export default CamsCompanyInfoForm;
