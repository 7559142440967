import { FETCH_CAMS_LOCATION, FETCH_CAMS_LOCATION_FAILED } from '../../actionTypes'; // import {  ILMSUser } from "../../../types/types";

const InitialState = {
  camsLocationLoaded: false,
  camsLocations: [],
  camsLocation: {}
};

const camsLocationsReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case FETCH_CAMS_LOCATION:
      return {
        ...state,
        camsLocationLoaded: true,
        camsLocations: [...action.payload.camsLocations]
      };

    case FETCH_CAMS_LOCATION_FAILED:
      return {
        ...state,
        camsLocationLoaded: true,
        camsLocations: [...action.payload.camsLocations]
      };

    default:
      return state;
  }
};

export default camsLocationsReducer;
