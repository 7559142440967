import { ISystemCodeProps, ISystemCodes } from '../../../types/ecosystem';
import { FETCH_SYSTEM_CODES } from '../../actionTypes';

interface ISystemCodesSetupType {
  [key: string]: ISystemCodeProps[];
}

const InitialState: ISystemCodes = {
  data: null,
  isFetching: false,
  hasData: false,
  hasError: false
};

const extractSetupTypes = (systemCodes: ISystemCodeProps[], data: ISystemCodesSetupType = {}): ISystemCodesSetupType | void => {
  const setupTypeName = systemCodes?.[0].setupType?.toUpperCase();
  const currentSetupTypeData = systemCodes?.filter(
    (setupTypeData: ISystemCodeProps) => setupTypeData.setupType.toLowerCase() === setupTypeName.toLowerCase()
  );

  const otherSetupTypesData = systemCodes?.filter(
    (setupTypeData: ISystemCodeProps) => setupTypeData.setupType.toLowerCase() !== setupTypeName.toLowerCase()
  );

  data[setupTypeName.replaceAll(/\s/gi, '')] = [...currentSetupTypeData];

  if (otherSetupTypesData.length > 0) {
    return extractSetupTypes(otherSetupTypesData, data);
  }

  return data;
};

const systemCodesReducer = (state = InitialState, action: any) => {
  const data: ISystemCodesSetupType = {};

  switch (action.type) {
    case FETCH_SYSTEM_CODES:
      if (action.payload?.length > 0) {
        const systemCodes: ISystemCodeProps[] = action?.payload;

        extractSetupTypes(systemCodes, data);
      }
      //   console.log(data);

      return {
        ...state,
        data,
        isFetching: false,
        hasData: !!action.payload?.length,
        hasError: false
      };

    default:
      return state;
  }
};

export default systemCodesReducer;
