//*************************************************************
// PROJECT NAME : CADERP
// COMPONENT NAME : Module UI Controller
// SIGNIFICANCE: The Component dynamically renders the module that was clicked .
// DATE CREATED : 13 May 2021
// DEVELOPER    : Simeon Kenneth Tobechi (Kenchi)
//*************************************************************

import axios from 'axios';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { baseAPI2, ECOSYSTEM_APIS } from "../../../api/APIEndpoints";
// import concatBySeparator from "../../../utils/concatBySeparator";
import '../grid.scss';
// import Roles from "../roles/Roles";
// import { uiConfig, formUIConfig } from "./data";
// import { gridGenerator } from "./formGridGenerator";
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';

import GridForm from './GridForm';
import { TIndexType } from './types';
import concatBySeparator from '../../../util/concatBySeparator';
import { createLookup2 } from '../../../util/createLookup';
import { addLookup } from '../../../store/actions/ecosystem/lookupAction';
import { IStateProps } from '../../../types/auth';
// import { addLookup } from "../../../redux/actions/lookupAction";
// import { createLookup2 } from "../../../utils/createLookup";

const ModuleUIController = () => {
  const baseAPI = useSelector((state: IStateProps) => state?.config?.baseURL);

  const jwt = useSelector((state: IStateProps) => state?.user?.token);

  const dispatch = useDispatch();

  const pathsFromURL = window.location.pathname.split('/');

  let moduleFromURLStr = pathsFromURL[pathsFromURL.length - 1];
  let moduleFromURL = moduleFromURLStr;

  let moduleFilterPageName = moduleFromURL.replace('ppp_', '');
  let moduleName = moduleFilterPageName[0].toUpperCase() + moduleFilterPageName.slice(1);

  moduleName = moduleName.replace(/[_]/gi, ' ');

  // console.log(moduleName, moduleFromURLStr, "PATH");

  if (moduleFromURL.includes('items') || moduleFromURL.includes('customers') || moduleFromURL.includes('taxcodes')) {
    moduleFromURL = 'fa' + moduleFromURL;
  }

  // console.log(moduleFromURL, "HERE");

  moduleFromURL = moduleFromURL.toUpperCase() === 'PREFERENCES' ? 'ecoPreferencess' : moduleFromURL;

  if (moduleFromURL.includes('-')) {
    let [pathName, moduleTitle] = concatBySeparator(moduleFromURLStr, '-');

    moduleFromURL =
      pathName.toUpperCase() === 'MESSAGETEMPLATE'
        ? 'ecoEmailListMessages'
        : pathName.toUpperCase() === 'APPLICATIONTASK'
        ? 'ecoTask'
        : pathName.toUpperCase() === 'COMPANYINFORMATION'
        ? 'ecoCompany'
        : pathName;
    moduleName = moduleTitle;
  }

  moduleName = moduleName.toUpperCase() === 'ECOTASK' ? 'Task Definition' : moduleName;
  // console.log(moduleFromURL);

  // const [uiName, setUiName] = useState("");
  const [data, setData]: any = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);

  useEffect(() => {
    (async () => {
      try {
      } catch (error) {
        console.log(error, 'HERE ERROR');
      }
      axios
        .get(`${baseAPI}/EcoCADUIEngineHdrs/GetEcoCADUIEngineHdrByUICode/${moduleFromURL}`)
        .then(async (res: any) => {
          const moduleConfig = res.data.result[0];
          // const uiType = moduleConfig.form_UIType
          //   .toUpperCase()
          //   .replaceAll(" ", "");

          let gridData: TIndexType = {};
          let formData: TIndexType = {};
          let columnFieldData: any[] = [];
          let gridColumnData: any[] = [];

          if (typeof moduleConfig === 'object') {
            for (const data in moduleConfig) {
              if (data.includes('form') || data.includes('Form')) {
                formData[data] = moduleConfig[data];
              } else {
                gridData[data] = moduleConfig[data];
              }
            }
            columnFieldData = gridData.oEcoCADUIEngineDtl;
            gridColumnData = columnFieldData.filter((data: any) => data.grid_ShowInGrid === true);
            gridColumnData.sort((a: any, b: any) => {
              if (a.uI_SortOrder < b.uI_SortOrder) {
                return -1;
              }
              if (a.uI_SortOrder > b.uI_SortOrder) {
                return 1;
              }
              // order are equal
              return 0;
            });
            // console.log(
            //   "SORTED  and FILTERED COLUMN FIELD DATA",
            //   gridColumnData,
            //   uiType,
            //   gridData,
            //   columnFieldData
            // );
          }

          let elemWithLookupURL2: any = {};
          let elemWithLookupURL: any = {};
          let id = 0;
          for (let i = 0; i < columnFieldData.length; i++) {
            if (columnFieldData[i].lookUp_FetchURL && columnFieldData[i].lookUp_FetchURL !== 'HREmployeeV2s') {
              elemWithLookupURL[columnFieldData[i].entity_FieldName] = {
                url: columnFieldData[i].lookUp_FetchURL,
                data: null,
                fieldName: columnFieldData[i].entity_FieldName,
                pos: i,
                id: id
              };
              id += 1;
            }
          }

          if (Object.entries(elemWithLookupURL).length > 0) {
            const axiosCall: any[] = [];
            for (const el in elemWithLookupURL) {
              // console.log(elemWithLookupURL[el].url);
              // console.log(elemWithLookupURL[el]);
              axiosCall.push(
                axios.get(`${baseAPI}/${elemWithLookupURL[el].url}`, {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                  }
                })
              );
            }

            await axios
              .all([...axiosCall])
              .then(
                axios.spread((...responses) => {
                  // responses.forEach((res) => {
                  //   console.log(res.data, "HEY");
                  // });
                  // console.log(elemWithLookupURL, "HEY");

                  for (let i = 0; i < responses.length; i++) {
                    for (const el in elemWithLookupURL) {
                      if (elemWithLookupURL[el].id === i) {
                        // console.log(responses[i].data, "HERE in LOOKUP");
                        const [lookupData, selectData] = createLookup2(
                          responses[i].data.result.data,
                          columnFieldData[elemWithLookupURL[el].pos].lookUp_DisplayMember,
                          columnFieldData[elemWithLookupURL[el].pos].lookUp_ValueMember
                        );

                        // console.log(lookupData, "here 5");

                        // elemWithLookupURL2[elemWithLookupURL[el].fieldName] = {
                        //   url: elemWithLookupURL[el].url,
                        //   data: responses[i].data.result.data,
                        //   fieldName: el,
                        //   id: i,
                        // };
                        elemWithLookupURL2[elemWithLookupURL[el].fieldName] = {
                          url: elemWithLookupURL[el].url,
                          data: {
                            grid: selectData,
                            form: lookupData
                          },
                          fieldName: el,
                          id: `${i}_${el}`
                        };
                      }
                      // console.log(elemWithLookupURL2, "here 5B");
                    }
                    // console.log(elemWithLookupURL2, "here 5C");
                  }

                  // console.log(elemWithLookupURL2, "HELLO WORLD 6A");
                  dispatch(addLookup(elemWithLookupURL2));
                })
              )
              .catch((errors) => {
                // react on errors.
                console.log(errors, 'HERE ERROR');
              });
            // console.log(elemWithLookupURL2, "HELLO WORLD 6B");
            dispatch(addLookup(elemWithLookupURL2));

            // console.log(moduleConfig, "RENDERED 7");
          }
          setData(moduleConfig);
          setLoadPanelVisible(false);
          setHasFetched(true);
        })
        .catch((error) => {
          // console.log(error.response?.data?.message, "ERROR HERE");
          const errorMessages = error.response?.data?.message;

          notify(
            {
              message: Array.isArray(errorMessages) ? errorMessages[0].replace('EcoCADUIEngineHdr with vUIOID:', '') : errorMessages,
              position: {
                my: 'top center',
                at: 'top center',
                of: window,
                offset: '0 30'
              }
              // show: {
              //   type: "slide",
              //   direction: "top",
              //   duration: 10000,
              //   from: { position: { my: "top", at: "bottom", of: window } },
              // },
            },
            'error',

            3000
          );
          setLoadPanelVisible(false);
        });
    })();
  }, [baseAPI, dispatch, moduleFromURL, jwt]);

  return (
    <div className={'page-ui'}>
      <header className={'page-ui-header'}>{moduleName}</header>
      <div className="module-wrapper" id={'moduleUI'}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#moduleUI' }}
          // onHiding={hideLoadPanel}
          visible={loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          // closeOnOutsideClick={closeOnOutsideClick}
        />
        {/* {hasFetched && <>{gridGenerator(data)}</>} */}
        {hasFetched && <GridForm config={data} />}
      </div>
    </div>
  );
};

export default ModuleUIController;
