import React, { useState, useEffect } from 'react';
import { Formik, Form as FForm, FieldArray } from 'formik';
import styled from '@emotion/styled';
import { Grid, Header, Icon, Form, Button, Message, Loader, Dimmer, Confirm } from 'semantic-ui-react';
// import MyTextInput from './input-controls/MyInlineTextInput';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import MySelectInput from './input-controls/MyInlineSelectInput';

import MyDateInput from './input-controls/MyInlineDateInput';
import { IStateProps } from '../../../types/auth';
import { PMTaskOperation } from '.';
import { FileUploader } from 'devextreme-react';
import { mapFunction } from '../../../util';
import { createPMOperations, editPMOperations } from '../../../store/actions';
import notify from 'devextreme/ui/notify';

const overallStatus = [
  {
    key: 'DONE',
    text: 'DONE',
    value: 'DONE'
  },
  {
    key: 'NOT STARTED',
    text: 'NOT STARTED',
    value: 'NOT STARTED'
  },
  {
    key: 'ONGOING',
    text: 'ONGOING',
    value: 'ONGOING'
  }
];

interface IProps {
  rowID?: number;
  setMode?: any;
  fileURL: string;
}

const fileExtensions = ['.pdf'];

const PMOperationForm: React.FC<IProps> = ({ rowID, setMode, fileURL }) => {
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    camspmOperationOID: 0,
    pmTaskOID: 0,
    operationDate: '',
    loggedByOID: '',
    doneByOID: 0,
    overallStatus: '',
    notes: '',
    camspmOperationDetailVirtual: []
  });
  const [loadState, setLoadState] = useState(false);
  const { pmTasks } = useSelector((state: IStateProps) => state?.cams?.pmTask);
  // const { hrEmployeesV2s } = useSelector((state: IStateProps) => state?.cams?.hrEmployeeV2);
  const { applicationUsers } = useSelector((state: IStateProps) => state?.cams?.applicationUser);
  const userRoles = useSelector((state: IStateProps) => state?.user?.profile?.userRoles);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const jwt = useSelector((state: IStateProps) => state.user.token);

  const isAdmin = userRoles.includes('ADMIN');

  const pmTasksOptions = mapFunction(pmTasks, (element: any) => {
    const { pmTaskDescription, pmTaskOID } = element;
    return {
      key: pmTaskOID,
      text: pmTaskDescription,
      value: pmTaskOID
    };
  });

  const userOptions = mapFunction(applicationUsers, (element) => {
    const { id, fullName } = element;
    return {
      key: id,
      text: fullName,
      value: id
    };
  });

  useEffect(() => {
    if (rowID) {
      setLoadState(true);
      const url = `${baseAPI}/CAMSPMOperations/${rowID}`;
      axios
        .get(url)
        .then((response) => {
          return response.data.result[0];
        })
        .then((data: any) => {
          setInitialValues(data);
          setLoadState(false);
        });
    }
  }, [rowID, baseAPI]);

  const handleCancelClick = () => {
    setMode('GRID');
  };

  const handleEditFormSubmit = (task: any) => {
    if (!rowID || typeof rowID !== 'number') return null;
    dispatch(editPMOperations(task, rowID));
    setMode('GRID');
    // const url = `${baseAPI}/CAMSPMOperations/${rowID}`;
    // axios
    //   .put(url, task)
    //   .then(() => setMode('GRID'))
    //   .catch((err) => console.log('Edit Form submission Failed'));
  };

  const handleAddFormSubmit = (task: any) => {
    dispatch(createPMOperations(task));
    setMode('GRID');

    // console.log(task);
    // const url = `${baseAPI}/CAMSPMOperations`;
    // axios
    //   .post(url, task)
    //   .then(() => setMode('GRID'))
    // .catch((err) => console.log('Add Form submission Failed'));
  };

  const handleDeleteClick = () => {
    setDeleteConfirm(true);
  };

  const handleDeleteConfirmClick = () => {
    axios
      .delete(`${baseAPI}/CAMSPMOperations/${initialValues.camspmOperationOID}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then(() => {
        setMode('GRID');
      })
      .catch((err) => {
        const error = err.message;
        notify(error, 'error', 3000);
      });
  };

  const handleDeleteCancelClick = () => {
    setDeleteConfirm(false);
  };

  const downloadExcelAPI = `${baseAPI}/CAMSReports/DownloadReport?vFullFileName=${fileURL}`;
  const reportUploadAPI = `${baseAPI}/CAMSReports/Upload-Report-To-Server?vReportType=${'pmoperation'}&EntityOID=${rowID}&extension=${'pdf'}`;

  return (
    <StyledProvider>
      <Header as="h2">
        <Icon onClick={handleCancelClick} className="cursor-pointer" size="huge" name="arrow circle left" color="blue" />
        PM Operation
      </Header>
      <Message color="brown" size="mini">
        <Message.Header>Manage PM operations</Message.Header>
        <p> You can update the PM Operation performed on this screen. </p>
      </Message>
      {loadState ? (
        <Dimmer active inverted>
          <Loader size="medium" inverted>
            Loading
          </Loader>
        </Dimmer>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => (rowID ? handleEditFormSubmit(values) : handleAddFormSubmit(values))}
        >
          {({ values }) => (
            <FForm className="ui form">
              <Grid columns={2}>
                {/* first form section */}
                <Grid.Row>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MySelectInput label="PM Task" placeholder="pm task" name="pmTaskOID" options={pmTasksOptions} />
                    {isAdmin ? <MySelectInput label="Assigned To" placeholder="" name="assignedTo_UserGUID" options={userOptions} /> : null}
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MyDateInput label="Log Date" placeholder="" name="operationDate" dateFormat="MMMM d, yyyy " />

                    <MySelectInput label="Done By" placeholder="" name="doneBy_UserGUID" options={userOptions} />
                  </Form.Group>
                  <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MySelectInput label="Overall Status" placeholder="" name="overallStatus" options={overallStatus} disabled={true} />
                  </Form.Group>
                  {/* <Form.Group style={{ width: '100%' }} widths={2} inline>
                    <MyTextInput label="Notes" placeholder="notes" name="notes" />

                    {isAdmin ? <MySelectInput label="Assigned To" placeholder="" name="assignedTo_UserGUID" options={userOptions} /> : null}
                  </Form.Group> */}
                </Grid.Row>

                <Grid.Row columns={1} width="16">
                  <FieldArray name="camspmOperationDetailVirtual">
                    {({ insert, remove, push }) => (
                      <>
                        <Header
                          className="w-full 
                        flex
                        flex-col justify-between"
                          as="h2"
                          dividing
                        >
                          PM Tasks Operation
                          {rowID ? (
                            <span className="mb-2 flex flex-col " style={{ display: 'flex', alignItems: 'center' }}>
                              <span>
                                {/* <Button as="a" color="linkedin" download="Operation-Template.xlsx" href={downloadExcelAPI} type="button">
                                  Download PM Task
                                </Button> */}
                                <Button
                                  color="facebook"
                                  as="a"
                                  target="_blank"
                                  href={`https://view.officeapps.live.com/op/embed.aspx?src=${downloadExcelAPI}`}
                                  type="button"
                                  style={{ padding: '10px', width: '150px' }}
                                >
                                  Preview PM Task
                                </Button>
                              </span>
                              <span className="file-uploader-block inline-block ">
                                <FileUploader
                                  name="files"
                                  multiple={false}
                                  uploadMode="useButtons"
                                  uploadUrl={reportUploadAPI}
                                  maxFileSize={4000000}
                                  allowedFileExtensions={fileExtensions}
                                  style={{ marginTop: '3em' }}
                                />
                                <span className="text-sm block" style={{ fontSize: '18px' }}>
                                  Only PDF IS Allowed
                                </span>
                                <span className="text-xl" style={{ fontSize: '18px' }}>
                                  {'Maximum file size: '}
                                  <span style={{ fontSize: '18px' }}>4 MB</span>.
                                </span>
                              </span>
                            </span>
                          ) : null}
                        </Header>
                        <>
                          <PMTaskOperation
                            baseAPI={baseAPI}
                            initialValues={initialValues}
                            setInitialValues={setInitialValues}
                            values={values}
                          />
                        </>
                      </>
                    )}
                  </FieldArray>
                </Grid.Row>
              </Grid>
              <div
                className="mt-4 flex justify-end w-full"
                style={{ marginTop: '1rem', display: 'flex', justifyContent: 'end', gap: '0.5rem' }}
              >
                <Button type="submit" primary content="Save" />
                <Button content="Cancel" type="button" onClick={handleCancelClick} />
                {isAdmin ? <Button content="Delete" color="red" type="button" onClick={handleDeleteClick} /> : null}
              </div>
            </FForm>
          )}
        </Formik>
      )}

      <Confirm
        header={`Delete Confirmation`}
        content={`Are you sure you want to delete the pm operation`}
        open={deleteConfirm}
        onCancel={handleDeleteCancelClick}
        onConfirm={handleDeleteConfirmClick}
        size="mini"
      />
    </StyledProvider>
  );
};

const StyledProvider = styled.div`
  .item {
    width: 100%;
    height: 100px;
    position: absolute;
  }

  .ui.dividing.header {
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  }

  .ui.grid > .row {
    padding: 1rem !important;
    flex-direction: column;
  }

  input {
    height: 40px;
  }

  .dx-button-has-text .dx-button-content {
    padding: 7px 18px 8px !important;
    text-shadow: none;
    background-image: none;
  }
`;

export default React.memo(PMOperationForm);
