import React, { useState } from 'react';
import { Segment, Message, Header, Table, Button } from 'semantic-ui-react';
import 'devextreme/dist/css/dx.light.css';
// import "devextreme/dist/css/dx.common.css";
// import "devextreme-react/text-area";
// import "devextreme-react/text-box";
import { TreeView } from 'devextreme-react/tree-view';
import './CamsReport.css';

import { camsReportsData } from '../../static';
import { useSelector } from 'react-redux';
// import CustomStore from 'devextreme/data/custom_store';
import { IStateProps } from '../../../../types/auth';
import axios from 'axios';
// import DataSource from 'devextreme/data/data_source';
import type { AxiosResponse } from 'axios';
import { LoadPanel } from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import 'whatwg-fetch';
import { Link } from 'react-router-dom';
import CamChart from './CamsChart';
//********************FUNCTIONS******************************* */
// function handleErrors(response: any) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
//********************FUNCTIONS******************************* */
interface Props {
  // fileURL: string;
}

const CamsReport = (props: Props) => {
  const dataSource: any = camsReportsData.getCamsReportsData();
  const [, setCurrentItem] = useState({ ...dataSource[0] });
  const [loadState, setLoadState] = useState(false);
  // const [mode, setMode] = useState('GRID');
  // const [rowID, setRowID] = useState(0);
  const baseAPI = useSelector((state: IStateProps) => state.config.baseURL);
  // const [fileURL, setFileURL] = useState('');
  // const [reportSheet, setReportSheet] = useState(null);
  const [reportURL, setReportURL] = useState('');

  const selectItem = (e: any) => {
    setLoadState(true);
    const reportURL = e.itemData.urlCams;
    const url = `${baseAPI}/${reportURL}`;
    axios
      .get(url)
      .then((res: AxiosResponse) => {
        // console.log(res);
        const response = res.data.reportURL;
        setReportURL(response);
        setLoadState(false);
      })
      .catch(() => setLoadState(false));

    setCurrentItem({ ...e.itemData });
  };

  function handleClick(e: any) {
    axios.get(`${baseAPI}/${e.urlCams}`).then((response) => console.log(response));
  }

  const reportDownloadURL = `${baseAPI}/CAMSReports/DownloadReport?vFullFileName=${reportURL}`;

  return (
    <Segment>
      <div>
        <Header as="h2"> CAMS Report </Header>
        <Message color="brown" size="big">
          <Message.Content className="message-text">
            <div
            // style={{ marginTop: '30px' }}
            >
              {/* <Link to="/app/cams/cams_chart"> */}
              <Button size="big" floated="right" color="brown" as={Link} to="/app/cams/cams_warehouses" type="button">
                Chart Report
              </Button>
              {/* </Link> */}

              {/* <Button
                size="medium"
                floated="right"
                color="brown"
                onClick={handleClick}
                download="EquipmentList.xlsx"
                target="_blank"
                href={reportDownloadURL}
                type="button"
              >
                Download
              </Button> */}
              <Button
                size="big"
                floated="right"
                color="brown"
                target="_blank"
                onClick={handleClick}
                href={`https://view.officeapps.live.com/op/embed.aspx?src=${reportDownloadURL}`}
                type="button"
              >
                Preview
              </Button>
            </div>
            <div className="message">
              <Message.Header> Manage CAMS Report</Message.Header>
              For report download in PDF format. Click on the preview button, Press "Ctrl + p" and save as PDF to your system.
            </div>
          </Message.Content>
        </Message>
      </div>

      <div className="form">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ textAlign: 'center' }}>List of CAMS Report</Table.HeaderCell>

              <Table.HeaderCell colSpan="2" style={{ textAlign: 'center' }}>
                Selected Report
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row></Table.Row>
          </Table.Body>
          <Table.Cell collapsing>
            <TreeView
              id="treeview"
              items={dataSource}
              dataStructure="plain"
              displayExpr="name"
              parentIdExpr="categoryId"
              keyExpr="ID"
              width={300}
              onItemClick={selectItem}
            />

            {/* <LoadPanel enabled={loader} /> */}
          </Table.Cell>
          <Table.Cell>
            {reportURL !== '' && (
              <div id="product-details">
                {/* <img src={currentItem.icon} alt="hello test" /> */}
                {/* <div className="name">{currentItem.name}</div> */}
                {/* <div className="price">{`$${currentItem.price}`}</div> */}
                {loadState ? (
                  <div style={{ textAlign: 'center' }}>
                    <LoadIndicator id="medium-indicator" height={60} width={60} visible={loadState} />
                  </div>
                ) : (
                  <div className="report" onClick={handleClick}>
                    {/* {`${currentItem.urlCams}`} */}
                    {
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${reportDownloadURL}`}
                        width="95%"
                        height="1014px"
                        title="CAMS Report Preview (Excel)"
                        frameBorder="0"
                      ></iframe>
                    }
                  </div>
                )}
              </div>
            )}
          </Table.Cell>
        </Table>
      </div>
    </Segment>
  );
};
export default CamsReport;
