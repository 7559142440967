import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import { FETCH_APPLICATION_USER, FETCH_APPLICATION_USER_FAILED } from '../../actionTypes';
import { IApplicationUsersAllAPIProps } from '../../../types';
import notify from 'devextreme/ui/notify';

export const fetchApplicationUsers = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/ApplicationUser/GetAllApplicationUsersV2`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<IApplicationUsersAllAPIProps>) => {
        let result = res.data.result;

        dispatch({
          type: FETCH_APPLICATION_USER,
          payload: {
            applicationUsers: result
          }
        });
      })
      .catch((err: AxiosError) => {
        // const error = err.message;
        notify('User Session has expired', 'error', 3000);
        dispatch({
          type: FETCH_APPLICATION_USER_FAILED,
          payload: {
            applicationUsersError: true
          }
        });
      });
  };
};
