import React, { Suspense } from 'react';
import NavigationScroll from './layout/NavigationScroll';
import FullPageLoader from './components/general/FullPageLoader';
import ThemeConfig from './themes';
import ERP from './ERP';

// This is important, and fixes the react-error-overlay issues for HMR
import './App.css';

const App = () => {
  return (
    <ThemeConfig>
      <NavigationScroll>
        <Suspense fallback={<FullPageLoader />}>
          <ERP />
        </Suspense>
      </NavigationScroll>
    </ThemeConfig>
  );
};

export default App;
