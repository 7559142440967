// import { useSelector } from "react-redux";

import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { FETCH_SYSTEM_CODES } from '../../actionTypes';
import { ISystemCodeProps } from '../../../types/ecosystem';

// import { ISystemCodeProps } from "../../types/types";
// import { ISystemCodes } from "../../types/types";

// interface IServiceObject {
//   serviceName: string;
//   theme: ITheme;
// }

type TSetupTypes =
  | 'brand'
  | 'gender'
  | 'country'
  | 'equipmentType'
  | 'workOrderPriority'
  | 'equipment'
  | 'EquipmentStatus'
  | 'EquipmentOwnership'
  | 'TaskType'
  | 'YesNo';

export const useGetSystemCodeBySetupType = (setupType: TSetupTypes) => {
  const systemCodes = useSelector((state: any) => state?.systemCodes?.data);
  const data = systemCodes[setupType.toUpperCase()];
  return data;
};

export const saveSystemCodesToStore = (payload: ISystemCodeProps[]) => {
  console.log(payload);
  return (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_SYSTEM_CODES,
      payload
    });
  };
};
