import Axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { baseURL } from '../../../api';
import {
  FETCH_CAMS_PM_OPERATIONS_ALL, //
  FETCH_CAMS_PM_OPERATIONS_ALL_FAILED,
  // EDIT_CAMS_PM_OPERATION,
  CREATE_CAMS_PM_OPERATION,
  FETCH_CAMS_PM_OPERATIONS_BY_STATUS,
  EDIT_CAMS_PM_OPERATION_V2,
  DELETE_CAMS_PM_OPERATION
} from '../../actionTypes';
import { IPMOperationProps, IPMOperationsAPIProps, IPMOperationPostAPIProps, IPMOperationGetByStatusAPIProps } from '../../../types';

export const fetchPMOperations = () => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/CAMSPMOperations`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<IPMOperationsAPIProps>) => {
        let result = res.data.result.data;

        dispatch({
          type: FETCH_CAMS_PM_OPERATIONS_ALL,
          payload: {
            pmOperations: result
          }
        });
      })
      .catch((err: AxiosError) => {
        // console.log('FETCH_CAMS_PM_TASKS_FAILED', err);
        dispatch({
          type: FETCH_CAMS_PM_OPERATIONS_ALL_FAILED,
          payload: {
            pmOperations: err.message
          }
        });
      });
  };
};

/**
 *
 * @param values
 * @param rowID
 */

export const editPMOperations = (values: IPMOperationProps, rowID: number) => {
  const putURL = `${baseURL}/CAMSPMOperations/${rowID}`;
  return (dispatch: Dispatch) => {
    Axios.put(putURL, { ...values }).then(() => {
      dispatch({
        type: EDIT_CAMS_PM_OPERATION_V2,
        payload: {
          pmOperation: values
        }
      });
    });
  };
};

/**
 *
 * @param values
 * @returns
 */
export const createPMOperations = (values: IPMOperationPostAPIProps) => {
  const postURL = `${baseURL}/CAMSPMOperations`;
  return (dispatch: Dispatch) => {
    Axios.post(postURL, { ...values }).then((res: AxiosResponse<IPMOperationPostAPIProps>) => {
      const response = res.data.result.data[0];
      dispatch({
        type: CREATE_CAMS_PM_OPERATION,
        payload: {
          pmOperation: response
        }
      });
    });
  };
};

/**
 *
 * @param status
 * @param year
 * @param month
 * @param userID
 * @returns
 */
export const fetchPMOperationsByStatus = (status: string, year: number, month: number, userID?: string) => {
  return (dispatch: Dispatch) => {
    const getUserStatusUrl = `${baseURL}/CAMSPMOperations/GetUserPMOperations?vUserGUID=${userID}&vStatus=${status}&vMonth=${month}&vYear=${year}`;

    Axios.get(getUserStatusUrl)
      .then(async (res: AxiosResponse<IPMOperationGetByStatusAPIProps>) => {
        let result = res.data.result;

        dispatch({
          type: FETCH_CAMS_PM_OPERATIONS_BY_STATUS,
          payload: {
            pmOperations: result
          }
        });
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: FETCH_CAMS_PM_OPERATIONS_ALL_FAILED,
          payload: {
            pmOperations: err.message
          }
        });
      });
  };
};

/**
 *
 * @param rowID
 */
export const deletePMOperations = (rowID: number) => {
  const delURL = `${baseURL}/CAMSPMOperations/${rowID}`;
  return (dispatch: Dispatch) => {
    Axios.delete(delURL).then(() => {
      dispatch({
        type: DELETE_CAMS_PM_OPERATION,
        payload: {
          pmOperationOID: rowID
        }
      });
    });
  };
};
