export const status = [
  {
    key: 'Active',
    text: 'Active',
    value: 'Active'
  },
  {
    key: 'InActive',
    text: 'InActive',
    value: 'InActive'
  }
];
export const trackByDate = [
  {
    key: 'YES',
    text: 'Date',
    value: 'YES'
  },
  {
    key: 'NO',
    text: 'Hour',
    value: 'NO'
  }
];

export const dueEvery = [
  {
    key: 'Day',
    text: 'Day',
    value: 'Day'
  },
  {
    key: 'Month',
    text: 'Month',
    value: 'Month'
  },
  {
    key: 'Year',
    text: 'Year',
    value: 'Year'
  }
];


export  const pmOperationStatusOptions = [
  {
    key: 'ALL TASKS',
    text: 'ALL TASKS',
    value: 'ALL TASKS',
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 'NOT STARTED',
    text: 'NOT STARTED',
    value: 'NOT STARTED',
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'ONGOING',
    text: 'ONGOING',
    value: 'ONGOING',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'DONE',
    text: 'DONE',
    value: 'DONE',
    label: { color: 'green', empty: true, circular: true },
  }, 
]

export  const pmOperationYearOptions = [
  {
    key: 2020,
    text: 2020,
    value: 2020,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 2021,
    text: 2021,
    value: 2021,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 2022,
    text: 2022,
    value: 2022,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 2023,
    text: 2023,
    value: 2023,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2024,
    text: 2024,
    value: 2024,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2025,
    text: 2025,
    value: 2025,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2026,
    text: 2026,
    value: 2026,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2027,
    text: 2027,
    value: 2027,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2028,
    text: 2028,
    value: 2028,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2029,
    text: 2029,
    value: 2029,
    label: { color: 'blue', empty: true, circular: true },
  }, 
  {
    key: 2030,
    text: 2030,
    value: 2030,
    label: { color: 'blue', empty: true, circular: true },
  }, 
]

export  const pmOperationMonthsOptions = [
  {
    key: 1,
    text: "January",
    value: 1,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 2,
    text: "February",
    value: 2,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 3,
    text: "March",
    value: 3,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 4,
    text: "April",
    value: 4,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 5,
    text: "May",
    value: 5,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 6,
    text: "June",
    value: 6,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 7,
    text: "July",
    value: 7,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 8,
    text: "August",
    value: 8,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 9,
    text: "September",
    value: 9,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 10,
    text: "October",
    value: 10,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 11,
    text: "November",
    value: 11,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 12,
    text: "December",
    value: 12,
    label: { color: 'blue', empty: true, circular: true },
  },
 
]