import * as actionTypes from '../../actionTypes';

export const initialState = {
  menuState: false
};

const generalGlobalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU:
      return {
        ...state,
        menuState: action.payload
      };
    default:
      return state;
  }
};

export default generalGlobalReducer;
