import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IStateProps } from '../../../../types/auth';
import { SET_MENU } from '../../../../store/actionTypes';

const MenuList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userMenuTasks = useSelector((state: IStateProps) => state?.user?.tasks);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  // console.log(userMenuTasks);
  // const orderedMenu: any = [];
  // const orderedSubMenu: any = [];

  // const filterByApplicationOID = (list: ITaskProps[] | null, value: any, filterCriteria: string, menu: any[]) => {
  //   if (list) {
  //     const match = list.filter((item: { [key: string]: any }) => item[filterCriteria] === value);
  //     menu.push({
  //       name: match[0].applicationDescription,
  //       modules: match
  //     });
  //     // orderedMenu[match[0].applicationDescription] =
  //     const nonMatch = list.filter((item) => item?.applicationOID !== value);

  //     if (nonMatch.length > 0) {
  //       filterByApplicationOID(nonMatch, nonMatch[0]?.applicationOID, filterCriteria, menu);
  //     }
  //   }
  //   return menu;
  // };

  // filterByApplicationOID(userMenuTasks, userMenuTasks?.[0]?.applicationOID, 'applicationOID', orderedMenu);
  // console.log(orderedMenu);
  const closeMenu = (matchDownMd: boolean) => () => {
    if (matchDownMd) {
      dispatch({ type: SET_MENU, payload: false });
    }
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const menuData = createMenu(userMenuTasks);
  const menuData = createMenu(userMenuTasks);
  // console.log(userMenuTasks, menuData, 'MENU DATA');

  return (
    <StyledMenuWrapper>
      {menuData.reverse().map((application, key) => (
        <div key={key}>
          <Typography className="list_group__header" variant="subtitle2" fontWeight="bold">
            {' '}
            {application?.text}
          </Typography>
          {application?.items?.map((module: any, key2: number) => (
            <Accordion
              key={key2}
              expanded={expanded === `panel${module?.text + key2}`}
              sx={{ width: '100%', boxShadow: 'none' }}
              onChange={handleChange(`panel${module?.text + key2}`)}
            >
              <AccordionSummary
                sx={{
                  padding: '0 5px 0 0',
                  background: expanded === `panel${module?.text + key2}` ? theme.palette.secondary.light : 'initial'
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {' '}
                  {/* <Dashboard /> */}
                  {module?.text}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="links__wrapper">
                  {module?.items?.map((link: any, key3: number) => (
                    <NavLink
                      to={link?.path || '#'}
                      key={key3}
                      onClick={closeMenu(matchDownMd)}
                      className={({ isActive }) => `link ${isActive ? 'active-link' : 'inactive-link'}`}
                    >
                      {link?.text?.trim()}
                    </NavLink>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </StyledMenuWrapper>
  );
};

export default MenuList;

function createMenu(tasks: any = []) {
  const icons: any = {
    administration: 'hierarchy',
    'financial accounting': 'money'
  };
  let navTree: any[] = [];
  if (tasks) {
    let tasksArray = [...tasks];
    // const menuTree: any[] = [];
    const application: any[] = [];
    let applicationName: any[] = [];
    tasksArray.forEach((task) => {
      applicationName.push(task.applicationDescription);
    });

    applicationName = Array.from(new Set(applicationName));

    for (let i = 0; i < applicationName.length; i += 1) {
      navTree.push({
        text: `${applicationName[i]}`,
        icon: `${icons[applicationName[i].toLowerCase()]}`,
        items: []
      });

      // console.log("NAV tree top later", navTree);

      let appModule = [];
      application.push(tasksArray.filter((task) => task.applicationDescription === applicationName[i]));

      let modulesNames: any[] = [];
      application[i].forEach((task: any) => {
        modulesNames.push(task.moduleDescription);
      });

      modulesNames = Array.from(new Set(modulesNames));
      for (let j = 0; j < modulesNames.length; j += 1) {
        navTree[i].items.push({
          text: `${modulesNames[j]}`,
          icon: '',
          items: []
        });

        appModule.push(application[i].filter((task: any) => task.moduleDescription === modulesNames[j]));

        let itemTree: any[] = [];

        application[i].forEach((task: any) => {
          if (task.moduleDescription === modulesNames[j]) {
            if (!itemTree.includes((childTask: any) => task.taskDescription === childTask.taskDescription)) {
              // console.log(task.taskDescription, itemTree);
              itemTree = Array.from(new Set(itemTree));
              itemTree.push({
                text: `   ${task.taskDescription}`,
                // path: `/${service}/app/${task.reactRoute}`, //FIXME
                path: `/app/${
                  task.reactRoute?.includes('cams_')
                    ? task.reactRoute?.replace('cams_', 'cams/cams_')
                    : task.reactRoute?.includes('ppp_')
                    ? task.reactRoute?.replace('ppp_', 'ppp/ppp_')
                    : task.reactRoute
                }` //FIXME,
                //index: Math.random() * 200,
              });
            }
          }
        });
        // console.log(itemTree);

        itemTree = Array.from(new Set(itemTree));
        // console.log(itemTree);
        navTree[i].items[j].items = itemTree;
      }

      // return menuTree.push([appModule]);
      // return navTree;
      // console.log("NAV app tree done", navTree);
    }

    // console.log("Application MODULES ", menuTree, navTree);
  }
  return navTree;
}
const StyledMenuWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  padding: 10px;

  .list_group__header {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: -20px;
    /* border-bottom: 1px solid #c6c6c6; */
    padding-bottom: 10px;
    border-radius: 30px;
    /* background-color: #e3e3e3; */
    padding: 3px 5px;
  }

  .Mui-expanded {
    /* margin: 0 !important; */
    /* margin-left: -20px; */
    min-height: 20px !important;
    margin: 0;
    /* padding: 5px 0; */
  }

  .MuiCollapse-root {
    padding-left: 19px;
  }
  .MuiAccordionSummary-root {
    /* background: #5cb85c85; */
    border-radius: 10px 20px 20px 10px;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 10px;
  }
  .MuiAccordionDetails-root {
    padding-top: 0 !important;
  }
  .MuiAccordion-root {
    &:before {
      display: none !important;
      content: none !important;
    }
  }
  .links__wrapper {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */

    a {
      text-decoration: none;
      position: relative;
      padding: 10px 0 10px;
      color: #333;
    }

    a:before {
      content: '';
      width: 5px;
      height: 100%;
      background: #ddddddad;
      position: absolute;
      left: -25px;
      top: 0;
    }
    .active-link {
      &:before {
        background: ${(props) => useTheme().palette.secondary.light};
      }
    }
    .inactive-link {
      &:before {
        background: #ddddddad;
      }
    }
  }
`;
